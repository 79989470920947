import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Button } from "../../Components";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import EmailNotificationModal from "../../Components/EmailNotificationModal/EmailNotificationModal";
import "../../Components/Input/style";
import "./style.css";

const ContactUsScreen = ({
  handleSubmit,
  handleChange,
  loading,
  handleClick,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const handleModal = (visible) => {
    setIsModalVisible(visible);
  };

  return (
    <div className="contact-us-main_container">
      <EmailNotificationModal
        isVisible={isModalVisible}
        handleModal={handleModal}
      />
      <div className="contact-us-container">
        <div className="contact-us-screen_header">
          <Link to="/selectOption">
            <IoIosClose size={40} color="white" onClick={handleClick} />
          </Link>
        </div>
        <div className="contact-us-form_container">
          <p className="contact-us-text">Contact Us</p>
          <div className="input-fields-width">
            <input
              style={{ fontFamily: "Poppins" }}
              name="name"
              className="input-field"
              placeholder="Enter Full Name"
              onChange={handleChange}
            />
          </div>
          <div className="text-area-field">
            <textarea
              className="text-area-styling"
              rows="10"
              name="message"
              cols="50"
              placeholder="How can we help you?"
              onChange={handleChange}
            />
          </div>
          <Button
            loading={loading}
            title={"Submit"}
            onClickButton={() => handleSubmit()}
            buttonClass="contact-us-submit-btn"
          />
          <div className="or-reach-text">
            <b>or</b> <div className="or-line"></div>
          </div>
          <p className="directlt-reach-text">Email directly to:</p>
          <div className="email-container">
            <a className="email-text" href="mailto:support@chex.ai">
              support@chex.ai
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
