/* eslint-disable */

import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";
import moment from "moment";

import "./LyftCertificate.css";

import ActionCreators from "../../actions";
// import { setTuroReportData } from "../../utils/functions";
import hopeSkipDriveIcon from "../../Assets/hope-skip-drive.png";
import HopeSkipDriveCheck from "../../Assets/Hope-skip-drive-check.jpg";

import font from "../../font/Cairo-VariableFont_wght.ttf";
import boldFont from "../../font/Cairo-Bold.ttf";
import boldRobotoFont from "../../font/RobotoCondensed-Bold.ttf";
import RobotoFont from "../../font/Roboto-Bold.ttf";
import kalamFont from '../../font/Kalam-Light.ttf';


Font.register({ family: 'Cairo', format: "truetype", src: font });
Font.register({ family: 'Cairo', format: "truetype", src: boldFont });
Font.register({ family: 'Roboto', format: "truetype", src: boldRobotoFont });
Font.register({ family: 'Roboto', format: "truetype", src: RobotoFont });

Font.register({ family: 'kalamFont', format: "truetype", src: kalamFont });
const styles = StyleSheet.create({
    body: {
      paddingBottom: 65,
    },
    header: {
      color: "#333333",
      alignSelf: 'flex-end',
      fontSize: 20,
      justifyContent: 'center',
      marginLeft: 140,
      fontWeight: "200",
      fontFamily: 'Roboto'
    },
    head: {
      paddingHorizontal: "40@s", 
      paddingTop: 20,
      display: 'flex',
      flexDirection: 'row',
    },
    tobecompletedContainer1: {
      marginTop: "10@s",
    },
    tobecompletedWidth1: {
      paddingHorizontal: "40@s",
    },
    namesfieldscontainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "flex-end",
    },
    namesContainerLowerFields: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "flex-end",
    //   marginTop: '-12@s'
    },
    borderWidthInspectionDate: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '5@s',
      width: '98%'
    },
    borderWidthCarMakeModel: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '8@s', 
      width: '97%',    },
    borderWidthUserName: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '-6@s', 
      width: '102%'
    },
    borderWidthCarYearColor: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '3@s', 
      width: '99%'
    },
    borderWidthVin: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '-10@s',
      width: '103%'
    },
    borderWidthNoOfSeats: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '68@s', 
      width: '71%'
    },
    borderWidthPlateNo: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '3@s', 
      width: '99%'
    },
    borderWidthMechanicSignature: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '23@s', 
      width: '90%',
      position: 'relative', 
      marginTop: '-10px'
    },
    borderWidthCompanyName: {
      borderBottomWidth: 1,
      borderBottomColor: "#000000",
      marginLeft: '114@s',
      width: '73%'
    },
    topNameInspectionDate: {
      fontSize: "13px",
      backgroundColor:'yellow',
      color: "black",
      marginTop:'6px',
      marginLeft: '5@s',
      textAlign: 'center', 
      fontWeight: '900',
      fontFamily: 'Roboto'
    },
    topNameCarMakeModel: {
      fontSize: "13px",
      backgroundColor:'yellow',
      color: "black",
      marginTop:'6px',
      textAlign: 'center', 
      fontWeight: '900',
      marginLeft: '8@s',
      fontFamily: 'Roboto'
    },
    topNameUserName: {
      fontSize: "13px",
      backgroundColor:'yellow',
      color: "black",
      marginTop:'6px',
      textAlign: 'center', 
      fontWeight: '900',
      fontFamily: 'Roboto',
      marginLeft: '-6@s'
    },
    topNameCarYearColor: {
      fontSize: "13px",
      backgroundColor:'yellow',
      color: "black",
      marginTop:'6px',
      textAlign: 'center', 
      fontWeight: '900',
      fontFamily: 'Roboto',
      marginLeft: '3@s'
    },
    topNameVin:{
      fontSize: "13px",
      backgroundColor:'yellow',
      color: "black",
      marginTop:'6px',
      textAlign: 'center', 
      fontWeight: '900',
      fontFamily: 'Roboto',
      marginLeft: '-10@s'
    },
    topNameNoOfSeats: {
      fontSize: "13px",
      backgroundColor:'yellow',
      color: "black",
      marginTop:'6px',
      textAlign: 'center', 
      fontWeight: '900',
      fontFamily: 'Roboto',
      marginLeft: '68@s'
    },
    topNamePlateNo: {
      fontSize: "13px",
      backgroundColor:'yellow',
      color: "black",
      marginTop:'6px',
      textAlign: 'center', 
      fontWeight: '900',
      fontFamily: 'Roboto',
      marginLeft: '3@s'
    },
    topNameCompany: {
      fontSize: "13px",
      color: "black",
      marginTop:'6px',
      paddingLeft: '30@s',
      fontWeight: '900',
      fontFamily: 'Roboto',
      marginLeft: '114@s', 
      marginRight: '50@s', 
    },
    bottomName: {
      fontSize: "14px",
      color: "#000000",
      paddingTop: "6px",
      fontWeight: "200",
      fontFamily: 'Cairo'
    },
    hopSkipDriveIcon: {
      height: "90px",
      width: "120px",
    },
    fieldWraper: {
      width: "50%", 
      marginRight: "20@s", 
      flexDirection:'row'
    },
    valueContainer: {
      width:'100%',
      marginLeft:20,
      padding:2
    },
    mechanicSignature: {
      width:'100%',
      marginLeft: '23@s',
      marginTop: '10@s'
    },
    plateNoMechanicSignature: {
      width: "50%",
      marginRight: "20@s",
      flexDirection:'row'
    }
  });

const HopeSkipDriveCertificate = (props) => {
  const { isLoading, handleModal, SetModal, reportData } = props;
  useEffect(() => {
    const getToken = () => localStorage.getItem("token") || null;
    if (getToken) {
      props.getVehicleCertificate(
        { id: props?.id, companyId: props.companyId },
        props.setLoading,
        "template4"
      );
    } else {
      props.history.push("/login");
    }
  }, [props?.companyId]);

  const handleReload = () => {
    SetModal(false);
    localStorage.setItem("tabStatus", "REVIEWED");
    setTimeout(function () {
      handleModal();
    }, 200);
  };

  return (
    <div className="App">
      <PDFDownloadLink
        wrap={false}
        document={
          <Document>
            <Page size="A3" style={styles.body}>
              <View style={styles.head}>
                <Image style={styles.hopSkipDriveIcon} source={hopeSkipDriveIcon} />
                <Text style={styles.header}>
                  Vehicle Inspection Form
                </Text>
              </View>

              <View style={styles.tobecompletedWidth1}>
                <View style={styles.tobecompletedContainer1}>
                  <View style={styles.namesfieldscontainer}>
                    <View style={styles.fieldWraper}>
                      <Text style={styles.bottomName}>Inspection Date</Text>
                        <View style={styles.valueContainer} >
                      <Text style={styles.topNameInspectionDate}>
                      {moment(
                          new Date(reportData && reportData?.reviewedDate)
                        ).format("MM/DD/YYYY")}
                      </Text>
                      <View style={styles.borderWidthInspectionDate} />
                      </View>
                    </View>
                    <View style={styles.fieldWraper}>
                      <Text style={styles.bottomName}>Car Make/Model</Text>
                        <View style={styles.valueContainer} >
                      <Text style={styles.topNameCarMakeModel}>
                      {`${reportData && reportData?.make?.toUpperCase()} ${reportData && reportData?.model?.toUpperCase()}`}
                      </Text>
                      <View style={styles.borderWidthCarMakeModel} />
                      </View>
                    </View>
                  </View>

                  <View style={styles.namesContainerLowerFields}>
                    <View style={styles.fieldWraper}>
                      <Text style={styles.bottomName}>Name</Text>
                        <View style={styles.valueContainer} >
                      <Text style={styles.topNameUserName}>
                        {reportData &&
                          `${reportData?.Username?.toUpperCase()} ${
                            reportData?.UserlastName === null
                              ? ""
                              : reportData?.UserlastName?.toUpperCase()
                          }`}
                      </Text>
                      <View style={styles.borderWidthUserName} />
                      </View>
                    </View>
                    <View style={styles.fieldWraper}>
                      <Text style={styles.bottomName}>Car Year/Color</Text>
                        <View style={styles.valueContainer} >
                      <Text style={styles.topNameCarYearColor}>
                      {`${reportData && reportData?.year}/${reportData && reportData?.color?.toUpperCase()}`}
                      </Text>
                      <View style={styles.borderWidthCarYearColor} />
                      </View>
                    </View>
                  </View>

                  <View style={styles.namesContainerLowerFields}>
                    <View style={styles.fieldWraper}>
                      <Text style={styles.bottomName}>VIN</Text>
                        <View style={styles.valueContainer} >
                      <Text style={styles.topNameVin}>
                      {reportData && reportData?.vin?.toUpperCase()}
                      </Text>
                      <View style={styles.borderWidthVin} />
                      </View>
                    </View>
                    <View style={styles.fieldWraper}>
                      <Text style={styles.bottomName}># of seats (do not include driver)</Text>
                        <View style={{ width:'100%', padding:2}} >
                      <Text style={styles.topNameNoOfSeats}>
                      {`${reportData && reportData?.seats ? `0${reportData?.seats - 1}` : "-"}`}
                      </Text>
                      <View style={styles.borderWidthNoOfSeats} />
                      </View>
                    </View>
                  </View>

                  <View style={styles.namesContainerLowerFields}>
                    <View style={styles.plateNoMechanicSignature}>
                      <Text style={styles.bottomName}>License Plate #</Text>
                        <View style={styles.valueContainer} >
                      <Text style={styles.topNamePlateNo}>
                      {reportData && reportData?.licensePlateNumber?.toUpperCase()}
                      </Text>
                      <View style={styles.borderWidthPlateNo} />
                      </View>
                    </View>
                    <View style={{ ...styles.plateNoMechanicSignature, alignItems:'flex-end' }}>
                      <Text style={styles.bottomName}>Mechanic’s Signature</Text>
                        <View style={styles.mechanicSignature} >

                            <View style={styles.borderWidthMechanicSignature} >
                                {reportData && reportData?.Inspectorsignature ? (
                                    <Image
                                      style={{
                                        width: '100@s',
                                        height: '50@s',
                                          backgroundColor:'transparent',
                                        marginTop:'0px',
                                          marginBottom:'-15px',
                                        marginLeft:'20@s',
                                          position: 'absolute',
                                          bottom:'50%',
                                      }}
                                      source={`${process.env.REACT_APP_AWS_S3_LINK}/${
                                        reportData && reportData?.Inspectorsignature
                                      }?x-request=xhr`}
                                    />

                                ) : (
                                    " "
                                )}
                            </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.namesContainerLowerFields}>
                    <View style={{ width: "100%", flexDirection:'row' }}>
                      <Text style={styles.bottomName}>Name of Facility Licensed by the Bureau of Automotive Repair:</Text>
                        <View style={styles.valueContainer} >
                      <Text style={styles.topNameCompany}>
                        CHEX.AI
                      </Text>
                      <View style={styles.borderWidthCompanyName} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ marginRight: "10@s"}}>
                  <View style={{ flexWrap: "wrap", flexDirection: "row", alignSelf: 'center'}}>
                    <Image
                      style={{
                        width: "85%",
                        height: "104%",
                        marginTop: 10,
                      }}
                      source={HopeSkipDriveCheck}
                    />
                  </View>
                </View>

                
            </Page>
          </Document>
        }
        fileName="Vehicle-certificate.pdf"
      >
        {({ blob, url, loading, error }) => (
          <>
            {isLoading ? (
              <ClipLoader color={"#246DB5"} size={40} />
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    handleReload();
                  }}
                  style={{
                    fontSize: "15px",
                    marginBottom: "20px",
                    marginTop: "20px",
                    color: "white",
                    fontWeight: "bold",
                    margin: "auto",
                    background: "#3276ba",
                    padding: "13px",
                    borderRadius: "50px",
                  }}
                >
                  Download Vehicle report
                </div>
                <i className="fa fa-download" />
              </div>
            )}
          </>
        )}
      </PDFDownloadLink>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    reportData: state.vehicleInstruction.turoVehicleCertificate,
    // checksData: setTuroReportData(
    //   state.vehicleInstruction.turoVehicleCertificate?.Files
    // ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HopeSkipDriveCertificate);
