import React from "react";
import "./style.css";
import LandingBannerComponent from "../../Components/LandingBannerComponent";

import {
  AboutChexComponent,
  Fleetcard,
  Footer,
  HomeBadgeBoxComponent,
  KeyBenefitsComponent,
} from "../../Components";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";

const FleetManagerScreen = () => {
  const handleLogo = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <LandingBannerComponent
        // HomeLandingText={<div> <h3>Fleet Vehicle  <span className='darkBlue'> INSPECTIONS </span> And <span className='darkBlue'> MANAGEMENT </span>  PLATFORM </h3></div>}
        HomeLandingText={
          <div>
            {" "}
            <h3>
              {" "}
              <span className="darkBlue">Fleet & DSP </span>Vehicle inspections
              and Management Platform
            </h3>
          </div>
        }
        BannerImage={
          "https://res.cloudinary.com/dgjordf6e/image/upload/v1696328455/vechiless_dsmr9u.png"
        }
        redirectionPage={"/contactPage"}
      />

      <div className="box_badge_wrapper_outer">
        <HomeBadgeBoxComponent
          badge_image={
            "https://res.cloudinary.com/dgjordf6e/image/upload/v1665470850/image_13_Traced_vewxrn_tsjvtz.png"
          }
          badgeHeading={"Easy & Convenient"}
          badgeInfo={
            "Chex.AI's fleet management solution modernizes operations and is easy to use"
          }
        />

        <HomeBadgeBoxComponent
          badge_image={
            "https://res.cloudinary.com/dgjordf6e/image/upload/v1665470849/image_14_Traced_qmtupw_v6rhgv.png"
          }
          badgeHeading={"Advanced & Efficient"}
          badgeInfo={
            "Our system uses machine learning technology to identify any vehicle issues. Digital copy of reports is auto generated"
          }
        />

        <HomeBadgeBoxComponent
          badge_image={
            "https://res.cloudinary.com/dgjordf6e/image/upload/v1665470851/image_15_Traced_qsaw9d_uxy09q.png"
          }
          badgeHeading={"Cost Effective"}
          badgeInfo={
            "We provide the best service at a lower cost when compared to competition"
          }
        />
      </div>

      <div className="why_chex_section_ride">
        <div className="why_chex_heading">
          <h2>
            <span className="span_style_why">Why</span> Chex.AI
          </h2>
        </div>
        <div className="main_wrapper_flex_chex">
          <div className="why_chex_inner_section_wrapper_ride straigt_ride_flex">
            <div className="why_chex_content_wrapper_ride">
              <div className="content_section straigt_co_ride">
                <div className="back-circle">
                  <div className="Mobile_content">
                    <h2>Mobile Application</h2>
                    <p>
                      Customizable app allows drivers to self inspect vehicles
                      for annual safety, pre and post rides and for DVIR
                      inspections
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="why_chex_Image_wrapper_ride">
              <img
                src={
                  "https://res.cloudinary.com/dgjordf6e/image/upload/v1695889025/Group_509_maysrb.png"
                }
                alt=""
              />
            </div>
          </div>

          <div className="why_chex_inner_section_wrapper_ride reverse_ride_flex">
            <div className="why_chex_content_wrapper_ride">
              <div className="content_section reverse_content">
                <div className="back-circle">
                  <div className="Digital_record_content">
                    <h2>Digital Record Keeping</h2>
                    <p>
                      DVI Reports and Certificates are stored in app for easy
                      access
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="why_chex_Image_wrapper_ride">
              <img
                src={
                  "https://res.cloudinary.com/dgjordf6e/image/upload/v1695889016/Group_425_f31vaf.png"
                }
                alt=""
              />
            </div>
          </div>

          <div className="why_chex_inner_section_wrapper_ride straigt_ride_flex">
            <div className="why_chex_content_wrapper_ride ">
              <div className="content_section straigt_co_ride">
                <div className="back-circle">
                  <div className="Partner_content">
                    <h2>Partner Dashboard</h2>
                    <p>
                      Chex.AI dashboard updates in real-time and is a great tool
                      for asset management, data storage and analytics
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="chex_Img_wrapper">
              <img
                src={
                  "https://res.cloudinary.com/dgjordf6e/image/upload/v1695889008/Group_447_nkja8w.png"
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <KeyBenefitsComponent />
      </div>

      <div className="pricing_wrapper_main_ride" id="pricingSectionComplete">
        <div className="pricing_wrapper_main_ride_inner">
          <h2>Pricing</h2>
          <p>Get the solution your business needs for an affordable price</p>
          <div className="wrap-price-tab">
            <div className={"pricing_card_main_outer_ride"}>
              <div className="pricing_main_wrapper">
                <div className="pricing_card_box_wrap">
                  <Fleetcard
                    priceValue={"5.49"}
                    inspectionDetail={"Per Vehicle Monthly"}
                  />
                </div>
              </div>
            </div>

            <div className="points_pricing_main">
              <div className="points_pricing_wrapper_inner">
                <ul>
                  <li>
                    <div className="point_wrap_style">
                      <GoPlus
                        size={18}
                        style={{
                          color: "#FF7A00",
                          marginRight: 10,
                          marginTop: 3,
                        }}
                      />
                      <p>Unlimited Users</p>
                    </div>
                  </li>
                  <li>
                    <div className="point_wrap_style">
                      <GoPlus
                        size={18}
                        style={{
                          color: "#FF7A00",
                          marginRight: 10,
                          marginTop: 3,
                        }}
                      />
                      <p>Document Storage</p>
                    </div>
                  </li>
                  <li>
                    <div className="point_wrap_style">
                      <GoPlus
                        size={18}
                        style={{
                          color: "#FF7A00",
                          marginRight: 10,
                          marginTop: 3,
                        }}
                      />
                      <p>Customized Forms</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="points_pricing_wrapper_inner">
                <ul className="second_lists_inspection_style">
                  <li>
                    <div className="point_wrap_style">
                      <GoPlus
                        size={18}
                        style={{
                          color: "#FF7A00",
                          marginRight: 10,
                          marginTop: 3,
                        }}
                      />
                      <p>Reminders & Notifications</p>
                    </div>
                  </li>
                  <li>
                    <div className="point_wrap_style">
                      <GoPlus
                        size={18}
                        style={{
                          color: "#FF7A00",
                          marginRight: 10,
                          marginTop: 3,
                        }}
                      />
                      <p>
                        {/* Service History */}
                        ML Based Reviews
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="point_wrap_style">
                      <GoPlus
                        size={18}
                        style={{
                          color: "#FF7A00",
                          marginRight: 10,
                          marginTop: 3,
                        }}
                      />
                      <p>Dashboard Access</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/*<a href="" className='btn_pricing_ride'>*/}
          <Link
            to="/request-a-demo"
            className="btn_pricing_ride"
            onClick={handleLogo}
          >
            Get Started
          </Link>
        </div>
      </div>

      <div>
        <AboutChexComponent />
      </div>
      <div
        style={{ textAlign: "center", fontSize: "large", fontWeight: "bolder" }}
      >
        A <span style={{ fontSize: "xx-large" }}> techstars</span>
        <span style={{ color: "#58c569" }}>_</span> backed company
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default FleetManagerScreen;
