import React, { useEffect, useState } from "react";
import { ScooterUploadScreen } from "../../Screens";
import { keyScooterReupload, queryKeyCheck, reUploadFile } from "../../actions/vehicleInspection";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";


const ScooterUploadContainer = (props) => {
    const addToast = useToasts();
    const dispatch = useDispatch();
    const history = useHistory();

    const { match } = props;
    const query = queryString.parse(props.location.search);
    const [uploadingPercentage, setuploadingPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingChk, setLoadingChk] = useState(false);
    const [imageLink, setImageLink] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [chkKeyResponse, setChkKeyResponse] = useState('');



    useEffect(() => {
        dispatch(queryKeyCheck(query.inspectionId, query.key, setChkKeyResponse, addToast, setLoadingChk));
    }, []);


    const imageUploadingProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor(loaded * 100) / total;
        setuploadingPercentage(percent);
    };




    const handleImageUpload = async (event) => {



        const imageFile = event.target.files[0];

        event.target.value = '';



        dispatch(reUploadFile(imageFile, { type: imageFile.type }, match?.params?.id, imageUploadingProgress, addToast, setuploadingPercentage, setImageLink));



    }
    useEffect(() => {

        // localStorage.removeItem('scooter_image_url');
        setImageLink(JSON.parse(JSON.stringify(localStorage.getItem('scooter_image_url'))));
    }, [setuploadingPercentage, isVisible]);

    const handleDeleteModal = () => {


        // if(imageLinkData != "" || imageLinkData !== undefined){
        //     localStorage.removeItem('scooter_image_url');
        //     setIsVisible(!isVisible);
        //
        // }else{
        setIsVisible(!isVisible);
        // }
    }

    const handleDeleteYes = (imageLinkData) => {
        localStorage.removeItem('scooter_image_url');
        setIsVisible(!isVisible);
    }

    const handleSubmit = () => {

        const data = `https://chex-ai-uploads.s3.amazonaws.com/${imageLink}`
        const params = {
            imageLink: data,
            key: query?.key
        }
        dispatch(keyScooterReupload(query?.inspectionId, params, setLoading, addToast, history));

    }

    return (
        <ScooterUploadScreen
            handleImageUpload={handleImageUpload}
            uploadingPercentage={uploadingPercentage}
            imageLink={imageLink}
            handleDeleteModal={handleDeleteModal}
            isVisible={isVisible}
            handleDeleteYes={handleDeleteYes}
            loading={loading}
            handleSubmit={handleSubmit}
            chkKeyResponse={chkKeyResponse}
            loadingChk={loadingChk}
        />

    )
}

export default ScooterUploadContainer