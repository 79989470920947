import { combineReducers } from 'redux';
import authReducer from './auth';
import vehicleInsturctionsReducer from './vehicleInsturctions';
import blog from './blog'

export default combineReducers(
  Object.assign({
    auth: authReducer,
    vehicleInstruction: vehicleInsturctionsReducer,
    blog:blog,
  })
);





