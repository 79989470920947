/* eslint-disable */

import React from 'react';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import './style.css';
import '../../App.css';
import in_review from '../../Assets/in_review.png';

import { Header } from '../../Components';
import { InReviewTileComponent } from '../../Components';
import { ReviewedTileComponent, ReadyForReviewTileComponent } from '../../Components';




const InspectionSubmittedScreen = ({
  loading,
  vehiclesByStatus,
  handleVehicleDetails,
                                     handleNewCompany,
}) => {
  const history = useHistory();
  return (
    <div className="submitted-main-container">
      <div>
        <div>
          <Header title={true} />
        </div>

        <div className="submitted-conatiner">
          <div className="submitteds-in-progress-text">Inspections Submitted</div>
          {/* {vehiclesByStatus ? vehiclesByStatus.length != "0" ? 
            <i>
            <div className="submitted-tag">
          Please tap orange icon for more options.
            </div>
          </i>
          <>
          {collapse ? collapse === true ? 
          : collapse === true ?
          <i>
          <div className="submitted-tag">
          Certificate can be downloaded for “Reviewed”.inspections.
          </div>
        </i>
          : null : null}
          </>
          : null : null} */}
          <div className="all-tiles">
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : vehiclesByStatus ? vehiclesByStatus.length != "0" ? (vehiclesByStatus?.map((item, index) => {
              return (
                <>
                  {item?.status === "REVIEWED" ? (
                    <>
                      <ReviewedTileComponent
                        item={item}
                        key={index}
                        handleVehicleDetails={handleVehicleDetails}
                        handleNewCompany={handleNewCompany}
                      />
                    </>
                  ) : item?.status === "READY_FOR_REVIEW" ? (
                    <ReadyForReviewTileComponent
                    key={index}
                    item={item} />
                  ) : item?.status === "IN_REVIEW" ? (
                      <InReviewTileComponent
                          key={index}
                          item={item} />
                  ) : item?.status === "IN_PROGRESS" ? (
                      <>
                        <ReviewedTileComponent
                            item={item}
                            key={index}
                            handleVehicleDetails={handleVehicleDetails}
                            handleNewCompany={handleNewCompany}
                        />
                      </>
                  ) : null}
                </>
              );
            })) : <div className="progress-container">
              <img className="draft-image-show" src={in_review} />
              <div className="draft-message-show">{'0 Inspections Submitted'}</div>
              <div className="check-inspection-text">(Please check Inspections in Progress for incomplete inspections)</div>
            </div> : null}
          </div>
          <div className="inspection-bottom-content">
            <div className="start-inspection-button">
              <div className="select-text" onClick={() => { history.push(`/selectCompany/${false}`) }}>+ Start Inspection</div>
            </div>
            <div className="back-text" onClick={() => { history.push(`/selectOption`) }}>Or Go back to <b className="home-text"> Home </b>  page</div>
          </div>
        </div>
      </div>

    </div>



  );
};
export default InspectionSubmittedScreen;
