import React from 'react';
import './style.css';

const KeyBenefitsComponent = () => {
    return(
        <div className='key_benefits_main_outer'>
            <div className='key_benefits_image_wrapper'>
                <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470829/Frame_c4fh6w_xgqrxw.png'} alt="key benefits"/>


            </div>
            <div className='key_benefits_content_wrapper_inner'>
                <h2>
                   <span>Key </span> Benefits
                </h2>
                <p className='simple_info_benefits'>
                    Application with easy
                    to use self inspection guide & tools for users.
                </p>

                <div className='key_benefits_heighlight_content'>
                    <p>
                        Easily integratable allowing businesses to perform and manage repeatable tasks.
                    </p>
                </div>

                <p className='simple_info_benefits'>
                    Highly scalable technology enabling businesses to expand quicker.
                </p>
                <p className='simple_info_benefits'>
                    Reduces business overhead & operations cost.
                </p>

            </div>

        </div>
    )
}
export default KeyBenefitsComponent