import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const InputMainContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 100%;
  }
`;
