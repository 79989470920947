/* eslint-disable */

import React from "react";
import "./style.css";
import "antd/dist/antd.css";
import { Row, Col, Form, Input, Button } from "antd";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";

const LandingSignUpScreen = ({
                                 handleSubmit,
                                 handleCheckBox,
                                 isLoading,
                                 handleLoginRedirect,
                                 handleTermsOfUse,
                             }) => {
    return (
        <Row className="chexBannerContainer">
            <Col xl={14} md={12} sm={24} xs={24}>
                <div className="bannerContentContainer">
                    <h3 className="headingBanner_ChexForm">
                        To start your inspection, fill out the form and Register
                    </h3>
                </div>
            </Col>
            <Col xl={10} md={12} sm={24} xs={24}>
                <div className="bannerFormContainer">
                    <div className="bannerFormInsideContainer">
                        <h3 className="headingFormChex">
                            Sign up and start vehicle inspection
                        </h3>
                        <div className="formContainer_chex">
                            <Form
                                className="form form_chex"
                                autoComplete={"off"}
                                // {...layout}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={handleSubmit}
                                // onFinishFailed={onFinishFailed}
                            >
                                <div className="namingSections_chex">
                                    <Form.Item
                                        name="name"
                                        className="form-item-style-chex_first"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your first name!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="input_field_chex"
                                            placeholder="First name"
                                            autoComplete="off"

                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="last_name"
                                        className="form-item-style-chex_first"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your last name!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="input_field_chex"
                                            placeholder="Last name"
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    name="email"
                                    type="email"
                                    className="form-item-style-chex"
                                    rules={[
                                        {
                                            // type: 'email',
                                            required: true,
                                            message: "Please enter valid email!",
                                            pattern: new RegExp(
                                                /^\s*(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/
                                            ),
                                        },
                                    ]}
                                >
                                    <Input className="input_field_chex" placeholder="Email"  autoComplete="off" />
                                </Form.Item>

                                <Form.Item
                                    name="phone"
                                    className="form-item-style"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter phone number!",
                                        },
                                    ]}
                                >
                                    <InputMask
                                        mask="+1(999)999 9999"
                                        className="form-control"
                                        id="phone"
                                        placeholder="phone number"
                                        type="text"
                                        name="phone"
                                        autoComplete="phone"
                                        required
                                        disabled={false}
                                    >
                                        {() => (
                                            <Input
                                                id="phone"
                                                placeholder="phone number"
                                                type="text"
                                                name="phone"
                                                className="input_field_chex"
                                                autoComplete="off"
                                            />
                                        )}
                                    </InputMask>
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    className="form-item-style"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your password!",
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        style={{ fontFamily: "Poppins" }}
                                        className="input_field_chex"
                                        placeholder="Password"
                                        autoComplete="off"
                                    />
                                </Form.Item>
                                <div style={{ marginBottom: 15, marginTop: 10 }}>
                                    <div
                                        style={{ display: "flex"}}
                                    >
                                        {/*<div></div>*/}
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div style={{ display: "flex" }}>
                                                <label class="newcontainer">
                                                    <input onChange={handleCheckBox} type="checkbox" />
                                                    <span class="newcheckmark_chex"></span>
                                                </label>
                                                <div className="register-checkbox-text_chex">
                                                    By checking the box, you accept our{" "}
                                                    <span
                                                        style={{
                                                            color: "#2566c7",
                                                            cursor: "pointer",
                                                            textDecoration: "underline",
                                                            fontWeight: "bold",
                                                        }}
                                                        onClick={() => handleTermsOfUse()}
                                                    >
                          Terms of Use
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div></div>*/}
                                    </div>

                                </div>

                                <Form.Item>
                                    <Button
                                        disabled={isLoading ? true : false}
                                        className="button-wrapper_chex"
                                        htmlType="submit"
                                    >
                                        {isLoading ? (
                                            <ClipLoader color={"white"} size={20} />
                                        ) : (
                                            "Register"
                                        )}
                                    </Button>
                                </Form.Item>

                                <div className="login-landing-signup">
                                    <div>
                                        Already have an account?{" "}
                                        <span
                                            style={{
                                                color: "#2566c7",
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => handleLoginRedirect()}
                                        >
                          Login
                        </span>
                                    </div>
                                </div>
                                {/*<Form.Item>s*/}
                                {/*    <label className="newcontainer">*/}
                                {/*        <input onChange={handleCheckBox} type="checkbox"/>*/}
                                {/*        <span className="newcheckmark_chex"></span>*/}
                                {/*    </label>*/}
                                {/*    <Link to="/login" style={{ color: 'white' }}>*/}
                                {/*        Already have an account? <span style={{ color: 'white' }}>Sign In!</span>*/}
                                {/*    </Link>*/}
                                {/*</Form.Item>*/}
                            </Form>
                        </div>
                        {/*<div className='terms_conditionsContainer'>*/}
                        {/*    <p className='termsConditionsText'>*/}
                        {/*        By checking the box, you accept our <Link to="/termsAndPolicy" style={{color:'#1f6adc', fontWeight:'bold'}}> Terms of use </Link>*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Col>
        </Row>
        //    <div className='chexBannerContainer'>
        //    </div>
    );
};

export default LandingSignUpScreen;


