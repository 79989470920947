/* eslint-disable */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useToasts } from 'react-toast-notifications';

import ActionCreators from '../../actions';
import { setCompanies } from '../../utils/functions';
import NewContactScreen from "../../Screens/NewContactScreen";

const NewContactContainer = (props) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });
    const [capcha, setCapcha] = useState(null)
    const [loading, setLoading] = useState();
    const { addToast } = useToasts();
    let valid;
    
    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = () => {

        if (formData.firstName === '' || formData.lastName === '' || formData.message === '' || formData.email === '') {
            addToast('Fill all the details first', { appearance: 'error' });
        }
        if (formData.email !== '') {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            valid = !(!formData.email || regex.test(formData.email) === false);
            if (valid === false) {
                addToast('Enter a valid email', { appearance: 'error' });
            }
        }
        if (((formData.firstName !== '' && formData.lastName !== '' && formData.message !== '' && formData.email !== '')) && (capcha === "" || capcha === null)) {
            addToast('Check the reCAPTCHA first', { appearance: 'error' });
        }
        if (formData.firstName !== '' && formData.lastName !== '' && formData.message !== '' && valid === true && capcha !== null) {
            const data = {
                ...formData,
                status: true
            }
            const { contactUs } = props;
            contactUs(data, addToast, setLoading, setFormData);
        }
    }


    const onRecapchaChange = (value) => {
        setCapcha(value);
    }


    return <NewContactScreen
        loading={loading}
        formData={formData}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        onCapchaChange={onRecapchaChange}
        scrollValue={20}
    />
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        vehicleData: state.auth.vehicleData,
        isLoading: state.auth.isAuthLoading,
        companies: setCompanies(state.auth.companies),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewContactContainer);
