import React from "react";
import { BiCheck } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { Modal } from "antd";

import "./style.css";

import {
  Header,
  AfterReviewingScreenProductCardComponent,
} from "../../Components";

const VehicleAfterReviewingScreen = ({
  vehicleDetails,
  loading,
  loadingFile,
  filesDetails,
  handleModal,
  handleModalClose,
  isModalVisible,
  url,
  type,
}) => {
  return (
    <div className="after-reviewing-main_container">
      <Modal
        visible={isModalVisible}
        title={false}
        style={{ width: "100%" }}
        wrapClassName="instruction-modalls"
        footer={null}
        className="after-review-in-screen"
        onCancel={handleModalClose}
        mask={true}
        // closeIcon={false}
        maskStyle={{ backgroundColor: "#266FB7" }}
        bodyStyle={{
          width: "100%",
          textAlign: "center",

          // background: 'linear-gradient(182.55deg, #1876CC -17.67%, #051532 115.19%), #191A1B',
        }}
      >
        <div
          style={{ marginTop: "80px", paddingTop: "50px", marginLeft: "90%" }}
        >
          <AiOutlineCloseCircle
            color="white"
            size={30}
            onClick={() => handleModalClose()}
          />
        </div>
        <div style={{ marginTop: "80%" }}>
          {type === "video" ? (
            <video
              preload="metadata"
              controls
              autoPlay={false}
              id="myVideo"
              className="produc-card-bg_file"
            >
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <div>
              <img className="img_container" alt="" src={url} />
            </div>
          )}
        </div>
      </Modal>
      <div className="container">
        <div className="main-container-width">
          {/* <AfterReviewingHeaderComponent /> */}
          <Header title={true} arrow={true} path={"/selectoption"} />
          <div className="after-reviewing-white_container">
            <div className="vechicle-detail-head-text">Vehicle Details</div>
            {vehicleDetails?.finalStatus ? (
              vehicleDetails?.finalStatus === "pass" ? (
                <>
                  <div className="final-status-container">
                    <div className="final-status-text">Final Status</div>
                    <div className="pass-container">
                      <div className="checked-icon-container">
                        <BiCheck color="white" size={16} />
                      </div>
                      <div className="pass-text">
                        {loading ? (
                          <ClipLoader color={"#246DB5"} size={20} />
                        ) : (
                          "Pass"
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : vehicleDetails?.finalStatus === "fail" ? (
                <>
                  <div className="final-status-container">
                    <div className="final-status-text">Final Status</div>
                    <div className="pass-container">
                      <div className="Unchecked-icon-container">
                        <IoIosClose color="white" size={20} />
                      </div>
                      <div className="pass-text">
                        {loading ? (
                          <ClipLoader color={"#246DB5"} size={20} />
                        ) : (
                          "Fail"
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null
            ) : null}
            {/* {vehicleDetails?.vehicle?.vehicleDoors ? ( */}
            <div className="no-of-door_container">
              <div className="no-of-door_text">
                No of Doors
                <p className="after-review-number">
                  {" "}
                  {loading ? (
                    <ClipLoader color={"#246DB5"} size={20} />
                  ) : (
                    vehicleDetails?.Vehicle?.vehicleDoors?.toString()
                  )}
                </p>
              </div>
            </div>
            {/* // ) : null} */}
            <textarea
              disabled={true}
              className="text-area-container"
              value={vehicleDetails?.remarks}
              type="textarea"
              placeholder="Description"
            />

            <div className="border-align-container">
              <div className="after-reviewing-bottom_border" />
            </div>
            <div className="after-reviewing-wrap_container">
              {loadingFile ? (
                <ClipLoader color={"white"} size={20} />
              ) : (
                filesDetails?.map((item) => {
                  return (
                    <AfterReviewingScreenProductCardComponent
                      handleModal={handleModal}
                      handleModalClose={handleModalClose}
                      item={item}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VehicleAfterReviewingScreen;
