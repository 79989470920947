import React from 'react';
// import { Button } from '../../Components';
import { ClipLoader } from 'react-spinners';

import './style.css';
import '../../App.css';

const UnSubscribeScreen = ({ loading , error }) => {
  return (
    <div className="intro-main-container">
      <div className="intro-imageBackground-main">
      {loading ? 
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',width:'100%'}} >
              <ClipLoader color={'#246DB5'} size={40} />
              </div>
            : null}
        <div className="intro-mainBgss">
          <div className="thank-contentMain-screen">
            <div className="thank-text-contaner">
                {error ?
              <div className="thankyou-contentP">Something Went Wrong</div>
              :
              <>
              <div className="thankyou-contentP">Thank You For Using</div>
              <div className="thankyou-chex-contentP" style={{marginBottom: 30}}>CHEX<span className="logo-span-color">.AI</span></div>
              <div className="thankyou-contentP">You Have Successfully</div>
                </>
                }  
              {error ? 
              <div className="thankyou-chex-contentP">Try Again</div>
              :
              <div className="thankyou-chex-contentP">UnSubscribed</div>
              }
              <div className="intro-content-footer-area">
                <div className="intro-MtB5"></div>
                <div className="intro-contentDiv-button"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnSubscribeScreen;