import React, { useState } from "react";

import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReqDemoScreen } from "../../Screens";
import { Form } from "antd";

const ReqADemoPageContainer = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [checkboxValue, setCheckBox] = useState(false);
  const handleCheckBox = () => {
    setCheckBox(!checkboxValue);
  };

  const handleSubmit = (params , checkboxValue) => {
    const { reqDemo } = props;
    if (params?.phone.includes("_")) {
      addToast("Please enter a valid number", { appearance: "error" });
    } else {
      reqDemo(params, history, addToast , checkboxValue);
      form.resetFields();
    }
  };
  //
//   const handleLoginRedirect = () => {
//     window.location.href = "/";
//   };
  //
  const handleReDirect = () => {
    window.location.href = "/getStarted";
  };
  //
//   const handleTermsOfUse = () => {
//     window.location.href = "/termsAndPolicy?uber=true";
//   };

//   const breakPoints = [
//     { width: 1, itemsToShow: 1 },
//     { width: 650, itemsToShow: 1, itemsToScroll: 1 },
//     { width: 768, itemsToShow: 2 },
//     { width: 1200, itemsToShow: 2 },
//   ];
  return (
    <ReqDemoScreen
    //   handleTermsOfUse={handleTermsOfUse}
    //   handleLoginRedirect={handleLoginRedirect}
    //   breakPoints={breakPoints}
      handleReDirect={handleReDirect}
      handleSubmit={handleSubmit}
      handleCheckBox={handleCheckBox}
      isLoading={props.isLoading}
      form={form}
      Form={Form}
    />
  );
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isAuthLoading,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReqADemoPageContainer);
