/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import ActionCreators from "../../actions";
import { SignUpScreen } from "../../Screens";
import { LyftConfirmationModal } from "../../Components";
import { setCompanies } from "../../utils/functions";

const SignUpContainer = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [checkboxValue, setCheckBox] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dropDown, setDropdownValue] = useState(false);

  const handleSubmit = (params) => {
    const { register } = props;
    const isMysterious = localStorage.getItem("isMysterious") === "true";

    params.isMysterious = isMysterious;

    if (params?.phone.includes("_")) {
      addToast("Please enter a valid number", { appearance: "error" });
    } else {
      if (checkboxValue) {
        register(params, history, addToast);
      } else {
        addToast("Please accept term of use", { appearance: "warning" });
      }
    }
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleCheckBox = () => {
    setCheckBox(!checkboxValue);
  };

  const handleDropDown = () => {
    setDropdownValue(!dropDown);
  };

  return (
    <>
      <SignUpScreen
        handleModal={handleModal}
        dropDown={dropDown}
        showModal={showModal}
        handleCheckBox={handleCheckBox}
        // companies={props.companies}
        handleSubmit={handleSubmit}
        isLoading={props.isLoading}
        handleDropDown={handleDropDown}
      />
      {/* <LyftConfirmationModal handleSubmitLyftModal={handleSubmitLyftModal} visibleLyft={visible} /> */}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isAuthLoading,
    companies: setCompanies(state.auth.companies),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
