/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import ActionCreators from '../../actions';
import { TransactionScreen } from '../../Screens';
import { setCompanies } from '../../utils/functions';

const TranscationContainer = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { addToast } = useToasts();

  const [price, setPrice] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('currentUser'));
  //   const { getPaymentPriceInfo, match } = props;
  //   const promoCode = localStorage.getItem('promoCode')
  //   // if (user?.updates || user?.updates === null || user?.updates === undefined) {
  //   //   window.location.replace('/logoutForChanges');
  //   // }
  //   getPaymentPriceInfo(match?.params?.id, setPrice, setPriceLoading, promoCode);
  // }, []);


  const handleSubmit = async () => {

    localStorage.removeItem("isUberComapany");
    localStorage.removeItem("inspectionData");
    // localStorage.removeItem("params");
    
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    setButtonDisable(true);
    const result = await stripe.createToken(card);
    const { startPayment, match } = props;
    const promoCode = localStorage.getItem('promoCode')
    // const ReferralCode=localStorage.getItem('ReferralCode')
    if (result.error) {
      addToast(`${result.error.message}`, { appearance: 'error' });
      setButtonDisable(false);
      // toast(result.error.message, { type: 'error' });
      // setDisableButton(false);
    } else {
      
      // setDisableButton(true);

      // if(ReferralCode)
      // {
      //   const Referralcode  = ReferralCode.replace(/\s+/g, '');
      //   startPayment({ source: result.token.id, vehicleId: match?.params?.id, code: promoCode ,referralCode:Referralcode }, addToast, setLoading, history, setButtonDisable );
      // }
     
// else{
  startPayment({ source: result.token.id, vehicleId: match?.params?.id, code: promoCode ,
    // referralCode:ReferralCode
   }, addToast, setLoading, history, setButtonDisable );
// }
       


    }
    // setDisableButton(false);
  };
  return <TransactionScreen priceLoading={priceLoading} buttonDisable={buttonDisable} price={props?.location?.state?.totalPrice} history={history} inspectionId={props?.match?.params?.id} vehicleId={props?.match?.params?.vehicleId} loading={loading} handleSubmit={handleSubmit} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehicleData: state.auth.vehicleData,
    isLoading: state.auth.isAuthLoading,
    companies: setCompanies(state.auth.companies),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TranscationContainer);
