import React from "react";
import "./style.css";

export const KeyDiffSection = () => {
  return (
    <div className="key-diff-main ">
      <section className="key-diff-section">
        <div className="diff-image-section">
          <img
            src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240084/key-diff-img_nrynic.png"
            alt="Diff"
          />
        </div>
        <div className="diff-text-section">
          <div className="diff-heading">Key Differentiators</div>
          <div className="diff-points">
            <div className="diff-point">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726488891/Vector_vn24o5.png"
                alt="icon"
                className="diff-icon"
              />
              <span>
                Mobile application with easy to use self inspection guide &
                tools for users.
              </span>
            </div>
            <div className="diff-point">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726488891/Vector_vn24o5.png"
                alt="icon"
                className="diff-icon"
              />
              <span>
                Easily integratable allowing businesses to perform and manage
                repeatable tasks.
              </span>
            </div>
            <div className="diff-point">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726488891/Vector_vn24o5.png"
                alt="icon"
                className="diff-icon"
              />
              <span>
                Highly scalable technology enabling businesses to expand
                quicker.
              </span>
            </div>
            <div className="diff-point">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726488891/Vector_vn24o5.png"
                alt="icon"
                className="diff-icon"
              />
              <span>Reduces business overhead & operations cost.</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
