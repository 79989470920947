import React from 'react';
// import { Button } from '../../Components';
import { ClipLoader } from 'react-spinners';

import './style.css';
import '../../App.css';

const ThankyouReupload = () => {
    return (
        <div className="intro-main-container">
            <div className="intro-imageBackground-main-reupoad">

                <div className="intro-mainBgss">
                    <div className="thank-contentMain-screen">
                        <div className="thank-text-contaner">

                                <>
                                    <div className="thankyou-contentP">Thank You For Using</div>
                                    <div className="thankyou-chex-contentP" style={{marginBottom: 30}}>CHEX<span className="logo-span-color">.AI</span></div>
                                </>
                            <div className="intro-content-footer-area">
                                <div className="intro-MtB5"></div>
                                <div className="intro-contentDiv-button"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankyouReupload;
