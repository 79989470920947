/* eslint-disable */

import React, { useEffect } from "react";
import moment from "moment";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { setLyftReportData } from "../../utils/functions";
// import { imageBaseUrl } from "../utils/api";
import TicketImg from "../../Assets/tickimg.png";
import lyftCarImage from "../../Assets/lyftCertificateForNevadaCarsPics.png";
import LyftImg from "../../Assets/liftimg.png";
import Vector from "../../Assets/Vector.png";
import lyftCheckBox from "../../Assets/lyftCheckBox.png";
import { height } from "dom-helpers";

import kalamFont from "../../font/Kalam-Light.ttf";

Font.register({ family: "kalamFont", format: "truetype", src: kalamFont });

const styles = StyleSheet.create({
  body: {
    // paddingBottom: 35,
  },
  headerWidth: {
    marginBottom: "5px",
  },
  header: {
    backgroundColor: "#00B3AD",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
  },
  californiaText: {
    color: "white",
    fontSize: "20px",
    textTransform: "uppercase",
  },
  boxContainer: {
    padding: "10px",
  },
  lyftdrivertextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
  },
  boxWidth: {
    paddingHorizontal: 20,
    // paddingTop: 10,
  },
  lyftdriverText: {
    fontSize: "20px",
    fontStyle: "sans-serif",
    fontWeight: "bold",
    // lineHeight: "42px",
    color: "black",
    // fontFamily: "sans-serif",
    float: "left",
  },
  allfieldsText: {
    color: "#000",
    fontSize: "20px",
    fontWeight: "400",
    marginLeft: "200px",
  },
  nameinputContainer: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    // width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  nameinputContain: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    // width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  lyftdriverName: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    // lineHeight: "23px",
    color: "#47484F",
    marginBottom: 0,
    // width:"100px",
    marginTop: "3px",
  },
  lyftdriver: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    // lineHeight: "23px",
    color: "#47484F",
    marginBottom: 0,
    // width:"100px",
    marginTop: "3px",
    borderTop: "3px solid #A6A4A4",
    paddingTop: "5px",
  },
  nameText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    // fontFamily: 'kalamFont',
    // lineHeight: "23px",
    color: "black",
    // width: "100%",
    borderBottom: "3px solid #A6A4A4",
  },
  threeinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
  },
  nameinputContainerOne: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    width: "40%",
    marginRight: "10px",
  },
  nameinputContainerTwo: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    width: "60%",
    // marginRight: "10px",
    marginLeft: "10px",
  },
  nameinputContainerThree: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    width: "40%",
  },
  threeinputFields: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    // fontFamily: 'kalamFont',
    // lineHeight: "23px",
    color: "black",
    borderBottom: "3px solid #A6A4A4",
    // backgroundColor: 'red',
    marginBottom: "-3px",
  },
  licenseplateContainer: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    width: "40%",
    marginRight: "10px",
  },
  codeText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    // lineHeight: "23px",
    color: "black",
    marginLeft: "50%",
  },
  vinContainer: {
    fontSize: "12px",
    fontWeight: "30",
    fontStyle: "normal",
    color: "black",
  },
  twoinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  vintopBorder: {
    borderTopWidth: 1,
    borderTopColor: "#47484F",
    width: "100%",
  },
  topText: {
    marginTop: "30px",
    fontSize: "12px",
    paddingLeft: "8px",
    paddingRight: "10px",
  },
  vehicleinspectionPadding: {
    paddingHorizontal: "30px",
  },
  checkboxesmainContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30px",
  },
  checkboxesmainContainerTwo: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30px",
    marginTop: "10px",
  },
  inspectionfirstContainer: {
    width: "94%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionsecondContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionText: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    textTransform: "uppercase",
  },
  inspectiontextTwo: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    marginLeft: "15px",
    textTransform: "uppercase",
  },
  passText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    marginRight: "15px",
    textTransform: "uppercase",
  },
  failText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    textTransform: "uppercase",
  },
  passfailContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  numbersText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
  },
  detailsText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "black",
    marginLeft: "5px",
  },
  failpassbox: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "black",
    marginLeft: "15px",
    width: "92%",
    padding: "10px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    height: "50px",
    top: 10,
  },

  boxinspectionText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    // lineHeight: "21px",
    color: "black",
    textTransform: "uppercase",
  },
  circleoneText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    // lineHeight: "21px",
    color: "#716E6E",
    marginTop: "5px",
  },
  boxpassText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "black",
  },
  orangeCircle: {
    borderWidth: 5,
    borderColor: "black",
    borderRadius: "50%",
    height: "75px",
    width: "75px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  boxfailText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "black",
  },
  boxinspectionContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
  },
  tobecompletedContainer: {
    backgroundColor: "#E4E5E7",
    padding: "5px",
    flexDirection: "row",
    alignItems: "center",
  },
  tobecompletedWidth: {
    paddingHorizontal: "30px",
    marginTop: "20px",
  },
  tobecompletedtextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  tobecompletedText: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "23px",
    color: "black",
    textDecoration: "underline",
    textTransform: "uppercase",
  },
  fieldsText: {
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "#716E6E",
    textTransform: "uppercase",
  },

  inpectionText: {
    fontSize: "16px",
    color: "#47484F",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  documentexpiryText: {
    fontSize: "12px",
    color: "#47484F",
  },
  dateText: {
    fontSize: "16px",
    letterSpacing: "1px",
  },
  datemainContainer: {
    marginTop: "10px",
    marginLeft: "52%",
  },
  namesfieldscontainer: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "18px",
    alignItems: "flex-end",
  },
  borderWidth: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  topName: {
    fontSize: "14px",
    color: "black",
    marginLeft: "12px",
  },
  bottomName: {
    fontSize: "12px",
    color: "#47484F",
    textTransform: "uppercase",
  },

  tickpassContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "28px",
  },
  tickfailContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "28px",
  },
  inspectiondateContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "95%",
  },
});

const styles1 = StyleSheet.create({
  inspectionfirstContainer: {
    marginBottom: "10px",
    width: "98%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionText: {
    // marginTop: '20@s',
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "#000000",
    textTransform: "uppercase",
  },
  passText: {
    fontSize: "12px",
    // marginBottom: '10px',
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21@s",
    color: "#716E6E",
    marginRight: "38px",
    textTransform: "uppercase",
  },
  failText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21@s",
    color: "#716E6E",
    // marginBottom: '10@s',
    textTransform: "uppercase",
  },
  passfailContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  passfailContainer1: {
    flexDirection: "row",
    alignItems: "center",
  },
  numbersText: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#000000",
  },
  numbersText2: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#000000",
    marginTop: "-23px",
  },
  detailsText: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700px",
    // lineHeight: "21px",
    color: "black",
    marginLeft: "5px",
  },
  detailsText2: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700px",
    // lineHeight: "21@s",
    color: "#828282",
    marginLeft: "5px",
  },
  shieldText: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "#828282",
    marginLeft: "15px",
    marginTop: "10px",
    marginBottom: "10px",
  },

  dimentionBorderWidth: {
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
  },

  tireDimentions: {
    // fontSize: "10px",
    // textAlign: 'center',
    // // position: 'relative',
    // color: "red",
    // backgroundColor: 'yellow',
    // marginTop: '10@s',

    fontSize: "10px",
    color: "#000",
    marginTop: "10px",
  },

  bottomPara: {
    paddingHorizontal: "25px",
    fontSize: 10,
    lineHeight: 1.6,
    marginTop: "30px",
    fontWeight: "700",
    color: "#000000",
  },
  bottomPara1: {
    paddingHorizontal: "30px",
    fontSize: 10,
    marginTop: "15px",
    marginLeft: "10px",
    color: "black",
  },
  bottomPara2: {
    paddingHorizontal: "30px",
    fontSize: 10,
    marginTop: "15px",
    marginLeft: "14px",
    color: "black",
  },
  tickpassContainer: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: "2px",
    height: "15px",
    width: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "50px",
  },
  tickpassContainertires: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: "2px",
    height: "15px",
    width: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  tickSelectContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
  },
  tickfailContainer: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: "2px",
    height: "15px",
    width: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  tickfailContainer1: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
  },
  inspectiondateContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "95%",
  },
  statetext: {
    fontSize: "10px",
    marginLeft: "10px",
    color: "gray",
    marginRight: "10px",
  },
  statewrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  stateinspection1: {
    fontSize: "11px",
    color: "gray",
    marginTop: "5px",
    marginLeft: "9.5px",
  },
  turoImage: {
    height: "40px",
    width: "100px",
  },
  detailsText2: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700px",
    // lineHeight: "21@s",
    color: "#828282",
    marginLeft: "19px",
  },
});

function LyftCertificateColorado(props) {
  const { checksData, reportData, isLoading, handleModal, SetModal } = props;
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const getToken = () => localStorage.getItem("token") || null;
    if (getToken) {
      props.getVehicleCertificate(
        { id: props?.id, companyId: props.companyId },
        props.setLoading,
        "template3"
      );
    } else {
      props.history.push("/login");
    }
  }, [props?.companyId]);

  const handleReload = () => {
    SetModal(false);
    localStorage.setItem("tabStatus", "REVIEWED");
    setTimeout(function () {
      handleModal();
    }, 200);
  };
  return (
    <div className="App">
      <PDFDownloadLink
        wrap={false}
        document={
          <Document>
            <Page size="A3" style={styles.body}>
              <View style={styles.headerWidth}>
                <View style={styles.header}>
                  <Image style={{ width: "40px" }} source={LyftImg} />
                  <Text style={styles.californiaText}>
                    COLORADO VEHICLE INSPECTION
                  </Text>
                </View>
              </View>
              <View style={styles.boxWidth}>
                <View style={styles.boxContainer}>
                  <View style={styles.lyftdrivertextContainer}>
                    <Text style={styles.lyftdriverText}>LYFT DRIVER INFO</Text>
                    {/* <Text style={styles.allfieldsText}>
                    ALL FIELDS ARE MANDATORY
                  </Text> */}
                  </View>

                  <View style={styles.nameinputContainer}>
                    <View style={{ width: "50%", marginRight: "10px" }}>
                      <Text style={styles.nameText}>
                        {reportData &&
                          `${reportData?.Username} ${
                            reportData?.UserlastName !== null
                              ? reportData?.UserlastName
                              : " "
                          }`}
                      </Text>
                      <Text style={styles.lyftdriverName}>
                        LYFT DRIVER NAME
                      </Text>
                    </View>
                    <View style={{ width: "50%", marginLeft: "10px" }}>
                      <Text style={styles.nameText}>
                        {reportData && reportData?.Useremail}
                      </Text>
                      <Text style={styles.lyftdriverName}>EMAIL ADDRESS</Text>
                    </View>
                  </View>
                  <View style={styles.threeinputfieldContainer}>
                    <View style={styles.nameinputContainerOne}>
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.licensePlateNumber}
                      </Text>
                      <Text style={styles.lyftdriverName}>LICENSE PLATE #</Text>
                    </View>
                    <View style={styles.nameinputContainerTwo}>
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.vin}
                      </Text>
                      <Text style={styles.lyftdriverName}>VIN # </Text>
                    </View>
                  </View>
                  <View style={styles.twoinputfieldContainer}>
                    <View style={styles.licenseplateContainer}>
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.make}
                      </Text>
                      <Text style={styles.lyftdriverName}>VEHICLE MAKE</Text>
                    </View>
                    <View
                      style={{
                        width: "40%",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.model}
                      </Text>
                      <Text style={styles.lyftdriverName}>VEHICLE MODEL</Text>
                    </View>
                    <View style={{ width: "20%", marginLeft: "10px" }}>
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.year}
                      </Text>
                      <Text style={styles.lyftdriverName}>VEHICLE YEAR</Text>
                    </View>
                  </View>
                  <View style={styles.nameinputContain}>
                    <View
                      style={{
                        width: "40%",
                        marginRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      {/* <Image
                        style={{
                          position: "absolute",
                          height: "100px",
                          width: "100px",
                          top: "-50px",
                          right: "100px",
                        }}
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                          reportData && reportData.Inspectorsignature
                        }?x-request=xhr`}
                      /> */}
                      <Text style={styles.lyftdriver}>
                        LYFT DRIVER SIGNATURE
                      </Text>
                    </View>
                    <View style={{ width: "40%", marginLeft: "10px" }}>
                      <Text style={styles.nameText}>
                        {reportData && reportData?.Userphone}
                      </Text>
                      <Text style={styles.lyftdriverName}>
                        LYFT DRIVER PHONE NUMBER
                      </Text>
                    </View>
                    <View style={{ width: "20%", marginLeft: "20px" }}>
                      <Text style={styles.nameText}>
                        {moment(
                          new Date(reportData && reportData?.reviewedDate)
                        ).format("MM/ DD /YYYY")}
                      </Text>
                      <Text style={styles.lyftdriverName}>DATE</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.vehicleinspectionPadding}>
                <View style={styles.lyftdrivertextContainer}>
                  <Text style={styles.lyftdriverText}>VEHICLE INSPECTION</Text>
                </View>
              </View>
              {/* <View style={{ marginLeft: "20px" }}>
              <Text style={styles.inspectionpointsText}>
                INSPECTION POINTS
              </Text>
              <View style={{ flexWrap: "wrap", flexDirection: "row" }}>
                <Image
                  style={{
                    width: "100%",
                    marginLeft: -10,
                    // marginTop: "20px",
                  }}
                  source={CheckBox}
                />
              </View>
            </View> */}

              {/* Old colorado download code */}
              {/* ________________________________________________ */}
              {/* <View style={{ flexDirection: "row", paddingHorizontal: "30px" }}>
              <View style={{ flexDirection: "column", width: "50%" }}>
                <View style={styles.inspectionfirstContainer}>
                  <Text style={styles.inspectionText}>Inspection Point</Text>
                  <View style={styles.passfailContainer}>
                    <Text style={styles.passText}>Pass</Text>
                    <Text style={styles.failText}>Fail</Text>
                  </View>
                </View>

                {vehicleChecksArray?.map((check, index) =>
                  index <= 11 ? (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text style={styles.numbersText}>{index + 1}.</Text>
                        <Text style={styles.detailsText}>
                          {check.Check.name}
                        </Text>
                      </View>
                      <View style={styles.passfailContainer}>
                        {check.status == true || check.status == 1 ? (
                          <View style={styles.tickpassContainer}>
                            <Image
                              style={{ width: "16px", marginLeft: "2px" }}
                              source={TicketImg}
                            />
                          </View>
                        ) : (
                          <View style={styles.tickpassContainer}></View>
                        )}
                        {check.status == false || check.status == 0 ? (
                          <View style={styles.tickfailContainer}>
                            <Image
                              style={{ width: "16px", marginLeft: "2px" }}
                              source={TicketImg}
                            />
                          </View>
                        ) : (
                          <View style={styles.tickfailContainer}></View>
                        )}
                      </View>
                    </View>
                  ) : null
                )}
              </View>

              <View style={{ flexDirection: "column", width: "50%" }}>
                <View style={styles.inspectionfirstContainer}>
                  <Text style={styles.inspectionText}>Inspection Point</Text>
                  <View style={styles.passfailContainer}>
                    <Text style={styles.passText}>Pass</Text>
                    <Text style={styles.failText}>Fail</Text>
                  </View>
                </View>

                {vehicleChecksArray?.map((check, index) =>
                  index > 11 ? (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text style={styles.numbersText}>{index + 1}.</Text>
                        <Text style={styles.detailsText}>
                          {check.Check.name}
                        </Text>
                      </View>
                      <View style={styles.passfailContainer}>
                        {check.status == true || check.status == 1 ? (
                          <View style={styles.tickpassContainer}>
                            <Image
                              style={{ width: "16px", marginLeft: "2px" }}
                              source={TicketImg}
                            />
                          </View>
                        ) : (
                          <View style={styles.tickpassContainer}></View>
                        )}
                        {check.status == false || check.status == 0 ? (
                          <View style={styles.tickfailContainer}>
                            <Image
                              style={{ width: "16px", marginLeft: "2px" }}
                              source={TicketImg}
                            />
                          </View>
                        ) : (
                          <View style={styles.tickfailContainer}></View>
                        )}
                      </View>
                    </View>
                  ) : null
                )}

                <View
                  style={[
                    styles.inspectionsecondContainer,
                    { marginTop: "20px", marginLeft: -10 },
                  ]}
                >
                  <View style={styles.failpassbox}>
                    <View style={styles.boxinspectionContainer}>
                      <Text style={styles.boxinspectionText}>
                        VEHICLE INSPECTION
                      </Text>
                      <Text style={styles.circleoneText}>
                        (Please circle one)
                      </Text>
                    </View>
                    {reportData &&
                    reportData.finalStatus === "pass" ? (
                      <View style={styles.orangeCircle}>
                        <Text style={styles.boxpassText}>PASS</Text>
                      </View>
                    ) : (
                      <Text style={styles.boxpassText}>PASS</Text>
                    )}
                    {reportData &&
                    reportData.finalStatus === "fail" ? (
                      <View style={styles.orangeCircle}>
                        <Text style={styles.boxpassText}>FAIL</Text>
                      </View>
                    ) : (
                      <Text style={styles.boxpassText}>FAIL</Text>
                    )}
                  </View>
                </View>
              </View>
            </View> */}
              {/* ________________________________________________ */}

              {/* New download code for colorado */}

              <View
                style={{
                  marginTop: "10px",
                  flexDirection: "row",
                  paddingHorizontal: "20px",
                }}
              >
                <View style={{ flexDirection: "column", width: "50%" }}>
                  <View style={styles1.inspectionfirstContainer}>
                    <Text style={styles1.inspectionText}>
                      INSPECTION POINTS
                    </Text>
                    <View style={styles1.passfailContainer}>
                      <Text style={styles1.passText}>PASS</Text>
                      <Text style={styles1.failText}>FAIL</Text>
                    </View>
                  </View>

                  {checksData?.map((check, index) =>
                    index < 8 ? (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles1.numbersText}>{index + 1}</Text>
                            <Text style={styles1.detailsText}>
                              {check.Check.name}
                            </Text>
                          </View>
                          <View style={styles1.passfailContainer}>
                            {check.status == true || check.status == 1 ? (
                              <View style={styles1.tickpassContainer}>
                                <Image
                                  style={{ width: "8px", marginLeft: "0px" }}
                                  source={Vector}
                                />
                              </View>
                            ) : (
                              <View style={styles1.tickpassContainer}></View>
                            )}
                            {check.status == false || check.status == 0 ? (
                              <View style={styles1.tickfailContainer}>
                                <Image
                                  style={{ width: "8px", marginLeft: "0px" }}
                                  source={Vector}
                                />
                              </View>
                            ) : (
                              <View style={styles1.tickfailContainer}></View>
                            )}
                          </View>
                        </View>
                        {check?.Check?.SubChecks?.length > 0 &&
                          check?.Check?.SubChecks?.map((subCheck) => (
                            <>
                              <View
                                style={{
                                  flexDirection: "row",
                                  // alignItems: "center",
                                  justifyContent: "space-between",
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text style={styles1.detailsText2}>
                                    {subCheck?.checkName}
                                  </Text>
                                </View>
                                {subCheck?.type === "input" ? (
                                  <View
                                    style={{
                                      borderBottom: "1px solid black",
                                      width: "80px",
                                      fontSize: "10px",
                                      marginRight: "18px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Text>
                                      {subCheck?.SubInspectionCheck?.checkValue}
                                    </Text>
                                  </View>
                                ) : (
                                  <View style={styles1.passfailContainer}>
                                    {subCheck?.SubInspectionCheck?.status ==
                                      true ||
                                    subCheck?.SubInspectionCheck?.status ==
                                      1 ? (
                                      <View style={styles1.tickpassContainer}>
                                        <Image
                                          style={{
                                            width: "8px",
                                            marginLeft: "0px",
                                          }}
                                          source={Vector}
                                        />
                                      </View>
                                    ) : (
                                      <View
                                        style={styles1.tickpassContainer}
                                      ></View>
                                    )}
                                    {subCheck?.SubInspectionCheck?.status ==
                                      false ||
                                    subCheck?.SubInspectionCheck?.status ==
                                      0 ? (
                                      <View style={styles1.tickfailContainer}>
                                        <Image
                                          style={{
                                            width: "8px",
                                            marginLeft: "0px",
                                          }}
                                          source={Vector}
                                        />
                                      </View>
                                    ) : (
                                      <View
                                        style={styles1.tickfailContainer}
                                      ></View>
                                    )}
                                  </View>
                                )}
                              </View>
                            </>
                          ))}
                      </>
                    ) : null
                  )}
                </View>

                <View
                  style={{
                    flexDirection: "column",
                    width: "50%",
                    paddingLeft: "20px",
                    marginBottom: "60px",
                  }}
                >
                  <View style={styles1.inspectionfirstContainer}>
                    <Text style={styles1.inspectionText}>
                      INSPECTION POINTS
                    </Text>
                    <View style={styles1.passfailContainer}>
                      <Text style={styles1.passText}>PASS</Text>
                      <Text style={styles1.failText}>FAIL</Text>
                    </View>
                  </View>

                  {checksData?.map((check, index) =>
                    index > 7 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        {index === 16 ? (
                          <View
                            style={{
                              width: "100%",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <View style={{ flexDirection: "row" }}>
                                <Text style={styles1.numbersText}>
                                  {index + 1}
                                </Text>
                                <Text style={styles1.detailsText}>
                                  {check?.Check?.name}
                                </Text>
                              </View>
                              <View style={styles1.passfailContainer1}>
                                {check?.status == true || check?.status == 1 ? (
                                  <View style={styles1.tickpassContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View
                                    style={styles1.tickpassContainer}
                                  ></View>
                                )}
                                {check?.status == false ||
                                check?.status == 0 ? (
                                  <View style={styles1.tickfailContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View
                                    style={styles1.tickfailContainer}
                                  ></View>
                                )}
                              </View>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <View style={{ marginTop: "10px" }}>
                                <Text style={styles1.detailsText2}>
                                  Right front [ 32nd’s / In ]
                                </Text>
                              </View>

                              <View
                                style={{ width: "21%", marginRight: "15px" }}
                              >
                                {reportData &&
                                  reportData?.Files.map((files, index) => {
                                    return (
                                      <>
                                        {files?.category ===
                                          "right_front_tire" ||
                                        files?.category === "tire" ? (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Text
                                              key={index}
                                              style={styles1.tireDimentions}
                                            >
                                              {files?.dimension === null ? (
                                                "*"
                                              ) : (
                                                <>
                                                  {files?.dimension}{" "}
                                                  <span>"</span>
                                                  {/* <Image
                                                  source={Comma}
                                                  style={{
                                                    height: 4,
                                                    width: 4,
                                                    backgroundColor: "yellow",
                                                    marginTop: 31,
                                                  }}
                                                /> */}
                                                </>
                                              )}
                                            </Text>
                                          </View>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                <View style={styles1.dimentionBorderWidth} />
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <View style={{ marginTop: "10px" }}>
                                <Text style={styles1.detailsText2}>
                                  Left front [ 32nd’s / In ]
                                </Text>
                              </View>
                              <View
                                style={{ width: "21%", marginRight: "15px" }}
                              >
                                {reportData &&
                                  reportData?.Files.map((files, index) => {
                                    return (
                                      <>
                                        {files?.category ===
                                          "left_front_tire" ||
                                        files?.category === "tire" ? (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Text
                                              key={index}
                                              style={styles1.tireDimentions}
                                            >
                                              {files?.dimension === null ? (
                                                "*"
                                              ) : (
                                                <>
                                                  {files?.dimension}{" "}
                                                  <span>"</span>
                                                  {/* <Image
                                                  source={Comma}
                                                  style={{
                                                    height: 4,
                                                    width: 4,
                                                    marginTop: 11,
                                                  }}
                                                /> */}
                                                </>
                                              )}
                                            </Text>
                                          </View>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                <View style={styles1.dimentionBorderWidth} />
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <View style={{ marginTop: "10px" }}>
                                <Text style={styles1.detailsText2}>
                                  Right rear [ 32nd’s / In ]
                                </Text>
                              </View>
                              <View
                                style={{ width: "21%", marginRight: "15px" }}
                              >
                                {reportData &&
                                  reportData?.Files.map((files, index) => {
                                    return (
                                      <>
                                        {files?.category ===
                                          "right_rear_tire" ||
                                        files?.category === "tire" ? (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Text
                                              key={index}
                                              style={styles1.tireDimentions}
                                            >
                                              {files?.dimension === null ? (
                                                "*"
                                              ) : (
                                                <>
                                                  {files?.dimension}{" "}
                                                  <span>"</span>
                                                  {/* <Image
                                                  source={Comma}
                                                  style={{
                                                    height: 4,
                                                    width: 4,
                                                    marginTop: 11,
                                                  }}
                                                /> */}
                                                </>
                                              )}
                                            </Text>
                                          </View>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                <View style={styles1.dimentionBorderWidth} />
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <View style={{ marginTop: "10px" }}>
                                <Text style={styles1.detailsText2}>
                                  Left rear [ 32nd’s / In ]
                                </Text>
                              </View>
                              <View
                                style={{ width: "21%", marginRight: "15px" }}
                              >
                                {reportData &&
                                  reportData?.Files.map((files, index) => {
                                    return (
                                      <>
                                        {files?.category === "left_rear_tire" ||
                                        files?.category === "tire" ? (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Text
                                              key={index}
                                              style={styles1.tireDimentions}
                                            >
                                              {files?.dimension === null ? (
                                                "*"
                                              ) : (
                                                <>
                                                  {files?.dimension}{" "}
                                                  <span>"</span>
                                                  {/* <Image
                                                  source={Comma}
                                                  style={{
                                                    height: 4,
                                                    width: 4,
                                                    marginTop: 11,
                                                  }}
                                                /> */}
                                                </>
                                              )}
                                            </Text>
                                          </View>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                <View style={styles1.dimentionBorderWidth} />
                              </View>
                            </View>
                          </View>
                        ) : (
                          <>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={
                                  index === 20
                                    ? styles1.numbersText2
                                    : styles1.numbersText
                                }
                              >
                                {index + 1}
                              </Text>
                              <Text style={styles1.detailsText}>
                                {check?.Check?.name}
                              </Text>
                            </View>
                            <View style={styles1.passfailContainer}>
                              {check?.status == true || check?.status == 1 ? (
                                <View style={styles1.tickpassContainer}>
                                  <Image
                                    style={{ width: "8px", marginLeft: "0px" }}
                                    source={Vector}
                                  />
                                </View>
                              ) : (
                                <View style={styles1.tickpassContainer}></View>
                              )}
                              {check?.status == false || check?.status == 0 ? (
                                <View style={styles1.tickfailContainer}>
                                  <Image
                                    style={{ width: "8px", marginLeft: "0px" }}
                                    source={Vector}
                                  />
                                </View>
                              ) : (
                                <View style={styles1.tickfailContainer}></View>
                              )}
                            </View>
                          </>
                        )}
                      </View>
                    ) : null
                  )}

                  <View
                    style={[
                      styles.inspectionsecondContainer,
                      { marginTop: "20px" },
                    ]}
                  >
                    <View style={styles.failpassbox}>
                      <View style={styles.boxinspectionContainer}>
                        <Text style={styles.boxinspectionText}>
                          VEHICLE INSPECTION
                        </Text>
                        <Text style={styles.circleoneText}>
                          (Please Circle One)
                        </Text>
                      </View>
                      {reportData && reportData.finalStatus === "pass" ? (
                        <View style={styles.orangeCircle}>
                          <Text style={styles.boxpassText}>PASS</Text>
                        </View>
                      ) : (
                        <Text style={styles.boxpassText}>PASS</Text>
                      )}
                      {reportData && reportData.finalStatus === "fail" ? (
                        <View style={styles.orangeCircle}>
                          <Text style={styles.boxpassText}>FAIL</Text>
                        </View>
                      ) : (
                        <Text style={styles.boxpassText}>FAIL</Text>
                      )}
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.tobecompletedWidth}>
                <View style={styles.tobecompletedContainer}>
                  <View
                    style={{
                      width: "50%",
                      marginLeft: "20px",
                      marginTop: "0px",
                      marginRight: "20px",
                    }}
                  >
                    <Text>TO BE COMPLETED BY INSPECTOR</Text>
                  </View>
                </View>
                <View style={styles.tobecompletedContainer}>
                  <View style={{ width: "40%", marginLeft: "20px" }}>
                    <Text style={styles.threeinputFields}>{`${
                      reportData && reportData.Inspectorname
                    } ${reportData && reportData.InspectorlastName}`}</Text>
                    <Text style={styles.lyftdriverName}>INSPECTOR NAME</Text>
                  </View>
                  <View
                    style={{
                      width: "40%",
                      marginRight: "20px",
                      marginTop: "15px",
                      marginLeft: "20px",
                    }}
                  >
                    <Image
                      style={{
                        position: "absolute",
                        height: "100px",
                        width: "100px",
                        top: "-70px",
                        right: "100px",
                      }}
                      src={`${process.env.REACT_APP_AWS_S3_LINK}/${
                        reportData && reportData?.Inspectorsignature
                      }?x-request=xhr`}
                    />
                    <Text style={styles.lyftdriver}>INSPECTOR SIGNATURE</Text>
                  </View>

                  <View style={{ width: "20%", marginRight: "20px" }}>
                    <Text style={styles.threeinputFields}>
                      {moment(
                        new Date(reportData && reportData?.reviewedDate)
                      ).format("MM/ DD /YYYY")}
                    </Text>
                    <Text style={styles.lyftdriverName}>DATE</Text>
                  </View>
                </View>
                <View style={styles.tobecompletedContainer}>
                  <View
                    style={{
                      width: "50%",
                      marginRight: "20px",
                      marginLeft: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.threeinputFields}>Chex.AI</Text>
                    <Text style={styles.lyftdriverName}>COMPANY NAME</Text>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      marginRight: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.threeinputFields}>
                      {reportData && reportData?.milage}
                    </Text>
                    <Text style={styles.lyftdriverName}>VEHICLE MILAGE</Text>
                  </View>
                </View>
                <View style={styles.tobecompletedContainer}>
                  <View
                    style={{
                      width: "100%",
                      marginRight: "20px",
                      marginLeft: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.threeinputFields}>
                      {reportData && reportData?.stateAddress}
                    </Text>
                    <Text style={styles.lyftdriverName}>COMPANY ADDRESS</Text>
                  </View>
                </View>
              </View>
              {/*<Text style={styles.bottomPara}>*/}
              {/*  Applicants who do not match the photo on their driver’s license*/}
              {/*  or do not have the proper documents, cannot complete an*/}
              {/*  inspection. Failing inspections can only be remedied by the same*/}
              {/*  shop on the same day. If an applicant fails the inspection and*/}
              {/*  is unable to fix the issues on the spot, a new appointment must*/}
              {/*  be made, and a new inspection form must be used.*/}
              {/*</Text>*/}
              {/*<Text style={styles.bottomPara}>*/}
              {/*  This inspection form reflects a 19 point inspection only on the*/}
              {/*  date of the inspection listed above. For purposes of this*/}
              {/*  inspection, the wheels were not removed from the vehicle, and*/}
              {/*  the vehicle was not put on a lift, nor was it checked for*/}
              {/*  emissions.*/}
              {/*</Text>*/}
            </Page>
          </Document>
        }
        fileName="Vehicle-certificate.pdf"
      >
        {({ blob, url, loading, error }) => (
          <>
            {isLoading ? (
              <ClipLoader color={"#246DB5"} size={40} />
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    handleReload();
                  }}
                  style={{
                    fontSize: "15px",
                    marginBottom: "20px",
                    marginTop: "20px",
                    color: "white",
                    fontWeight: "bold",
                    margin: "auto",
                    background: "#3276ba",
                    padding: "13px",
                    borderRadius: "50px",
                  }}
                >
                  Download Vehicle Report
                </div>
                <i className="fa fa-download" />
              </div>
            )}
          </>
        )}
      </PDFDownloadLink>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    reportData: state.vehicleInstruction.lyftVehicleCertificate,
    checksData: setLyftReportData(
      state.vehicleInstruction.lyftVehicleCertificate?.Files
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LyftCertificateColorado);
