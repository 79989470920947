/* eslint-disable */
import React from "react";
import { ClipLoader } from "react-spinners";
import { Form, Input, Select, Button, DatePicker } from "antd";
import { TiTick } from "react-icons/ti";
import InputMask from "react-input-mask";

import { Header, LogoutModal } from "../../Components";
import {
  MainContainer,
  MainDownContainer,
  ImageBackgroundVehicleInspection,
  ContentFooterareaInspectionInstruction,
  MainBgInsertDetails,
} from "./style.js";
import "./style.css";

const ConfirmVehicleDetailsScreen = ({
  // companies,
  stateEmpty,
  checkEmpty,

  numberEmpty,
  setStateEmpty,
  loadingcompny,
  submitLoading,
  stateCompanies,
  setSubmitLoading,
  inspectionChecked,
  alreadyExistModal,
  inspectionErrorResp,
  setAlreadyExistModal,
  handleInspectionCheck,
  handleConfirmSubmit,
  handleSubmitAlreadyExist,
  reInspectionPlateNumber,
  reInspectionState,
  inspectionState,
  licensePlateNumber,
  loadingSelect,
  setNumberEmpty,
  setCheckEmpty,
  reInspection,
  getInspectionDetailData,
  detailLoading,
  selectedCompanies,

  vinNumber,
  registrationState,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  hasSticker,
  date,

  handleVinNumber,
  handleRegistrationState,
  handleVehicleMake,
  handleVehicleModel,
  handleVehicleYear,
  handleHasSticker,
  handleDate,

  vinNumberError,
  registrationStateError,
  vehicleMakeError,
  vehicleModelError,
  vehicleYearError,
  hasStickerError,
  dateError,

  setVinNumberError,
  setRegistrationStateError,
  setVehicleMakeError,
  setVehicleModelError,
  setVehicleYearError,
  setHasStickerError,
  setDateError,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  let filterArray = stateCompanies;
  return (
    <MainContainer>
      <ImageBackgroundVehicleInspection>
        <MainBgInsertDetails>
          <div className="select-company-header">
            <div className="select-company-header-content">
              <Header title={true} />
            </div>
          </div>
          <MainDownContainer>
            <ContentFooterareaInspectionInstruction
              className={detailLoading ? "overlayLoader" : ""}
            >
              <div className="confirmVehicleDetailHeading">
                Confirm Vehicle Details
              </div>

              <div>
                <div>
                  <div className="input-label-heading">
                    Vehicle Identification Number (VIN)
                  </div>
                  <div className="license-plate">
                    <input
                      type="text"
                      disabled={false}
                      placeholder="CAR12345"
                      value={vinNumber}
                      className="input-fields"
                      onChange={(number) => {
                        handleVinNumber(number.target.value);
                        setVinNumberError("");
                      }}
                      pattern=".{17,17}"
                      maxLength={17}
                      minLength={17}
                      required
                    />
                    {vinNumberError ? (
                      <div className="error-message" style={{ color: "red" }}>
                        {vinNumberError}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className="dropdown-heading">
                    Select Registration State
                  </div>

                  <div className="dropdown-container">
                    <Select
                      className="dropdown"
                      labelInValue
                      placeholder="Select State"
                      onChange={(e) => {
                        handleRegistrationState(e?.value);
                        setRegistrationStateError("");
                      }}
                    >
                      
                      <Option value="Alabama">Alabama</Option>
                      <Option value="Alaska">Alaska</Option>
                      <Option value="Arizona">Arizona</Option>
                      <Option value="Arkansas">Arkansas</Option>
                      <Option value="California">California</Option>
                      <Option value="Colorado">Colorado</Option>
                      <Option value="Connecticut">Connecticut</Option>
                      <Option value="Delaware">Delaware</Option>
                      <Option value="Florida">Florida</Option>
                      <Option value="Georgia">Georgia</Option>
                      <Option value="Hawaii">Hawaii</Option>
                      <Option value="Idaho">Idaho</Option>
                      <Option value="Illinois">Illinois</Option>
                      <Option value="Indiana">Indiana</Option>
                      <Option value="Iowa">Iowa</Option>
                      <Option value="Kansas">Kansas</Option>
                      <Option value="Kentucky">Kentucky</Option>
                      <Option value="Louisiana">Louisiana</Option>
                      <Option value="Maine">Maine</Option>
                      <Option value="Maryland">Maryland</Option>
                      <Option value="Massachusetts">Massachusetts</Option>
                      <Option value="Michigan">Michigan</Option>
                      <Option value="Minnesota">Minnesota</Option>
                      <Option value="Mississippi">Mississippi</Option>
                      <Option value="Missouri">Missouri</Option>
                      <Option value="Montana">Montana</Option>
                      <Option value="Nebraska">Nebraska</Option>
                      <Option value="Nevada">Nevada</Option>
                      <Option value="New Hampshire">New Hampshire</Option>
                      <Option value="New Jersey">New Jersey</Option>
                      <Option value="New Mexico">New Mexico</Option>
                      <Option value="New York">New York</Option>
                      <Option value="North Dakota">North Dakota</Option>
                      <Option value="Ohio">Ohio</Option>
                      <Option value="Oklahoma">Oklahoma</Option>
                      <Option value="Oregon">Oregon</Option>
                      <Option value="Rhode Island">Rhode Island</Option>
                      <Option value="South Carolina">South Carolina</Option>
                      <Option value="South Dakota">South Dakota</Option>
                      <Option value="Tennessee">Tennessee</Option>
                      <Option value="Texas">Texas</Option>
                      <Option value="Utah">Utah</Option>
                      <Option value="Vermont">Vermont</Option>
                      <Option value="Virginia">Virginia</Option>
                      <Option value="Washington">Washington</Option>
                      <Option value="West Virginia">West Virginia</Option>
                      <Option value="Wisconsin">Wisconsin</Option>
                      <Option value="Wyoming">Wyoming</Option>
                    </Select>
                  </div>
                  {registrationStateError ? (
                    <div
                      className="error-message-dropdown"
                      style={{ color: "red" }}
                    >
                      {registrationStateError}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="input-label-heading">Vehicle Make</div>
                  <div className="license-plate">
                    <input
                      type="text"
                      disabled={false}
                      placeholder="Honda"
                      value={vehicleMake}
                      className="input-fields"
                      onChange={(e) => {
                        handleVehicleMake(e.target.value);
                        setVehicleMakeError("");
                      }}
                    />

                    {vehicleMakeError ? (
                      <div className="error-message" style={{ color: "red" }}>
                        {vehicleMakeError}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className="input-label-heading">Vehicle Model</div>
                  <div className="license-plate">
                    <input
                      type="text"
                      disabled={false}
                      placeholder="Civic"
                      value={vehicleModel}
                      className="input-fields"
                      onChange={(e) => {
                        handleVehicleModel(e.target.value);
                        setVehicleModelError("");
                      }}
                    />
                    {vehicleModelError ? (
                      <div className="error-message" style={{ color: "red" }}>
                        {vehicleModelError}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className="input-label-heading">Vehicle Year</div>
                  <div className="license-plate">
                    <InputMask
                      mask="9999"
                      maskChar=" "
                      disabled={false}
                      placeholder="2022"
                      value={vehicleYear}
                      className="input-fields"
                      onChange={(e) => {
                        handleVehicleYear(e.target.value);
                        setVehicleYearError("");
                      }}
                    />

                    {vehicleYearError ? (
                      <div className="error-message" style={{ color: "red" }}>
                        {vehicleYearError}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className="dropdown-heading">
                    Has Registration Sticker?
                  </div>

                  <div className="dropdown-container">
                    <Select
                      className="dropdown"
                      labelInValue
                      placeholder="Select Yes or No"
                      onChange={(e) => {
                        handleHasSticker(e?.value);
                        setHasStickerError("");
                      }}
                    >
                      <Option value="true">Yes</Option>
                      <Option value="false">No</Option>
                    </Select>
                  </div>

                  {hasStickerError ? (
                    <div
                      className="error-message-dropdown"
                      style={{ color: "red" }}
                    >
                      {hasStickerError}
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="input-label-heading">
                    Registration Sticker Month/Year (MM/YY)
                  </div>
                  <div className="license-plate">
                    <InputMask
                      mask="99/99"
                      maskChar=" "
                      className="input-fields"
                      placeholder="01/24"
                      value={date}
                      onChange={(e) => {
                        handleDate(e.target.value);
                        setDateError("");
                      }}
                    />
                    {dateError ? (
                      <div className="error-message" style={{ color: "red" }}>
                        {dateError}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="submit-button-container">
                  <div
                    className="inspection-btn-container-submit"
                    onClick={() => handleConfirmSubmit()}
                  >
                    {loadingSelect ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "21px",
                        }}
                      >
                        <ClipLoader color={"#246DB5"} size={20} />
                      </div>
                    ) : (
                      <div className="select-text">Confirm</div>
                    )}
                  </div>
                </div>
              </div>

              <LogoutModal
                logout={false}
                loading={submitLoading}
                modal={alreadyExistModal}
                setLoading={setSubmitLoading}
                setModal={setAlreadyExistModal}
                handleSubmit={handleSubmitAlreadyExist}
                errorMessage={inspectionErrorResp?.errorMessage}
              />
            </ContentFooterareaInspectionInstruction>
          </MainDownContainer>
        </MainBgInsertDetails>
      </ImageBackgroundVehicleInspection>
    </MainContainer>
  );
};
export default ConfirmVehicleDetailsScreen;
