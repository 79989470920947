import React from "react";
import "./style.css";

const ManageSection = () => {
  return (
    <div className="manage-section-container">
      <div className="manage-section-inner">
        <div className="manage-section-divs">
          <div className="manage-section-left-div"></div>
          <div className="manage-section-right-div">
            <div className="manage-section-right-div-head">
              You Can Manage & Inspect Your Car Online
            </div>
            <div className="manage-text-wrap-div">
              <div className="manage-section-right-div-para">
                A Chex Verified Ecosystem interconnecting all of the various
                stakeholders
              </div>
              <div className="manage-section-right-div-para">
                User-friendly app and comprehensive dashboard
              </div>
              <div className="manage-section-right-div-para">
                Powered by advanced AI, allowing for real-time <br />
                reporting on every inspected vehicle
              </div>
              <div className="manage-section-right-div-para">
                AI based reviews for pinpoint damage and safety items reporting
              </div>
            </div>
            {/* <div className="manage-section-read-more-btn">Read More</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSection;
