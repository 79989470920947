import React, { useEffect, useState } from "react";
import { ValidatePromoTokenScreen } from "../../Screens";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { validatePromoToken } from "../../actions/auth";
import { useDispatch } from "react-redux";

import { useToasts } from "react-toast-notifications";

const ValidatePromoTokenContainer = () => {
  const { addToast } = useToasts();
  const [promoLoading, setPromoLoading] = useState(false);

  const location = useLocation();
  const query = queryString.parse(location.search);
  const promoToken = query.token;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (promoToken) {
      dispatch(
        validatePromoToken(promoToken, history, setPromoLoading, addToast)
      );
    }
  }, [promoToken]);

  return <ValidatePromoTokenScreen promoLoading={promoLoading} />;
};

export default ValidatePromoTokenContainer;
