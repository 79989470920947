import React from 'react'
import './style.css'

const Fleetcard = ({deboseImage, priceValue, inspectionDetail, textDebose, imageLyft, imageDouble}) => {
    return(
        <div className='pricing_card_main_ride'>
            <div className='card_debose_wrap'>
                <div className='image_debose_wrapper'>
                    <h3 className='heading_content_price'>
                        <span>$</span>{priceValue}
                    </h3>

                </div>
            </div>

            <div className='content_section_pricing_card_wrapper'>
                <p className='para_pricing_content'>
                    {inspectionDetail}
                </p>

            </div>

        </div>
    )
}

export default Fleetcard