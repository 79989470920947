import React, { useState } from "react";

import UberVehicleInspectionScreen from "../../Screens/UberVehicleInspectionScreen";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {MicromobilityScreen, RideshareScreen} from "../../Screens";

const MicromobilityContainer = (props) => {

    return (
        <MicromobilityScreen

        />
    );
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        isLoading: state.auth.isAuthLoading,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MicromobilityContainer);
