import React from 'react';
import './style.css';

const CommunityBannerComponent = () => {
    return(
        <div className='community_wrapper_main_outer'>
            <div className='community_wrapper_main_inner'>
                <h2 className='community_wrapper_heading'>
                    Our <span> Community </span> of Chex.AI is Growing Fast
                </h2>

                <div className='community_wrapper_counter'>
                    <div className='counter_box'>
                        <h2>
                            2+
                        </h2>
                        <h3>
                            YEARS OF EXPERIENCE
                        </h3>

                    </div>

                    <div className='counter_box center_counter_box_line'>
                        <h2>
                           1000s
                        </h2>
                        <h3>
                           SATISFIED CUSTOMER
                        </h3>

                    </div>

                    <div className='counter_box'>
                        <h2>
                           1000s
                        </h2>
                        <h3>
                           VEHICLE INSPECTION <br /> COMPLETED
                        </h3>

                    </div>


                </div>

            </div>

        </div>
    )
}
export default CommunityBannerComponent