/* eslint-disable */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import ActionCreators from "../../actions";

import { ConfirmVehicleDetailsScreen } from "../../Screens";

import { useHistory } from "react-router-dom";

const ConfirmVehicleDetailsContainer = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  };

  const {
    confirmVehicleDetail,
    createInspection,
    stateCompanies,
    companies,
    match,
  } = props;
  const [inspectionChecked, setInspectionChecked] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingcompny, setLoadingcompny] = useState(false);
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [inspectionState, setInspectionState] = useState("");
  const [stateEmpty, setStateEmpty] = useState(false);
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [alreadyExistModal, setAlreadyExistModal] = useState(false);
  const [inspectionErrorResp, setInspectionErrorResp] = useState("");
  const [getInspectionDetailData, setGetInspectionDetailData] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);

  const paramsSaved = localStorage.getItem("params");
  const parsedParams = JSON.parse(paramsSaved);

  const [vinNumber, setVinNumber] = useState(parsedParams?.vinNumber);
  const [vinNumberError, setVinNumberError] = useState("");

  const [registrationState, setRegistrationState] = useState("");
  const [registrationStateError, setRegistrationStateError] = useState("");

  const [vehicleMake, setVehicleMake] = useState(parsedParams?.vehicleMake);
  const [vehicleMakeError, setVehicleMakeError] = useState("");

  const [vehicleModel, setVehicleModel] = useState(parsedParams?.vehicleModel);
  const [vehicleModelError, setVehicleModelError] = useState("");

  const [vehicleYear, setVehicleYear] = useState(parsedParams?.vehicleYear);
  const [vehicleYearError, setVehicleYearError] = useState("");

  const [hasSticker, setHasSticker] = useState("");
  const [hasStickerError, setHasStickerError] = useState("");

  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState("");

  const [vehicleObject, setVehicleObject] = useState({
    vinNumber,
    vehicleMake,
    vehicleModel,
    vehicleYear,
  });

  const handleVinNumber = (value) => {
    setVinNumber(value);
    setVehicleObject((prevObject) => ({
      ...prevObject,
      vinNumber: value,
    }));
  };

  const handleRegistrationState = (value) => {
    setRegistrationState(value);
  };

  const handleVehicleMake = (value) => {
    setVehicleMake(value);
    setVehicleObject((prevObject) => ({
      ...prevObject,
      vehicleMake: value,
    }));
  };

  const handleVehicleModel = (value) => {
    setVehicleModel(value);
    setVehicleObject((prevObject) => ({
      ...prevObject,
      vehicleModel: value,
    }));
  };

  const handleVehicleYear = (value) => {
    setVehicleYear(value);
    setVehicleObject((prevObject) => ({
      ...prevObject,
      vehicleYear: value,
    }));
  };

  const handleHasSticker = (value) => {
    setHasSticker(value);
  };

  const handleDate = (value) => {
    setDate(value);
  };

  const handlEmptyFields = () => {
    inspectionChecked.length === 0 ? setCheckEmpty(true) : setCheckEmpty(false);
  };

  const handleConfirmSubmit = () => {
    const vinNumberError = validateVinNumber(vinNumber, "VIN is required");
    const registrationStateError = validateInput(
      registrationState,
      "Registration State is required"
    );
    const vehicleMakeError = validateInput(
      vehicleMake,
      "Vehicle Make is required"
    );
    const vehicleModelError = validateInput(
      vehicleModel,
      "Vehicle Model is required"
    );
    const vehicleYearError = validateInput(
      vehicleYear,
      "Vehicle Year is required"
    );
    const hasStickerError = validateInput(
      hasSticker,
      "Please select Yes or No for Registration Sticker"
    );
    const dateError = validateDateInput(
      date,
      "Registration Sticker Month/Year is required"
    );

    if (
      vinNumberError ||
      registrationStateError ||
      vehicleMakeError ||
      vehicleModelError ||
      vehicleYearError ||
      hasStickerError ||
      dateError
    ) {
      setVinNumberError(vinNumberError);
      setRegistrationStateError(registrationStateError);
      setVehicleMakeError(vehicleMakeError);
      setVehicleModelError(vehicleModelError);
      setVehicleYearError(vehicleYearError);
      setHasStickerError(hasStickerError);
      setDateError(dateError);

      return;
    } else {
      const body = {
        vin: vinNumber,
        registrationState: registrationState,
        make: vehicleMake,
        model: vehicleModel,
        year: vehicleYear,
        registrationSticker: hasSticker,
        RegistrationDate: date,
      };

      const newParams = JSON.stringify(vehicleObject);
      localStorage.setItem("params", newParams);

      const inspectionId = match?.params?.inspectionId;
      const vehicleId = match?.params?.vehicleId;

      confirmVehicleDetail(
        body,
        vehicleId,
        inspectionId,
        setLoadingSelect,
        addToast
      );
      // history.push(`/checkoutScreen/${inspectionId}/${vehicleId}`);
    }
  };

  const validateInput = (value, errorMessage) => {
    if (!value) {
      return errorMessage;
    }
  };

  const validateVinNumber = (value, errorMessage) => {
    if (!value) {
      return errorMessage;
    }
    

    if (value.length !== 17) {
      return "VIN must be 17 characters long";
    }
    return ""; // No error
  };
  const validateDateInput = (value, errorMessage) => {
    if (!value) {
      return errorMessage;
    }
    const formattedDate = moment(value, "MM/YY", true);

    if (!formattedDate.isValid()) {
      return "Invalid date format";
    }
    return ""; // No error
  };

  return (
    <ConfirmVehicleDetailsScreen
      companies={companies}
      stateCompanies={stateCompanies}
      checkEmpty={checkEmpty}
      stateEmpty={stateEmpty}
      numberEmpty={numberEmpty}
      setStateEmpty={setStateEmpty}
      setCheckEmpty={setCheckEmpty}
      submitLoading={submitLoading}
      loadingSelect={loadingSelect}
      loadingcompny={loadingcompny}
      setNumberEmpty={setNumberEmpty}
      inspectionState={inspectionState}
      setSubmitLoading={setSubmitLoading}
      handlEmptyFields={handlEmptyFields}
      inspectionChecked={inspectionChecked}
      alreadyExistModal={alreadyExistModal}
      licensePlateNumber={licensePlateNumber}
      reInspection={match?.params?.inspection}
      inspectionErrorResp={inspectionErrorResp}
      handleConfirmSubmit={handleConfirmSubmit}
      reInspectionState={props?.location?.state?.state}
      // InspectionEditState={inspectionDataEdit}
      getInspectionDetailData={getInspectionDetailData}
      detailLoading={detailLoading}
      reInspectionPlateNumber={props?.location?.state?.plateNumber}
      selectedCompanies={props?.location?.state?.selectedCompanies}
      vinNumber={vinNumber}
      registrationState={registrationState}
      vehicleMake={vehicleMake}
      vehicleModel={vehicleModel}
      vehicleYear={vehicleYear}
      hasSticker={hasSticker}
      date={date}
      handleVinNumber={handleVinNumber}
      handleRegistrationState={handleRegistrationState}
      handleVehicleMake={handleVehicleMake}
      handleVehicleModel={handleVehicleModel}
      handleVehicleYear={handleVehicleYear}
      handleHasSticker={handleHasSticker}
      handleDate={handleDate}
      vinNumberError={vinNumberError}
      registrationStateError={registrationStateError}
      vehicleMakeError={vehicleMakeError}
      vehicleModelError={vehicleModelError}
      vehicleYearError={vehicleYearError}
      hasStickerError={hasStickerError}
      dateError={dateError}
      setVinNumberError={setVinNumberError}
      setRegistrationStateError={setRegistrationStateError}
      setVehicleMakeError={setVehicleMakeError}
      setVehicleModelError={setVehicleModelError}
      setVehicleYearError={setVehicleYearError}
      setHasStickerError={setVehicleYearError}
      setDateError={setDateError}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehiclesByStatus: state.vehicleInstruction.inspectionByStatus,
    companies: state?.auth?.companies?.data,
    stateCompanies: state?.auth?.stateCompanies?.data,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmVehicleDetailsContainer);
