import React, { useState,useEffect,useRef } from "react";

import UberVehicleInspectionScreen from "../../Screens/UberVehicleInspectionScreen";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {BlogScreen} from "../../Screens";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogDetails } from "../../actions/blog";


const BlogContainer = (props) => {
    const firstCardRef = useRef(null);
    const dispatch = useDispatch();
const [loading, setLoading] = useState(false);
const [page, setPage] = useState(1);
const [pageSize, setPageSize] = useState(20);
const [content, setContent] = useState('');
    const history = useHistory();
    const { addToast } = useToasts();
    
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = (id , title) => {
    const value =title.toLowerCase().replace(/ /g, '-');
    window.location.href=`/blog-detail/${id}/${value}`

  };


    useEffect(() => {
        dispatch(getAllBlogDetails(setLoading,page,pageSize));
      }, [dispatch,page,pageSize]);
   const handlechangesize=(page)=>
   {
    setPage(page)
   }
   const handleScroll=()=>
   {
    
    if (firstCardRef.current) {
        firstCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
   }
    return (
        <BlogScreen
            blogdata={props?.blogdata}
            showFullContent={showFullContent}
            page={page}
            setPage={setPage}
            firstCardRef={firstCardRef}
            setPageSize={setPageSize}
            pageSize={pageSize}
            toggleContent={toggleContent}
            handleScroll={handleScroll }
            loading={loading}
            handlechangesize={handlechangesize}
            // content={content}
            history={history}
            pagination={props?.blogdata?.data?.meta && props?.blogdata?.data?.meta}
        />
    );
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        blogdata: state.blog.blogdata,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlogContainer);
