/* eslint-disable */

import SelectOptionScreen from '../../Screens/SelectOptionScreen';

const SelectOptionContainer = () => {
    return (
        <SelectOptionScreen />
    );
} 

export default SelectOptionContainer;