import React from "react";
import "./style.css";
import { Col, Row } from "antd";

const AboutChexComponent = () => {
  return (
    <div>
      <section className="new_about_section_main">
        <Row>
          <Col xl={24} md={24} sm={24}>
            <div className="about_Content_section_wrapper">
              <h2 className="about-chex-left-title_main_new">
                <span className="titleAbout_new">ABOUT CHEX.AI</span>
              </h2>
              <p className="paragraphAbout_new">
                Chex.AI revolutionizes vehicle safety inspection with its
                cutting-edge platform, employing advanced damage detection
                technology. Businesses benefit from real-time insights, spotting
                any new vehicle damage instantly. With a user-friendly app and
                comprehensive dashboard, Chex.AI streamlines inspection reviews
                and provides detailed insights, enhancing operational efficiency
                and safety standards.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={24} md={24} sm={24}>
            <div className="Chex_video_wrapper">
              <video
                poster="https://res.cloudinary.com/dgjordf6e/image/upload/v1666085419/banner_image_poster_a2pplr.png"
                type="video/mp4"
                playsInline
                controls={true}
                className="chex-demo-video_custom"
              >
                <source
                  src="https://res.cloudinary.com/dgjordf6e/video/upload/v1695888050/CHex_ai_je0una.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            {/* Store Icons */}
            {/* <div className="app-icons">
              <div className="about-icons-img">
                <a
                  href="https://play.google.com/store/apps/details?id=com.chex_ai&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726473711/pngegg_7_1_ymafu9.png"
                    alt="Google Play Store"
                    className="store-icon"
                  />
                </a>
              </div>
              <div className="about-icons-img">
                <a
                  href="https://apps.apple.com/pk/app/chex-inspection/id6473516316"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726473711/pngegg_7_2_fhhplb.png"
                    alt="Apple App Store"
                    className="store-icon"
                  />
                </a>
              </div>
            </div> */}
          </Col>
        </Row>
      </section>
    </div>
  );
};
export default AboutChexComponent;
