import "./style.css";

export const BenifitsSection = () => {
  return (
    <div>
      <section className="centered-section-benifits">
        <span className="benefits-text">BENEFITS WE PROPOSE</span>
        <div className="cards-container">
          <div className="card">
            <div className="circle">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240534/Group_23776_uttwsh.png"
                alt="Fast and convenient"
              />
            </div>
            <h2 className="card-title">Fast and convenient</h2>
            <p className="card-paragraph">
              Chex.AI's Inspection solution modernizes operations and is easy to
              use
            </p>
          </div>
          <div className="card">
            <div className="circle">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240534/Group_23779_b8dzd9.png"
                alt="Cost-effective"
              />
            </div>
            <h2 className="card-title">Cost-effective</h2>
            <p className="card-paragraph">
              We provide the best service at a lower cost when compared to
              competition
            </p>
          </div>
          <div className="card">
            <div className="circle">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240535/Rating_q5krqq.png"
                alt="Improved Customer Experience"
              />
            </div>
            <h2 className="card-title">Improved Customer Experience</h2>
            <p className="card-paragraph">
              With Easy To Follow Guidelines Provided In App, customers can
              complete inspection in minutes
            </p>
          </div>
        </div>
        <div className="cards-container">
          <div className="card">
            <div className="circle">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240537/Group_23778_ufg0eo.png"
                alt="Increased accuracy"
              />
            </div>
            <h2 className="card-title">Increased accuracy</h2>
            <p className="card-paragraph">
              High accuracy levels and advanced machine vision technology to
              accurately pinpoint the damage
            </p>
          </div>
          <div className="card">
            <div className="circle">
              <img
                src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240537/Risk_yvvrg1.png"
                alt="Better risk management"
              />
            </div>
            <h2 className="card-title">Better risk management</h2>
            <p className="card-paragraph">
              Visibility allows for strategic loss mitigation, allowing partners
              to higher profitability
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
