export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ISAUTHENTICATED = "SET_ISAUTHENTICATED";
export const IS_AUTH_LOADING = "IS_AUTH_LOADING";
export const SET_VEHCILE_DATA = "SET_VEHCILE_DATA";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_VEHICLE_INSTRUCTION = "SET_VEHICLE_INSTRUCTION";
export const SET_INSPECTION_BY_STATUS = "SET_INSPECTION_BY_STATUS";
export const SET_INSPECTION_DATA = "SET_INSPECTION_DATA";
export const SET_CAR_VERIFICATION_ITEMS = "SET_CAR_VERIFICATION_ITEMS";
export const SET_INTERIOR_ITEMS = "SET_INTERIOR_ITEMS";
export const SET_EXTERIOR_ITEMS = "SET_EXTERIOR_ITEMS";
export const SET_TIRES = "SET_TIRES";
export const SET_TIRE = "SET_TIRE";
export const IS_Vehicle_LOADING = "IS_Vehicle_LOADING";
export const SET_DELETE_LOCAL_FILE = "SET_DELETE_LOCAL_FILE";
export const SET_DELETE_LOCAL_FILE_OF_ADD_COMPANY =
  "SET_DELETE_LOCAL_FILE_OF_ADD_COMPANY";
export const SET_VEHICLE_STATUS = "SET_VEHICLE_STATUS";
export const SET_CERTIFICATE_DATA = "SET_CERTIFICATE_DATA";
export const SET_UBER_CERTIFICATE_DATA = "SET_UBER_CERTIFICATE_DATA";
export const SET_GetAround_CERTIFICATE_DATA = "SET_GetAround_CERTIFICATE_DATA";
export const SET_LYFT_CERTIFICATE_DATA = "SET_LYFT_CERTIFICATE_DATA";
export const SET_VEHICLE_DETAILS = "SET_VEHICLE_DETAILS";
export const SET_FILE_DETAILS = "SET_FILE_DETAILS";
export const SET_COMPANIES_BY_STATE = "SET_COMPANIES_BY_STATE";
export const SET_TURO_CERTIFICATE_DATA = "SET_TURO_CERTIFICATE_DATA";
export const GET_BlogData = "GET_BlogData";
export const GET_IndividualRecord = "GET_IndividualRecord";
export const IS_Mysterious_COMPANY = "IS_Mysterious_COMPANY";
