import React from 'react';
import {
    MainContainer,
    MainDownContainer,
    ImageBackgroundVehicleInspection,
    VehicleInspectionP,
    ContentVehicleInspection,
    ContentFooterareaVehicleinspection,
    MainBgInsertDetails,
    MobileViewWarningContainer,
    MobileViewWarning,
} from './style.js';
import './style.css'
import {InspectionCard} from "../../Components";
import {IoClose} from "react-icons/io5";
import {AiFillCamera} from "react-icons/ai";
import {FaPlay} from "react-icons/fa";
import {ClipLoader} from "react-spinners";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {Modal} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {InSideBody, ModalBody, ModalHeading, MtB5} from "../../Components/ConfirmationModal/style";


const ScooterUploadScreen = ({handleImageUpload, uploadingPercentage, imageLink, handleDeleteModal, isVisible, loading,loadingChk, handleDeleteYes, handleSubmit, chkKeyResponse}) => {

    return(
        <div >
            <MainContainer>
                <ImageBackgroundVehicleInspection>
                    <MainBgInsertDetails>
                        <ContentVehicleInspection>

                            <MainDownContainer>
                                <p className="veh-inspection-chexai_text-logo">
                                    CHEX.<span style={{ color: '#FF7A00' }}>AI</span>
                                </p>
                                <VehicleInspectionP>

                                    Please complete inspection items within each category below
                                </VehicleInspectionP>
                            </MainDownContainer>
                        </ContentVehicleInspection>

                        <MainDownContainer>
                            <ContentFooterareaVehicleinspection>
                                {
                                    loadingChk ?
                                        <div className='loader_wrapper_center'>
                                            <ClipLoader color={'orange'} />
                                        </div>

                                        : chkKeyResponse && chkKeyResponse === "unexpired" ?
                                            <div className="veh-inspection-mobilepaddind">
                                                <MobileViewWarningContainer>
                                                    <MobileViewWarning>
                                                        <i>
                                                            Please use <b>mobile phone </b>for optimal perfomance
                                                        </i>

                                                    </MobileViewWarning>
                                                </MobileViewWarningContainer>
                                                <div className='heading_scooter_image'>
                                                    <h4>
                                                        Reupload Scooter Image
                                                    </h4>
                                                </div>

                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>


                                                    <div className="veh-inspection-first_card">

                                                        <div style={{cursor: 'pointer'}}>

                                                            {imageLink && imageLink != '' ?

                                                                <div style={{position: 'relative'}}>
                                                                    <img
                                                                        // onClick={() => handleModal(item, groupType)}
                                                                        alt=""
                                                                        className="inspection-image-card"
                                                                        src={`${process.env.REACT_APP_AWS_S3_LINK}/${imageLink}`}
                                                                    />
                                                                    {/* <div

  > */}
                                                                    <div className="inspection-card-close_icon"
                                                                         onClick={() => handleDeleteModal()}
                                                                    >
                                                                        <IoClose color="#fff" size={14}/>
                                                                    </div>
                                                                </div>
                                                                :

                                                                <div style={{background: 'none',}}>

                                                                    <div
                                                                        // onClick={() => handleModal(item, groupType)}
                                                                        className="vec-inspection-inspection_card"
                                                                    >
                                                                        <div className="vec-inspection-camera_icon"></div>
                                                                        {uploadingPercentage ?
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                width: 111,
                                                                                height: 111
                                                                            }}>
                                                                                <div style={{width: 71, height: 70}}>
                                                                                    <CircularProgressbar
                                                                                        value={parseInt(uploadingPercentage)}
                                                                                        text={`${parseInt(uploadingPercentage)}%`}
                                                                                        styles={buildStyles({
                                                                                            rotation: 0.75,
                                                                                            // strokeLinecap: 'round',
                                                                                            textSize: '16px',
                                                                                            fontWeight: 'bolder',
                                                                                            padding: 20,
                                                                                            pathTransitionDuration: 0,
                                                                                            pathColor: `rgba(255, 122, 0, 1)`,
                                                                                            textColor: 'orange',
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            :


                                                                            <div className="veh-inspection-downloadicon_center">
                                                                                <label htmlFor="file-input-photo">
                                                                                    <div
                                                                                        className="veh-inspection-download_icon">

                                                                                        <AiFillCamera size={22} color="#97A9C5"
                                                                                                      style={{cursor: 'pointer'}}/>

                                                                                    </div>
                                                                                </label>

                                                                                <div className="input-img-container">
                                                                                    <div className="image-upload">
                                                                                        <label htmlFor="file-input-photo">
                                                                                            <div style={{
                                                                                                position: 'relative',
                                                                                                color: '#1468BA',
                                                                                                cursor: 'pointer',
                                                                                                fontSize: '10px',
                                                                                                marginTop: '4px',
                                                                                                fontWeight: 600
                                                                                            }}>
                                                                                                Upload Image
                                                                                            </div>

                                                                                        </label>

                                                                                        <input
                                                                                            // disabled={isLoading ? true : false}
                                                                                            type="file"
                                                                                            id="file-input-photo"
                                                                                            accept="image/*"
                                                                                            capture
                                                                                            onChange={handleImageUpload}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>


                                                                </div>
                                                            }

                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <p className="veh-inspection-registrationcard_text">
                                                                    Scooter Photo Upload
                                                                </p>

                                                            </div>
                                                            <p className="veh-inspection-photo_text">Photo</p>

                                                        </div>


                                                    </div>

                                                </div>
                                                {imageLink === "" || imageLink === null ?

                                                    <div className="main_button_scooter_wrapper">
                                                        <button
                                                            style={{background: 'gray'}}
                                                            disabled={true}
                                                            className="inspection-button-style-scooter"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                    :

                                                    <div className="main_button_scooter_wrapper">
                                                        <button
                                                            onClick={handleSubmit}
                                                            disabled={false}
                                                            className="inspection-button-style-scooter"
                                                        >
                                                            {loading ? <ClipLoader/> :  "Submit"}
                                                        </button>
                                                    </div>

                                                }

                                            </div>
                                            :
                                            <div style={{height: 150,paddingTop: 40, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <h3 style={{fontSize: 18, textAlign:'center',}}>
                                                    Sorry! Your Picture Upload <br/> Key is Expired.
                                                </h3>
                                            </div>
                                }

                            </ContentFooterareaVehicleinspection>

                        </MainDownContainer>
                    </MainBgInsertDetails>
                </ImageBackgroundVehicleInspection>
            </MainContainer>
            <div>
                <Modal className="sure-modal" visible={isVisible} onCancel={() => handleDeleteModal(false)} closeIcon={<CloseOutlined color="#BCCBD9" size={30} />}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                        <ModalBody>
                            <InSideBody>
                                <ModalHeading>Are you sure you want to delete this inspection item?</ModalHeading>
                                <MtB5>
                                    <div className="no-btn-container" onClick={() => handleDeleteModal()} type="submit">
                                        No
                                    </div>

                                    <div className="yes-btn-container" onClick={()=>handleDeleteYes(imageLink)} type="submit">
                                        {loading ? <ClipLoader color={'white'} size={20} /> : 'Yes'}
                                    </div>
                                </MtB5>
                            </InSideBody>
                        </ModalBody>
                    </div>
                </Modal>
            </div>


        </div>
    )
}

export default ScooterUploadScreen;



















