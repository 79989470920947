/* eslint-disable */

import React, { useEffect } from "react";
import moment from "moment";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { setLyftReportData } from "../../utils/functions";
// import { imageBaseUrl } from "../utils/api";
import TicketImg from "../../Assets/tickimg.png";
import lyftCarImage from "../../Assets/lyftCertificateForNevadaCarsPics.png";
import LyftImg from "../../Assets/liftimg.png";
import lyftCheckBox from "../../Assets/lyftCheckBox.png";
import { height } from "dom-helpers";

import kalamFont from "../../font/Kalam-Light.ttf";

Font.register({ family: "kalamFont", format: "truetype", src: kalamFont });

const styles = StyleSheet.create({
  body: {
    paddingBottom: 0,
  },
  headerWidth: {
    // paddingHorizontal: 10,
    // marginBottom: "10px",
  },
  header: {
    backgroundColor: "#47484f",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
  },
  californiaText: {
    color: "white",
    fontSize: "20px",
    textTransform: "uppercase",
  },
  boxContainer: {
    padding: "10px",
  },
  lyftdrivertextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "0px",
  },
  lyftdrivertextContainer2: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
  },
  boxWidth: {
    paddingHorizontal: 10,
    // paddingTop: 10,
  },
  lyftdriverText: {
    fontSize: "20px",
    fontStyle: "sans-serif",
    fontWeight: "bold",
    // lineHeight: "42px",
    color: "black",
    // fontFamily: "sans-serif",
    float: "left",
  },
  allfieldsText: {
    color: "#000",
    fontSize: "20px",
    fontWeight: "400",
    marginLeft: "200px",
  },
  nameinputContainer: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    // width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15px",
  },
  nameinputContain: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    // width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  lyftdriverName: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    // lineHeight: "23px",
    color: "#47484F",
    marginBottom: 0,
    // width:"100px",
    marginTop: "3px",
  },
  lyftdriverName1: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#47484F",
    marginTop: "3px",
  },
  lyftdriver: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    // lineHeight: "23px",
    color: "#47484F",
    marginBottom: 0,
    // width:"100px",
    marginTop: "3px",
    borderTop: "3px solid #A6A4A4",
    paddingTop: "5px",
  },
  nameText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    // fontFamily: 'kalamFont',
    // lineHeight: "23px",
    color: "black",
    // width: "100%",
    borderBottom: "3px solid #A6A4A4",
  },
  threeinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15px",
  },
  nameinputContainerOne: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    width: "40%",
    marginRight: "10px",
  },
  nameinputContainerTwo: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    width: "40%",
    // marginRight: "10px",
    marginLeft: "10px",
  },
  nameinputContainerTwodate: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    width: "20%",
    // marginRight: "10px",
    marginLeft: "20px",
  },
  nameinputContainerThree: {
    // borderWidth: 1,
    // borderColor: "#47484F",
    marginTop: "10px",
    width: "40%",
  },
  inspectionflex: {
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",
    marginLeft: "33px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "100",
  },
  inspectionflex1: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "100",
    paddingBottom: "10px",
  },
  bodydamage: {
    flexDirection: "row",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "900",
    marginLeft: "33px",
  },
  bodydamage1: {
    fontSize: "13px",
    fontStyle: "normal",
    color: "#716E6E",
  },
  tnc001: {
    flexDirection: "row",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    justifyContent: "flex-end",
    marginRight: "10px",
  },
  tnc002: {
    flexDirection: "row",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    justifyContent: "flex-end",
    marginRight: "30px",
    borderBottom: "1px solid black",
    // borderBottomLeftRadius: "40px",
  },
  textdecoration: {
    flexDirection: "row",
    fontSize: "5px",
    fontStyle: "normal",
    fontWeight: "100",
    color: "#716E6E",
    justifyContent: "flex-end",
    marginRight: "30px",
  },
  threeinputFields: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    // fontFamily: 'kalamFont',
    // lineHeight: "23px",
    color: "black",
    borderBottom: "3px solid #A6A4A4",
    // backgroundColor: 'red',
    marginBottom: "-3px",
  },
  phoneInputFields: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    color: "black",
    borderBottom: "3px solid #A6A4A4",
    marginBottom: "-3px",
  },
  licenseplateContainer: {
    width: "40%",
    marginRight: "10px",
  },
  codeText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    // lineHeight: "23px",
    color: "black",
    marginLeft: "50%",
  },
  vinContainer: {
    fontSize: "12px",
    fontWeight: "30",
    fontStyle: "normal",
    color: "black",
  },
  twoinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  vintopBorder: {
    borderTopWidth: 1,
    borderTopColor: "#47484F",
    width: "100%",
  },
  topText: {
    marginTop: "30px",
    fontSize: "12px",
    paddingLeft: "8px",
    paddingRight: "10px",
  },
  vehicleinspectionPadding: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-15px",
  },
  checkboxesmainContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30px",
  },
  checkboxesmainContainerTwo: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30px",
    marginTop: "10px",
  },
  inspectionfirstContainer: {
    width: "94%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionsecondContainer: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "100px",
  },
  inspectionText: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    textTransform: "uppercase",
  },
  inspectiontextTwo: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    marginLeft: "15px",
    textTransform: "uppercase",
  },
  passText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    marginRight: "15px",
    textTransform: "uppercase",
  },
  failText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
    textTransform: "uppercase",
  },
  passfailContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  numbersText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "#716E6E",
  },
  detailsText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    // lineHeight: "21px",
    color: "black",
    marginLeft: "5px",
  },
  failpassbox: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "black",
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "180px",
    width: "350px",
    top: 10,
  },

  boxinspectionText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    color: "black",
    textTransform: "uppercase",
  },
  boxinspectionText1: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "black",
  },
  circleoneText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    // lineHeight: "21px",
    color: "#716E6E",
    marginTop: "5px",
  },
  boxpassText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "black",
  },
  orangeCircle: {
    borderWidth: 5,
    borderColor: "black",
    borderRadius: "50%",
    height: "50px",
    width: "50px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5px",
    marginRight: "5px",
  },
  boxfailText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "black",
  },
  boxinspectionContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxinspectionContainer1: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tobecompletedContainer: {
    backgroundColor: "#E4E5E7",
    padding: "5px",
    flexDirection: "row",
    alignItems: "center",
  },
  tobecompletedWidth: {
    paddingHorizontal: "0px",
    marginTop: "20px",
  },
  tobecompletedtextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  tobecompletedText: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "23px",
    color: "black",
    textDecoration: "underline",
    textTransform: "uppercase",
  },
  fieldsText: {
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: "700",
    // lineHeight: "21px",
    color: "#716E6E",
    textTransform: "uppercase",
  },

  inpectionText: {
    fontSize: "16px",
    color: "#47484F",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  documentexpiryText: {
    fontSize: "12px",
    color: "#47484F",
  },
  dateText: {
    fontSize: "16px",
    letterSpacing: "1px",
  },
  datemainContainer: {
    marginTop: "10px",
    marginLeft: "52%",
  },
  namesfieldscontainer: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "18px",
    alignItems: "flex-end",
  },
  borderWidth: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  topName: {
    fontSize: "14px",
    color: "black",
    marginLeft: "12px",
  },
  bottomName: {
    fontSize: "12px",
    color: "#47484F",
    textTransform: "uppercase",
  },
  bottomPara: {
    paddingHorizontal: "30px",
    fontSize: 10,
    marginTop: "15px",
    color: "#716E6E",
  },
  tickpassContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "28px",
  },
  tickfailContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "28px",
  },
  passCheckBox: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "25px",
    marginLeft: "20px",
  },
  failCheckBox: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "28px",
    marginLeft: "21px",
  },
  inspectiondateContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "95%",
  },
  belowLineText: {
    fontSize: "12px",
  },
  boxLineContainer: {
    flexDirection: "row",
    marginBottom: "2px",
  },
  boxContainerBlack: {
    backgroundColor: "black",
    marginLeft: "8px",
    marginRight: "8px",
    height: "8px",
    width: "23px",
  },
  detailsText2: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700px",
    // lineHeight: "21@s",
    color: "#828282",
    marginLeft: "19px",
  },
  detailsText3: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700px",
    color: "#828282",
  },
});

function LyftCertificateNevada(props) {
  const checksname = [
    {
      id: 1,
      name: "Left front",
    },
    {
      id: 2,
      name: "Right front",
    },
    {
      id: 3,
      name: "Left rear",
    },
    {
      id: 4,
      name: "Right rear",
    },
  ];
  // const { checksData, reportData, isLoading, handleReload } = props;

  const { checksData, reportData, isLoading, handleModal, SetModal } = props;
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const getToken = () => localStorage.getItem("token") || null;
    if (getToken) {
      props.getVehicleCertificate(
        { id: props?.id, companyId: props.companyId },
        props.setLoading,
        "template3"
      );
    } else {
      props.history.push("/login");
    }
  }, [props?.companyId]);

  const handleReload = () => {
    SetModal(false);
    localStorage.setItem("tabStatus", "REVIEWED");
    setTimeout(function () {
      handleModal();
    }, 200);
  };
  const TNCString = reportData?.licensePlateNumber;
  const TNCNumber = TNCString?.substring(TNCString?.length - 4);

  return (
    <div className="App">
      <PDFDownloadLink
        wrap={false}
        document={
          <Document>
            <Page size="A3" style={styles.body}>
              <View style={styles.headerWidth}>
                <View style={styles.header}>
                  <Image style={{ width: "40px" }} source={LyftImg} />
                  <Text style={styles.californiaText}>VEHICLE INSPECTION</Text>
                </View>
              </View>
              <View style={styles.boxWidth}>
                <View style={styles.boxContainer}>
                  <View style={styles.lyftdrivertextContainer}>
                    <Text style={styles.lyftdriverText}>LYFT DRIVER INFO</Text>
                    {/* <Text style={styles.allfieldsText}>
                      ALL FIELDS ARE MANDATORY
                    </Text> */}
                  </View>

                  <View style={styles.nameinputContainer}>
                    <View style={{ width: "55%", marginRight: "10px" }}>
                      <Text style={styles.nameText}>
                        {reportData &&
                          `${reportData?.Username} ${
                            reportData?.UserlastName !== null
                              ? reportData?.UserlastName
                              : " "
                          }`}
                      </Text>
                      <Text style={styles.lyftdriverName}>
                        LYFT DRIVER NAME
                      </Text>
                    </View>
                    <View style={{ width: "45%", marginLeft: "20px" }}>
                      <Text style={styles.nameText}>
                        {reportData && reportData?.Useremail}
                      </Text>
                      <Text style={styles.lyftdriverName}>EMAIL ADDRESS</Text>
                    </View>
                  </View>
                  <View style={styles.threeinputfieldContainer}>
                    <View style={styles.nameinputContainerOne}>
                      <Text style={styles.threeinputFields}> </Text>
                      <Text style={styles.lyftdriverName}>
                        LYFT DRIVER SIGNATURE
                      </Text>
                    </View>
                    <View style={styles.nameinputContainerTwo}>
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData?.Userphone}
                      </Text>
                      <Text style={styles.lyftdriverName}>
                        {" "}
                        LYFT DRIVER PHONE NUMBER{" "}
                      </Text>
                    </View>
                    <View style={styles.nameinputContainerTwodate}>
                      <Text style={styles.threeinputFields}>
                        {moment(
                          new Date(reportData && reportData?.reviewedDate)
                        ).format("MM/ DD /YYYY")}
                      </Text>
                      <Text style={styles.lyftdriverName}>DATE</Text>
                    </View>
                  </View>

                  <View style={styles.lyftdrivertextContainer2}>
                    <Text style={styles.lyftdriverText}>
                      VEHICLE INSPECTION
                    </Text>
                    {/* <Text style={styles.allfieldsText}>
                      ALL FIELDS ARE MANDATORY
                    </Text> */}
                  </View>

                  <View style={styles.twoinputfieldContainer}>
                    <View
                      style={{
                        width: "25%",
                        marginRight: "10px",
                        marginLeft: "0px",
                      }}
                    >
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.licensePlateNumber}
                      </Text>
                      <Text style={styles.lyftdriverName}>LICENSE PLATE #</Text>
                    </View>
                    <View
                      style={{
                        width: "25%",
                        marginRight: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.inspectionState}
                      </Text>
                      <Text style={styles.lyftdriverName}>
                        LICENSE PLATE STATE
                      </Text>
                    </View>
                    <View style={{ width: "50%", marginLeft: "10px" }}>
                      <Text style={styles.threeinputFields}>
                        {reportData && reportData.vin}
                      </Text>
                      <Text style={styles.lyftdriverName}>VIN #</Text>
                    </View>
                  </View>
                  <View style={styles.nameinputContain}>
                    <View style={{ width: "25%", marginLeft: "0px" }}>
                      <Text style={styles.nameText}>
                        {reportData && reportData.make}
                      </Text>
                      {/* <Image
                          style={{
                            position: "absolute",
                            height: "100px",
                            width: "100px",
                            top: "-50px",
                            right: "100px",
                          }}
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                            reportData && reportData.Inspectorsignature
                          }?x-request=xhr`}
                        /> */}
                      <Text style={styles.lyftdriverName}>VEHICLE MAKE</Text>
                    </View>
                    <View style={{ width: "25%", marginLeft: "20px" }}>
                      <Text style={styles.nameText}>
                        {reportData && reportData.model}
                      </Text>
                      <Text style={styles.lyftdriverName}>VEHICLE MODEL</Text>
                    </View>
                    <View style={{ width: "20%", marginLeft: "20px" }}>
                      <Text style={styles.nameText}>
                        {reportData && reportData?.year}
                      </Text>
                      <Text style={styles.lyftdriverName}>VEHICLE YEAR</Text>
                    </View>
                    <View style={{ width: "30%", marginLeft: "10px" }}>
                      <Text style={styles.nameText}>
                        {reportData && reportData?.milage}
                      </Text>
                      <Text style={styles.lyftdriverName}>VEHICLE MILEAGE</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.vehicleinspectionPadding}>
                <View style={styles.belowLineText}>
                  <Text>INSPECTOR ONLY BELOW THIS LINE</Text>
                </View>
                <View style={styles.boxLineContainer}>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                  <View style={styles.boxContainerBlack}></View>
                </View>
              </View>
              {/* <View style={{ marginLeft: "20px" }}>
                <Text style={styles.inspectionpointsText}>
                  INSPECTION POINTS
                </Text>
                <View style={{ flexWrap: "wrap", flexDirection: "row" }}>
                  <Image
                    style={{
                      width: "100%",
                      marginLeft: -10,
                      // marginTop: "20px",
                    }}
                    source={CheckBox}
                  />
                </View>
              </View> */}
              <View style={{ flexDirection: "row", paddingHorizontal: "30px" }}>
                <View style={{ flexDirection: "column", width: "50%" }}>
                  <View style={styles.inspectionfirstContainer}>
                    <Text style={styles.inspectionText}>Inspection Point</Text>
                    <View style={styles.passfailContainer}>
                      <Text style={styles.passText}>Pass</Text>
                      <Text style={styles.failText}>Fail</Text>
                    </View>
                  </View>
                  {checksData?.map((check, index) =>
                    index <= 10 ? (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.numbersText}>{index + 1}.</Text>
                            <Text style={styles.detailsText}>
                              {check.Check.name}
                            </Text>
                          </View>

                          <View style={styles.passfailContainer}>
                            {check.status == true || check.status == 1 ? (
                              <View style={styles.tickpassContainer}>
                                <Image
                                  style={{ width: "16px", marginLeft: "2px" }}
                                  source={TicketImg}
                                />
                              </View>
                            ) : (
                              <View style={styles.tickpassContainer}></View>
                            )}
                            {check.status == false || check.status == 0 ? (
                              <View style={styles.tickfailContainer}>
                                <Image
                                  style={{ width: "16px", marginLeft: "2px" }}
                                  source={TicketImg}
                                />
                              </View>
                            ) : (
                              <View style={styles.tickfailContainer}></View>
                            )}
                          </View>
                        </View>
                        {check?.Check?.SubChecks?.length > 0 &&
                          check?.Check?.SubChecks?.map((subCheck, index) => (
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              <Text style={styles.detailsText2}>
                                {subCheck?.checkName}
                              </Text>
                              <View style={styles.passfailContainer}>
                                {subCheck?.SubInspectionCheck?.status == true ||
                                subCheck?.SubInspectionCheck?.status == 1 ? (
                                  <View style={styles.tickpassContainer}>
                                    <Image
                                      style={{
                                        width: "16px",
                                        marginLeft: "2px",
                                      }}
                                      source={TicketImg}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickpassContainer}></View>
                                )}
                                {subCheck?.SubInspectionCheck?.status ==
                                  false ||
                                subCheck?.SubInspectionCheck?.status == 0 ? (
                                  <View style={styles.tickfailContainer}>
                                    <Image
                                      style={{
                                        width: "16px",
                                        marginLeft: "2px",
                                      }}
                                      source={TicketImg}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickfailContainer}></View>
                                )}
                              </View>
                            </View>
                          ))}
                      </>
                    ) : null
                  )}
                </View>

                <View style={{ flexDirection: "column", width: "50%" }}>
                  <View style={styles.inspectionfirstContainer}>
                    <Text style={styles.inspectionText}>Inspection Point</Text>
                    <View style={styles.passfailContainer}>
                      <Text style={styles.passText}>Pass</Text>
                      <Text style={styles.failText}>Fail</Text>
                    </View>
                  </View>

                  {checksData?.map((check, index) =>
                    index > 10 ? (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.numbersText}>{index + 1}.</Text>
                            <Text style={styles.detailsText}>
                              {check.Check.name}
                            </Text>
                          </View>
                          <View style={styles.passfailContainer}>
                            {check.status == true || check.status == 1 ? (
                              <View style={styles.tickpassContainer}>
                                <Image
                                  style={{ width: "16px", marginLeft: "2px" }}
                                  source={TicketImg}
                                />
                              </View>
                            ) : (
                              <View style={styles.tickpassContainer}></View>
                            )}
                            {check.status == false || check.status == 0 ? (
                              <View style={styles.tickfailContainer}>
                                <Image
                                  style={{ width: "16px", marginLeft: "2px" }}
                                  source={TicketImg}
                                />
                              </View>
                            ) : (
                              <View style={styles.tickfailContainer}></View>
                            )}
                          </View>
                        </View>
                        {check?.Check?.SubChecks?.length > 0 &&
                          check?.Check?.SubChecks?.map((subCheck, index) => (
                            <View
                              style={{
                                flexDirection: "row",
                                marginTop: "11px",
                                marginLeft: "17px",
                              }}
                            >
                              <Text style={styles.detailsText2}>
                                {subCheck?.checkName}
                              </Text>
                              <View
                                style={{
                                  borderBottom: "1px solid black",
                                  width: "50px",
                                  marginLeft: "5px",
                                  textAlign: "center",
                                  fontSize: "15px",
                                }}
                              >
                                <Text style={styles.detailsText3}>
                                  {subCheck?.SubInspectionCheck?.checkValue}
                                </Text>
                              </View>
                            </View>
                          ))}
                      </>
                    ) : null
                  )}
                </View>
              </View>
              <View style={styles.inspectionflex}>
                <View style={{ width: "35%" }}>
                  <Text style={styles.inspectionText}>Inspection Point</Text>
                </View>
                <View style={styles.inspectionflex1}>
                  <View style={{ width: "10%", marginLeft: "50px" }}>
                    <Text>Pass</Text>
                  </View>
                  <View style={{ width: "10%", marginLeft: "20px" }}>
                    <Text>Fail</Text>
                  </View>
                  <View style={{ width: "10%", marginLeft: "20px" }}>
                    <Text>Adding?</Text>
                  </View>
                  <View style={{ width: "10%", marginLeft: "20px" }}>
                    <Text>Reinspect?</Text>
                  </View>
                </View>
              </View>
              <View style={styles.bodydamage}>
                <View>
                  <Text style={styles.numbersText}>23. </Text>
                </View>
                <View>
                  <Text>Body Damage </Text>
                </View>
                <View style={styles.bodydamage1}>
                  <Text>
                    (If fail, please mark with an X where the damage is on the
                    vehicle)
                  </Text>
                </View>
                <View style={styles.passCheckBox}>
                  {reportData && reportData.finalStatus === "pass" ? (
                    <Image
                      style={{ width: "16px", marginLeft: "2px" }}
                      source={TicketImg}
                    />
                  ) : null}
                </View>
                <View style={styles.failCheckBox}>
                  {reportData && reportData.finalStatus === "fail" ? (
                    <Image
                      style={{ width: "16px", marginLeft: "2px" }}
                      source={TicketImg}
                    />
                  ) : null}
                </View>
              </View>
              <View style={styles.tnc001}>
                <Text style={styles.tnc002}>TNC: 0001-{TNCNumber}</Text>
              </View>
              <View style={styles.textdecoration}>
                <Text>
                  Add the last 4 digits of the vehicle license plate number
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View>
                  <Image
                    style={{
                      width: "300px",
                      height: "170px",
                      marginLeft: "30px",
                    }}
                    source={lyftCarImage}
                  />
                </View>
                <View style={styles.inspectionsecondContainer}>
                  <View style={styles.failpassbox}>
                    <View style={styles.boxinspectionContainer}>
                      <Text style={styles.boxinspectionText}>
                        VEHICLE INSPECTION
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {reportData && reportData.finalStatus === "pass" ? (
                        <View style={styles.orangeCircle}>
                          <Text style={styles.boxpassText}>PASS</Text>
                        </View>
                      ) : (
                        <Text style={styles.boxpassText}>PASS</Text>
                      )}
                      {reportData && reportData.finalStatus === "fail" ? (
                        <View style={styles.orangeCircle}>
                          <Text style={styles.boxpassText}>FAIL</Text>
                        </View>
                      ) : (
                        <Text style={styles.boxpassText}>FAIL</Text>
                      )}
                    </View>
                    <View style={styles.boxinspectionContainer1}>
                      <Text style={styles.boxinspectionText1}>
                        (Please Circle One) One failed checkmark or more makes
                      </Text>
                      <Text style={styles.boxinspectionText1}>
                        for an overall Failed Vehicle Inspection
                      </Text>
                    </View>
                    <View style={styles.boxinspectionContainer1}>
                      <Text style={styles.boxinspectionText1}>
                        Applicant - Please note that a failed vehicle inspection
                      </Text>
                      <Text style={styles.boxinspectionText1}>
                        form will be reviewed before a final decision is made
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.tobecompletedWidth}>
                <View style={styles.tobecompletedContainer}>
                  <View
                    style={{
                      width: "50%",
                      marginLeft: "10px",
                      marginTop: "0px",
                      marginRight: "10px",
                    }}
                  >
                    <Text>TO BE COMPLETED BY INSPECTOR</Text>
                  </View>
                </View>
                <View style={styles.tobecompletedContainer}>
                  <View style={{ width: "40%", marginLeft: "20px" }}>
                    <Text style={styles.threeinputFields}>{`${
                      reportData && reportData.Inspectorname
                    } ${reportData && reportData.InspectorlastName}`}</Text>
                    <Text style={styles.lyftdriverName}>INSPECTOR NAME</Text>
                  </View>
                  <View
                    style={{
                      width: "40%",
                      marginLeft: "20px",
                      marginRight: "20px",
                      marginTop: "17px",
                    }}
                  >
                    <View style={styles.threeinputFields}>
                      <Image
                        style={{
                          position: "absolute",
                          height: "100px",
                          width: "100px",
                          top: "-70px",
                          right: "100px",
                        }}
                        source={`${process.env.REACT_APP_AWS_S3_LINK}/${
                          reportData && reportData?.Inspectorsignature
                        }?x-request=xhr`}
                      />
                    </View>
                    <Text style={styles.lyftdriverName}>
                      INSPECTOR SIGNATURE
                    </Text>
                  </View>

                  <View style={{ width: "20%", marginRight: "20px" }}>
                    <Text style={styles.threeinputFields}>
                      {moment(
                        new Date(reportData && reportData?.reviewedDate)
                      ).format("MM/ DD /YYYY")}
                    </Text>
                    <Text style={styles.lyftdriverName}>DATE</Text>
                  </View>
                </View>
                <View style={styles.tobecompletedContainer}>
                  <View
                    style={{
                      width: "50%",
                      marginRight: "20px",
                      marginLeft: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.threeinputFields}>Chex.AI</Text>
                    <Text style={styles.lyftdriverName}>COMPANY NAME</Text>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      marginRight: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.phoneInputFields}>
                    415-234-3496
                    </Text>
                    <Text style={styles.lyftdriverName}>COMPANY PHONE</Text>
                  </View>
                </View>
                <View style={styles.tobecompletedContainer}>
                  <View
                    style={{
                      width: "100%",
                      marginRight: "20px",
                      marginLeft: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.threeinputFields}>
                      {reportData && reportData?.stateAddress}
                    </Text>
                    <Text style={styles.lyftdriverName}>COMPANY ADDRESS</Text>
                  </View>
                </View>
              </View>
              {/*<Text style={styles.bottomPara}>*/}
              {/*  Applicants who do not match the photo on their driver's license*/}
              {/*  or do not have the proper documents, cannot complete an*/}
              {/*  inspection. Failing inspections can only be remedied by the same*/}
              {/*  shop on the same day. If an applicant fails the inspection and*/}
              {/*  is unable to fix the issues on the spot, a new appointment must*/}
              {/*  be made, and a new inspection form must be used.*/}
              {/*</Text>*/}
              {/*<Text style={styles.bottomPara}>*/}
              {/*  This inspection form reflects a 19 point inspection only on the*/}
              {/*  date of the inspection listed above. For purposes of this*/}
              {/*  inspection, the wheels were not removed from the vehicle, and*/}
              {/*  the vehicle was not put on a lift, nor was it checked for*/}
              {/*  emissions.*/}
              {/*</Text>*/}
            </Page>
          </Document>
        }
        fileName="Vehicle-certificate.pdf"
      >
        {({ blob, url, loading, error }) => (
          <>
            {isLoading ? (
              <ClipLoader color={"#246DB5"} size={40} />
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    handleReload();
                  }}
                  style={{
                    fontSize: "15px",
                    marginBottom: "20px",
                    marginTop: "20px",
                    color: "white",
                    fontWeight: "bold",
                    margin: "auto",
                    background: "#3276ba",
                    padding: "13px",
                    borderRadius: "50px",
                  }}
                >
                  Download Vehicle Report
                </div>
                <i className="fa fa-download" />
              </div>
            )}
          </>
        )}
      </PDFDownloadLink>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    reportData: state.vehicleInstruction.lyftVehicleCertificate,
    checksData: setLyftReportData(
      state.vehicleInstruction.lyftVehicleCertificate?.Files
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LyftCertificateNevada);
