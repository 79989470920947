import React from "react";
import "./style.css";

const EmailNotificationModal = ({ isVisible, handleModal }) => {
  if (!isVisible) return null;

  return (
    <div className="custom-modal-overlay" onClick={() => handleModal(false)}>
      <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
        <button
          className="custom-modal-close"
          onClick={() => handleModal(false)}
        >
          &times;
        </button>
        <div className="custom-modal-content">
          <h2>Email Notification</h2>
          <p>
            Emailing through the app is currently unavailable. Please email us
            directly at <a href="mailto:support@chex.ai">support@chex.ai</a> for
            a quicker response.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailNotificationModal;
