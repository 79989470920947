/* eslint-disable */

/* eslint-disable */

import React, { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
//@ts-ignore
import TicketImg from "../../Assets/tickimg.png";
import ActionCreators from "../../actions";
import { setOtherReportData } from "../../utils/functions";
import ChackboxImg from "../../Assets/Checkbox.png";
import kalamFont from "../../font/Kalam-Light.ttf";
import arrow from "../../Assets/arrow.PNG";
import { BsArrowDown } from "react-icons/bs";
import { FaReact } from "react-icons/fa";
import LatoBold from "../../font/Lato-Bold.ttf";

import "./styles.css";
import { Checkbox } from "antd";

Font.register({ family: "kalamFont", format: "truetype", src: kalamFont });

Font.register({ family: "LatoBold", format: "truetype", src: LatoBold });

const styles = StyleSheet.create({
  body: {
    paddingBottom: 65,
    // paddingHorizontal: 10,
  },
  headerContainer: {
    padding: "10px",
    backgroundColor: "black",
    height: "85px",
    borderLeft: "13px solid red",
    marginTop: "-3",
  },
  vehicleinspectionText: {
    color: "white",
    fontSize: "30px",
    fontWeight: "300",
    fontStyle: "normal",
  },
  tobecompletedContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E6E6E6",
    padding: "5px",
  },
  tobecompletedText: {
    color: "#151515",
    fontSize: "9px",
    fontWeight: "200",
    fontStyle: "normal",
    marginLeft: "14px",
    marginTop: "4",
  },
  tobecompletedrightText: {
    color: "black",
    fontSize: "9px",
    fontWeight: "200",
    fontStyle: "normal",
    // textAlign:'right',
    marginLeft: "468px",
    textTransform: "uppercase",
    marginTop: "4",
  },
  tobeRightcompletedText: {
    color: "#151515",
    fontSize: "9px",
    fontWeight: "200",
    fontStyle: "normal",
    marginLeft: "500px",
  },
  topfieldsmainContainer: {
    backgroundColor: "#F7F7F7",
    padding: "10px",
    height: "50px",
  },
  namefiledsContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignItems: "flex-end",
  },
  firstField: {
    width: "55%",
    marginRight: "20px",
  },
  secondfield: {
    width: "45%",
  },
  fieldBorder: {
    borderBottomWidth: 1,
    borderBottomColor: "#3F3F3F",
  },
  fieldBorder2: {
    borderBottomWidth: 1,
    borderBottomColor: "#3F3F3F",
  },
  topnameText: {
    color: "black",
    backgroundColor: "yellow",
    marginRight: "85px",
    fontSize: "16px",
    fontWeight: "100",
    fontStyle: "normal",
    marginLeft: "10px",
    // fontFamily: 'kalamFont',
    marginBottom: "3px",
  },
  bottomnameText: {
    color: "black",
    fontSize: "8px",
    fontWeight: "100",
    fontStyle: "normal",
    marginTop: "3px",
  },
  inspectionpointsText: {
    color: "black",
    fontSize: "15px",
    fontWeight: "bold",
    fontStyle: "normal",
    marginTop: "25px",
  },
  passfailrowmainContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "space-around",
    flex: "0 0 45%",
    marginRight: "30px",
    width: "100%",
  },

  dashedBorder: {
    width: "80px",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#959595",
    marginRight: "5px",
  },
  dashedBorder2: {
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#959595",
    marginRight: "5px",
  },

  tickimgContainer: {
    width: "13px",
    height: "13px",
    borderWidth: 1,
    borderColor: "black",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "4px",
  },
  tickimgContainer2: {
    width: "13px",
    height: "13px",
    borderWidth: 1,
    borderColor: "black",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "4px",
  },
  emptytickimgContainer: {
    width: "13px",
    height: "13px",
    borderWidth: 1,
    borderColor: "black",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "8px",
  },
  emptytickimgContainer2: {
    width: "13px",
    height: "13px",
    borderWidth: 1,
    borderColor: "black",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "8px",
  },
  nameText: {
    color: "black",
    fontSize: "8px",
    fontWeight: "bold",
    fontStyle: "normal",
    textTransform: "uppercase",
    width: "50%",
  },
  passText: {
    color: "#959595",
    fontSize: "11px",
    fontWeight: "bold",
    fontStyle: "normal",
    textTransform: "uppercase",
    marginLeft: "5px",
  },
  failText: {
    color: "#959595",
    fontSize: "11px",
    fontWeight: "bold",
    fontStyle: "normal",
    textTransform: "uppercase",
    marginLeft: "5px",
  },
  nameTextss: {
    color: "black",
    fontSize: "10px",
    fontWeight: "300",
    fontStyle: "normal",
    textTransform: "uppercase",
    marginLeft: "20px",
    width: "30%",
  },

  passTexts2: {
    color: "#959595",
    fontSize: "11px",
    fontWeight: "700",
    fontStyle: "normal",
    textTransform: "uppercase",
    marginLeft: "5px",
  },
  failTexts2: {
    color: "#959595",
    fontSize: "11px",
    fontWeight: "700",
    fontStyle: "normal",
    textTransform: "uppercase",
    marginLeft: "5px",
  },
  inspectionresultContainer: {
    width: "87%",
    backgroundColor: "#767170",
    padding: "10px",
    alignSelf: "center",
    marginTop: "19px",
  },
  inspectionrexultText: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "200",
    color: "white",
    textAlign: "center",
  },
  inspectiondatemainContainer: {
    padding: "10px",
    backgroundColor: "#F7F7F7",
    width: "87%",
    alignSelf: "center",
  },
  markingText: {
    display: "flex",
    flexDirection: "row",
    fontSize: "13px",
    fontStyle: "normal",
    // fontWeight: '200',
    color: "black",
    textAlign: "center",
    marginTop: "5px",
  },
  passfaildatedirectionContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "30px",
    marginBottom: "15px",
  },
  boxBorder: {
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
    borderBottomColor: "black",
    width: "130%",
    letterSpacing: "10px",
  },
  boxpasscircleContainer: {
    width: "70px",
    height: "70px",
    borderWidth: 3,
    borderColor: "#000",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
  },
  boxpassText: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "black",
  },
  boxpasscolorText: {
    fontSize: "18px",
    fontWeight: "bolder",
    backgroundColor: "yellow",
    padding: "5",
    color: "black",
  },
  boxdateText: {
    fontSize: "12px",
    color: "black",
    backgroundColor: "yellow",
    marginBottom: "2px",
    // fontFamily:'kalamFont',

    fontWeight: "bold",
  },
  inspectiondateText: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginTop: "3px",
  },
  textDirection: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: "45",
  },
  bottommainContainer: {
    backgroundColor: "#F7F7F7",
    padding: "10px",
    // marginTop: '50px',
  },
  byvehicleText: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    color: "black",
    textTransform: "uppercase",
  },
  firstfourfieldsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "10px",
    alignItems: "flex-end",
  },
  bottomboxBorder: {
    borderBottomWidth: 1,
    borderColor: " #767170",
  },
  bottomboxtoptext: {
    fontSize: "10px",
    fontStyle: "normal",
    // fontFamily: 'kalamFont',
    backgroundColor: "yellow",
    fontWeight: "500",
    color: "black",
    marginLeft: "10px",
    marginBottom: "4px",
    textTransform: "uppercase",
  },
  bottomboxbootomText: {
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    color: "black",
    textTransform: "uppercase",
    marginTop: "3px",
  },
  lastbottomText: {
    textAlign: "center",
    marginTop: "-5px",
    fontSize: "10px",
    color: "black",
  },
});

const UberCertificate = (props) => {
  const history = useHistory();
  // const [agent,setagent]=useState(false)
  const { isLoading, handleModal, SetModal } = props;

  // useEffect(()=>
  // {
  //   if (userAgent.includes("iPhone")) {
  //     setagent(true)
  //   }
  // },[])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const getToken = () => localStorage.getItem("token") || null;
    if (getToken) {
      props.getVehicleCertificate(
        { id: props?.id, companyId: props.companyId },
        props.setLoading,
        "uber"
      );
    } else {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("token");
      localStorage.removeItem("vehicleData");
      window.location.reload("/login");
    }
  }, [props?.companyId]);

  const handleReload = () => {
    de;
    SetModal(false);
    localStorage.setItem("tabStatus", "REVIEWED");
    setTimeout(function () {
      handleModal();
    }, 200);
  };
  return (
    <div className="App">
      <PDFDownloadLink
        wrap={false}
        document={
          <Document>
            <Page size="A3" style={styles.body}>
              <View style={{ backgroundColor: "black" }}>
                <View
                  style={{ borderTop: "13px solid red", marginRight: "740" }}
                ></View>

                <View style={styles.headerContainer}>
                  <Text style={styles.vehicleinspectionText}>
                    Vehicle Inspection
                  </Text>
                </View>
              </View>
              <View style={styles.tobecompletedContainer}>
                <Text style={styles.tobecompletedText}>
                  TO BE COMPLETED BY VEHICLE OPERATOR{" "}
                </Text>
                <Image
                  style={{
                    width: "10",
                    marginLeft: "1",
                    height: "18",
                    marginTop: 1,
                  }}
                  source={arrow}
                />
                <Text style={styles.tobecompletedrightText}>
                  please print legibly
                </Text>
              </View>
              <View style={styles.topfieldsmainContainer}>
                <View style={styles.namefiledsContainer}>
                  <View style={styles.firstField}>
                    <Text style={styles.topnameText}>{`${
                      props.reportData &&
                      `${props.reportData?.Username} ${
                        props.reportData?.UserlastName
                          ? props.reportData?.UserlastName
                          : ""
                      }`
                    }`}</Text>
                    <View style={styles.fieldBorder} />
                    <Text style={styles.bottomnameText}>FULL NAME</Text>
                  </View>
                  <View style={styles.secondfield}>
                    <Text style={styles.topnameText}>
                      {props.reportData && props.reportData?.Userphone}
                    </Text>
                    <View style={styles.fieldBorder2} />
                    <Text style={styles.bottomnameText}>PHONE NUMBER</Text>
                  </View>
                </View>
              </View>
              {/* <View style={{ marginLeft: '20@s' }}>
                <Text style={styles.inspectionpointsText}>INSPECTION POINTS</Text>
                <View style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {props.checksData &&
                      props.checksData.map((data) => (
                        <View style={styles.passfailrowmainContainer}>
                          <Text style={styles.nameText}>{data.name} </Text>
                          <View style={styles.dashedBorder} />
                          {data.status === 1 || data.status === true ? (
                            <View style={{ flexDirection: 'row', marginLeft: '9px' }}>
                              <View style={styles.tickimgContainer}>
                                <Image style={{ width: '15px' }} source={TicketImg} />
                              </View>
                              <Text style={styles.passText}>PASS</Text>
                            </View>
                          ) : (
                            <View style={{ flexDirection: 'row', marginRight: '3px' }}>
                              <View style={styles.emptytickimgContainer}></View>
                              <Text style={styles.passText}>PASS</Text>
                            </View>
                          )}

                          {data.status === 0 || data.status === false ? (
                            <View style={{ flexDirection: 'row' }}>
                              <View style={styles.tickimgContainer}>
                                <Image style={{ width: '15px' }} source={TicketImg} />
                              </View>
                              <Text style={styles.passText}>FAIL</Text>
                            </View>
                          ) : (
                            <View style={{ flexDirection: 'row' }}>
                              <View style={styles.emptytickimgContainer}></View>
                              <Text style={styles.passText}>FAIL</Text>
                            </View>
                          )}
                        </View>
                      ))}
                  </View>
                </View>
              </View> */}
              <Image
                style={{ width: "95%", marginTop: "18px", marginRight: "17" }}
                source={ChackboxImg}
              />

              <View style={styles.inspectionresultContainer}>
                <Text style={styles.inspectionrexultText}>
                  <Text style={{ fontWeight: "900", fontFamily: "LatoBold" }}>
                    VEHICLE INSPECTION RESULTS
                  </Text>{" "}
                  (Inspector To Circle)
                </Text>
              </View>
              <View style={styles.inspectiondatemainContainer}>
                <Text style={styles.markingText}>
                  Any markings on the{""}
                  <Text style={{ fontWeight: "900", fontFamily: "LatoBold" }}>
                    {" "}
                    “Fail”
                  </Text>{" "}
                  side will automatically fail inspection.
                </Text>
                <View style={styles.passfaildatedirectionContainer}>
                  {props.reportData &&
                  props.reportData?.finalStatus === "pass" ? (
                    <View style={styles.boxpasscircleContainer}>
                      <Text style={styles.boxpasscolorText}>PASS</Text>
                    </View>
                  ) : (
                    <Text style={styles.boxpassText}>PASS</Text>
                  )}
                  <View style={styles.textDirection}>
                    <Text style={styles.boxdateText}>
                      {moment(
                        props.reportData && props.reportData?.reviewedDate
                      ).format("MM-DD-YYYY")}
                    </Text>
                    <View style={styles.boxBorder} />
                    <Text style={styles.inspectiondateText}>
                      INSPECTION DATE
                    </Text>
                  </View>
                  {props.reportData &&
                  props.reportData?.finalStatus === "fail" ? (
                    <View style={styles.boxpasscircleContainer}>
                      <Text style={styles.boxpasscolorText}>FAIL</Text>
                    </View>
                  ) : (
                    <Text style={styles.boxpassText}>FAIL</Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  backgroundColor: "#E5E5E5",
                  padding: "13px",
                  marginTop: 54,
                }}
              ></View>
              <View style={styles.bottommainContainer}>
                {/* <Text style={styles.byvehicleText}>to be completed by vehicle inspector</Text> */}

                <View style={styles.firstfourfieldsContainer}>
                  <View
                    style={{
                      width: "20%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.milage}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      Vehicle Mileage
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "20%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.registrationState}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      license plate state
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "20%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.licensePlateNumber}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      License plate number
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "40%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.vin}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>Vin</Text>
                  </View>
                </View>

                <View style={styles.firstfourfieldsContainer}>
                  <View
                    style={{
                      width: "40%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.make}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>vehicle make</Text>
                  </View>
                  <View
                    style={{
                      width: "22%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.model}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      vehicle model
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "18%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.year}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>vehicle year</Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.vehicleDoors}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      number of doors
                    </Text>
                  </View>
                </View>

                <View style={styles.firstfourfieldsContainer}>
                  <View
                    style={{
                      width: "41%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData?.registrationSticker === true
                        ? "YES"
                        : "NO"}{" "}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      Has Registration Sticker? (Yes/No)
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.RegistrationDate}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      Registration sticker month/year (mm/yy)
                    </Text>
                  </View>
                </View>

                <View style={styles.firstfourfieldsContainer}>
                  <View
                    style={{
                      width: "41%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>Chex.AI</Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      inspector company
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData?.stateAddress}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      inspector address
                    </Text>
                  </View>
                </View>

                <View style={styles.firstfourfieldsContainer}>
                  <View
                    style={{
                      width: "42%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>{`${
                      props.reportData && props.reportData?.Inspectorname
                    } ${
                      props.reportData && props.reportData?.InspectorlastName
                    }`}</Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      inspector name{" "}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "30%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "yellow",
                        marginBottom: "4",
                        height: "17",
                        position: "relative",
                      }}
                    >
                      {props.reportData &&
                      props.reportData?.Inspectorsignature ? (
                        <Image
                          style={{
                            width: "100px",
                            marginLeft: "50px",
                            marginTop: -19,
                            height: "55",
                            position: "absolute",
                          }}
                          source={`${process.env.REACT_APP_AWS_S3_LINK}/${
                            props.reportData &&
                            props.reportData?.Inspectorsignature
                          }?x-request=xhr`}
                        />
                      ) : (
                        <Text style={styles.bottomboxtoptext}>{`${
                          props.reportData && props.reportData?.Inspectorname
                        } ${
                          props.reportData &&
                          props.reportData[0]?.InspectorlastName
                        }`}</Text>
                      )}
                    </View>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      inspector signature
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "30%",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  >
                    <Text style={styles.bottomboxtoptext}>
                      {props.reportData && props.reportData.stateNumber}
                    </Text>
                    <View style={styles.bottomboxBorder} />
                    <Text style={styles.bottomboxbootomText}>
                      state certification number
                    </Text>
                  </View>
                </View>
              </View>
              <Text style={styles.lastbottomText}>
                This certification expires on (1) year from inspection date.
              </Text>
              <View
                style={{
                  border: "13px solid red",
                  marginLeft: 800,
                  borderBottomWidth: 0,
                  borderTopWidth: 0,
                  height: 64,
                  borderLeftWidth: 0,
                  marginTop: -58,
                  marginBottom: "-3",
                }}
              ></View>
              <View
                style={{
                  borderTop: "13px solid red",
                  marginLeft: 750,
                  borderRight: "20px solid red",
                }}
              ></View>
            </Page>
          </Document>
        }
        fileName="Vehicle-certificate.pdf"
      >
        {({ blob, url, loading, error }) => (
          <>
            {isLoading ? (
              <ClipLoader color={"#246DB5"} size={40} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => handleReload()}
                  style={{
                    fontSize: "15px",
                    marginBottom: "20px",
                    marginTop: "20px",
                    color: "white",
                    fontWeight: "bold",
                    margin: "auto",
                    background: "#3276ba",
                    padding: "13px",
                    borderRadius: "50px",
                  }}
                >
                  Download vehicle report
                </div>
              </div>
            )}
          </>
        )}
      </PDFDownloadLink>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    reportData: state.vehicleInstruction.uberVehicleCertificate,
    checksData: setOtherReportData(
      state.vehicleInstruction.uberVehicleCertificate?.Files,
      "index_2"
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UberCertificate);
