import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom";

const ContactUsLogoComponent = () => {
    const currentUser = localStorage.getItem("currentUser");
    const user = currentUser ? true : false;
    
  const history = useHistory();
  const handleClick = () => {
    if(user){
        history.push("/contactUs");
    }
    else{
        history.push("/contactPage");
    }
    
  };
  return (
    <div className="main-contact-logo-div">
      <button onClick={handleClick} className="contact-us-logo">
        <img src="https://res.cloudinary.com/dgjordf6e/image/upload/v1716278207/y5vnyskrzo0kmiwflqxc.png" alt="logo" />
      </button>
    </div>
  );
};

export default ContactUsLogoComponent;
