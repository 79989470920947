import { Link as RouterLink } from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import { Box, Button, Container, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import iconMessage from '../../Assets/2663517.jpg';

const NotFound = () => {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <HelmetProvider>
            <Helmet>
                <title>Not Found</title>
            </Helmet>
            <Box
                sx={{
                    alignItems: "center",
                    backgroundColor: "background.paper",
                    display: "flex",
                    minHeight: "100%",
                    px: 3,
                    py: "60px",
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant={mobileDevice ? "h5" : "h3"}
                    >
                        404: The promo link you are looking for isn’t valid
                    </Typography>
                    <Typography
                        align="center"
                        color="textSecondary"
                        sx={{ mt: 0.5 }}
                        variant="subtitle2"
                        style={{marginTop: 10}}

                    >
                        You either tried some shady route or you came here by mistake.
                        Whichever it is, try using the navigation.
                    </Typography>



                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                        }}
                    >
                        {/*<img style={{width: '100%'}} src={iconMessage} />*/}
                        <Box
                            alt="Under development"
                            component="img"
                            src={iconMessage}
                            sx={{
                                height: "auto",
                                maxWidth: "100%",
                                width: 400,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 6,
                        }}
                    >
                        <Button
                            style={{color: '#FF7A00', borderColor: '#FF7A00', borderWidth: 1, backgroundColor: '#fff'}}
                            component={RouterLink}
                            to="/"
                            variant="outlined"
                        >
                            Back to Home
                        </Button>
                    </Box>
                </Container>
            </Box>
            </HelmetProvider>
    );
};

export default NotFound;

