
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useToasts } from 'react-toast-notifications';

import ActionCreators from '../../actions';
import { ContactUsScreen } from '../../Screens';
import { setCompanies } from '../../utils/functions';
import FaqsScreen from "../../Screens/FaqsScreen";

const FaqsContainer = (props) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > props.scrollValue);
    });
  }, []);

  const data = {
    rows: [
      {
        question: "I need car inspection for Uber/Lyft.",
        answer: <p>You can use our online platform for your Uber and Lyft inspection. Please register your account at <a href="https://www.chex.ai/register" style={{color:'#000', fontWeight: 'bold' }}>https://www.chex.ai/register </a> and follow the instructions to upload photos and videos from your phone. Once you submit, we’ll prepare the certificate for you to upload to your Uber/Lyft account.</p>,
      },
      {
        question: "How much does an inspection cost through Chex.AI?",
        answer:
            "We charge $29.99 for a single inspection. If you want to bundle it with multiple inspections it will be additional $17.99 for each. You can find details on service fee on our website.",
      },
      {
        question: "Is your inspection accepted by ridesharing companies?",
        answer: `Yes, we are certified to conduct vehicle inspections for rideshare and car-sharing companies and have completed thousands of inspections.`,
      },
      {
        question: "I don't see my state in the inspection states list.",
        answer: "Unfortunately, we are not authorized to conduct inspections in all 50 states. States missing from the list are the ones where we don’t operate. We update the list regularly and may add states as we get approvals.",
      },
      {
        question: "Do I need to set up an appointment to get my car inspected?",
        answer: "No, all inspections are done through your smartphone. All you need to do is register your account, start an inspection and upload photos and short video clips at your convenience, from any location of your choice.",
      },
      {
        question: "What happens after I upload all the inspection items (Photos and Videos)?",
        answer: "Once you upload all the inspection items, our inspectors review those and our system automatically generates certificates for you.",
      },
      {
        question: "How do I get my certificate?",
        answer: "You will receive an email confirming that the certificate is ready. All certificates are stored in your Chex.AI account and can be accessed in the “Certificates” section of the homepage.",
      },
      {
        question: "What should I do after I get my certificate?",
        answer: "You will have to either download the PDF or take a screenshot of the certificate and upload it into your rideshare company account. We also send a copy of the certificate in an email directly to some of our ride sharing partners.",
      },
      {
        question: "What if my vehicle fails an inspection?",
        answer: "We typically ask users to re-upload items and allow you to re-upload as many times until all the requirements are met.",
      },
    ],
  };


  return <FaqsScreen scrollValue={scroll} data={data} />;
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    userData: state.auth.currentUser,
    isLoading: state.auth.isAuthLoading,
    companies: setCompanies(state.auth.companies),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FaqsContainer);
