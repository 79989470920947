/*eslint-disable */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';
import { GrFormClose } from 'react-icons/gr';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlineCar } from 'react-icons/ai';
import { UpCircleOutlined } from '@ant-design/icons';
import { IoCloseOutline } from 'react-icons/io5';

import { Modal, Collapse, Row, Col } from 'antd';
import './style.css';

import DownloadCertifcate from '../../Screens/Certificates/DownLoadCertificate';
import Lyftcertificate from '../../Screens/Certificates/Lyftcertificate';
import UberCertificate from '../../Screens/Certificates/UberCertificate';
import LyftCertificateArizona from '../../Screens/Certificates/LyftCertificateArizona';
import ConfimrationModal from '../../Components/ConfirmationModal';
import ProgressModal from '../../Components/ProgressModal';

const TabContentComponent = ({
  title,
  draft,
  reviewed,
  inReview,
  item,
  all,
  setCertificateLoading,
  certificateLoading,
  setReInspectionId,
  cancleReInspection,
  setReInspectionModal,
  loadingDiscard,
  handleDiscardInspection,
  setReInspectionLisencePlateNumber,
  genExtra,
}) => {
  const { Panel } = Collapse;
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [company, setCompany] = useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [iscertificateLoading, setisCertificateLoading] = useState(false);
  const [certificateData, setCertificateData] = useState({
    id: '',
    companyId: '',
    companyName: '',
    templateId: '',
  });

  const inspectionData = {
    vehicleId: item?.Vehicle?.id,
    plateNumber: item?.Vehicle?.licensePlateNumber
  }
  const history = useHistory();
  // const showModalLittle = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOkLittle = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancelLittle = () => {
  //   setIsModalVisible(false);
  // };

  const showModalCompany = () => {
    setCompanyModalVisible(true);
  };


  const handleOkCompany = () => {
    setCompanyModalVisible(false);
  };

  const handleCancelCompany = () => {
    setCompanyModalVisible(false);
    setCompany(false);
    setCertificateData({
      id: '',
      companyId: '',
      companyName: '',
      templateId: '',
    });
    window.location.reload();
  };

  // const handleReInspectionId = (item) => {
  //   setReInspectionId(item?.vehicleId);
  // };

  const handleDownload = (items, id) => {
    setCompany(true);
    setCertificateData({
      id: id,
      companyId: items?.Company?.id,
      companyName: items?.Company?.name,
      templateId: items?.Company?.templateId,
    });
  };

  const handleVehicleDetails = (item) => {
    history.push(`/VehicleAfterReviewing/${item?.id}/${item?.vehicleId}`);
  };

  const handleRedirect = (item) => {
    window.location.href = `/vehicleinspection/${item?.id}/${item?.vehicleId}?lyftUser=${item.lyftInspection}`;
  };
  return (
    <div>
      {all === true ? (
        <div>
          {item?.status === "IN_PROGRESS" ?
            <div onClick={() => { handleRedirect(item); localStorage.setItem('tabStatus', 'all') }} className="open-tab-container">
              <div className="open-tab-bg_color">
                <div className="tab-all-tag">
                  <div className="content-text">{title}</div>
                  {item?.status === "IN_PROGRESS" ?
                    <div className="tab-all-tag-content" style={{ backgroundColor: "#FF9900", border: "1px solid #FF9900" }}>
                      <div>{item?.status}</div>
                    </div>
                    // : item?.status === "REVIEWED" ?
                    //   <div className="tab-all-tag-content" style={{ backgroundColor: "#20C18D", border: "1px solid #20C18D" }}>
                    //     <div>{item?.status}</div>
                    //   </div>
                    //   : item?.status === "READY_FOR_REVIEW" ?
                    //     <div className="tab-all-tag-content" style={{ backgroundColor: "#55A4F1", border: "1px solid #55A4F1" }}>
                    //       <div>{item?.status}</div>
                    //     </div>
                    : null
                  }
                  {/* <div className="tab-content-container">
              <div className="arrow-icon-container">
              <RiArrowRightSLine color="orange" size={30} />
              </div>
            </div> */}
                </div>
              </div>
            </div>
            :
            <>
              {/* // <div className="open-tab-container">
            //   <div className="open-tab-bg_color">
            //     <div className="tab-all-tag">
            //       <div className="content-text">{title}</div> */}
              {item?.status === "REVIEWED" ?
                <>
                  <Collapse
                    className="reviewed-all-content"
                    defaultActiveKey={['0']}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => (
                      <div className="reviewed-all-tag">
                        <div className="reviewed-all-tag-text">{item?.status}</div>
                      </div>
                    )}
                  >
                    <Panel
                      style={{ overflow: 'hidden' }}

                      header={
                        <Row
                          gutter={40}
                        >
                          <Col >
                            <div className="reviewed-all-text">
                              {item?.inspectionCode}
                            </div>
                          </Col>
                          <Col>
                          </Col>
                        </Row>
                      }
                      key="1"
                      extra={genExtra}
                    >
                      <div className="document-container">
                        <IoDocumentTextOutline color="#1468BA" size={18} />
                        <div className="document-text"
                          onClick={() => { showModalCompany(); localStorage.setItem('tabStatus', 'all') }}>Download Certificate</div>
                      </div>
                      <div className="document-container"
                        onClick={() => { history.push(`/selectCompany/${true}?${inspectionData}`); localStorage.setItem('tabStatus', 'all') }}>
                        <AiOutlineCar color="#1468BA" size={18} />
                        <div className="document-text">
                          Re-Inspect Car
                      </div>
                      </div>
                      <div className="document-container">
                        <AiOutlineCar color="#1468BA" size={18} />
                        <div className="document-text"
                          onClick={() => { handleVehicleDetails(item); localStorage.setItem('tabStatus', 'all') }}>Car Details</div>
                      </div>
                    </Panel>
                  </Collapse>

                  <Modal
                    style={{ width: '40px', height: '40px' }}
                    title="Basic Modal"
                    visible={companyModalVisible}
                    onOk={handleOkCompany}
                    onCancel={() => {
                      handleCancelCompany();
                      // handleCancelLittle();
                      cancleReInspection();
                    }}
                  >
                    <div className="companies-modal">
                      <div className="modal-content-container">
                        <div></div>
                        <div className="option-text">Companies</div>
                        <GrFormClose color="black" size={25} />
                      </div>
                      {item?.CompanyInspections?.map((items) => {
                        return (
                          <div className="modal-company-text" key={items?.id}>
                            <div className="modal-links-text">{items?.Company?.name}</div>
                            <input
                              className="checkbox-container"
                              type="radio"
                              name="name"
                              onClick={() => {
                                handleDownload(items, item?.id);
                                // handleCancelLittle();
                              }}
                            />
                            {/* <form>
                      <input type="radio" id="html" name="fav_language" value="HTML" />
                      <label for="html">HTML</label>
                    </form> */}
                          </div>
                        );
                      })}
                      {company === true ? (
                        certificateData?.templateId === 3 ? (
                          <>
                            {certificateData?.companyName === 'Uber' || certificateData?.companyName === 'uber' ? (
                              <>
                                <UberCertificate
                                  SetModal={setCompanyModalVisible}
                                  handleModal={handleCancelCompany}
                                  setLoading={setisCertificateLoading}
                                  isLoading={iscertificateLoading}
                                  companyId={certificateData?.companyId}
                                  id={certificateData?.id}
                                />
                              </>
                            ) : item?.Vehicle?.registrationState?.toLowerCase() === 'arizona' && certificateData?.companyName?.toLowerCase() === 'lyft' ? (
                              <>
                                <LyftCertificateArizona
                                  SetModal={setCompanyModalVisible}
                                  handleModal={handleCancelCompany}
                                  setLoading={setisCertificateLoading}
                                  isLoading={iscertificateLoading}
                                  companyId={certificateData?.companyId}
                                  id={certificateData?.id}
                                />
                              </>
                            ) : <Lyftcertificate
                              SetModal={setCompanyModalVisible}
                              handleModal={handleCancelCompany}
                              setLoading={setisCertificateLoading}
                              isLoading={iscertificateLoading}
                              companyId={certificateData?.companyId}
                              id={certificateData?.id}
                            />}
                          </>
                        ) : certificateData?.templateId === 2 ? (
                          <>
                            {certificateData?.companyName === 'Uber' || certificateData?.companyName === 'uber' ? (
                              <>
                                <UberCertificate
                                  SetModal={setCompanyModalVisible}
                                  handleModal={handleCancelCompany}
                                  setLoading={setisCertificateLoading}
                                  isLoading={iscertificateLoading}
                                  companyId={certificateData?.companyId}
                                  id={certificateData?.id}
                                />
                              </>
                            ) : (
                              <>
                                <DownloadCertifcate
                                  SetModal={setCompanyModalVisible}
                                  handleModal={handleCancelCompany}
                                  setLoading={setisCertificateLoading}
                                  isLoading={iscertificateLoading}
                                  companyId={certificateData?.companyId}
                                  id={certificateData?.id}
                                />
                              </>
                            )}
                          </>
                        ) : certificateData?.templateId.length ? (
                          <>
                            <DownloadCertifcate
                              SetModal={setCompanyModalVisible}
                              handleModal={handleCancelCompany}
                              setLoading={setisCertificateLoading}
                              isLoading={iscertificateLoading}
                              companyId={certificateData?.companyId}
                              id={certificateData?.id}
                            />
                          </>
                        ) : null
                      ) : null}
                    </div>
                  </Modal>
                </>
                : item?.status === "READY_FOR_REVIEW" ?
                  <>
                    <div onClick={() => { localStorage.setItem('tabStatus', 'all') }} className="open-tab-container">
                      <div className="open-tab-bg_color">
                        <div className="tab-all-tag">
                          <div className="content-text">{title}</div>
                          {item?.status == "READY_FOR_REVIEW" ?
                            <div className="tab-all-tag-content" style={{ backgroundColor: "#55A4F1", border: "1px solid #55A4F1" }}>
                              <div>{"In Review"}</div>
                            </div>
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  </> : null
              }

              {/* <Collapse
              className="reviewed-content-container"
              defaultActiveKey={['0']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <div>gxdfydfgyd</div>
              )}
            >
              <Panel
                style={{ overflow: 'hidden' }}
                header={
                  <Row
                    gutter={40}
                    style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}
                  >
                    <Col>
                      <div className="text-reviewed">
                        {item?.inspectionCode}
                      </div>
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                }
                key="1"
                extra={genExtra}
              >
                <div className="document-container">
                  <IoDocumentTextOutline color="#1468BA" size={18} />
                  <div className="document-text"
                    onClick={() => showModalCompany()}>Download Certificate</div>
                </div>
                <div className="document-container"
                  onClick={() => { history.push(`/selectCompany/${true}`, item?.Vehicle?.id) }}>
                  <AiOutlineCar color="#1468BA" size={18} />
                  <div className="document-text">
                    Re-Inspect Car
                  </div>
                </div>
                <div className="document-container">
                  <AiOutlineCar color="#1468BA" size={18} />
                  <div className="document-text"
                    onClick={() => handleVehicleDetails(item)}>Car Details</div>
                </div>
              </Panel>
            </Collapse> */}
              {/* //     </div> */}
              {/* //   </div> */}
              {/* // </div> */}
            </>
          }
        </div>
      ) : draft === true ? (
        <div className="open-tab-container">
          <div className="open-tab-bg_color">
            <div className="tab-content-container">
              <div onClick={() => handleRedirect(item)} className="content-text">{title}</div>
              <div className="arrow-icon-container">
                <IoCloseOutline color="#FF0000" size={30} />
                <ProgressModal loadingDiscard={loadingDiscard} handleDiscardInspection={handleDiscardInspection} item={item} />
              </div>

            </div>
          </div>
        </div>
      ) : reviewed === true ? (
        <>
          {/* <div className=".open-tab-container">
            <div
              className="open-tab-bg_color"
              onClick={() => {
                showModalLittle();
                setReInspectionModal(false);
              }}
            >
              <div className="tab-content-container">
                <div className="content-text">{title}</div>
                <div className="arrow-icon-container">
                  <RiArrowRightSLine color="orange" size={30} />
                </div>
              </div>
            </div>
          </div> */}
          {/* <Modal
            style={{ width: '40px', height: '40px' }}
            title="Basic Modal"
            visible={isModalVisible}
            onOk={handleOkLittle}
            onCancel={() => {
              handleCancelLittle();
            }}
          >
            <div className="modal-content-container">
              <div></div>
              <div className="option-text">Options</div>
              <GrFormClose color="black" size={25} />
            </div>

            <div
              className="modal-links-container"
              onClick={() => {
                showModalCompany();
              }}
            >
              <IoDocumentTextOutline color="#1468BA" size={18} />
              <div className="modal-links-text">Download Certificate</div>
            </div>

            <div
              className="modal-links-container"
              onClick={() => {
                handleCancelLittle();
                // history.push(`/selectCompany/${true}`);
                // setReInspectionModal(true);
                // setReInspectionLisencePlateNumber(title);
                handleReInspectionId(item);
              }}
            >
              <AiOutlineCar color="#1468BA" size={18} />
              <Link href="#" className="modal-links-text">
                Re-Inspect Car
              </Link>
            </div>
            <div className="modal-links-container_noneborder" onClick={() => handleVehicleDetails(item)}>
              <AiOutlineCar color="#1468BA" size={18} />
              <Link className="modal-links-text">Car Details</Link>
            </div>
          </Modal> */}

          <div>
            <Collapse
              className="reviewed-content-container"
              defaultActiveKey={['0']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <UpCircleOutlined
                  style={
                    isActive
                      ? { fontSize: 33, color: '#FF7A00', marginTop: 0 }
                      : { fontSize: 33, color: 'lightGray', marginTop: 0 }
                  }
                  rotate={isActive ? 180 : 0}
                />
              )}
            >
              <Panel
                style={{ overflow: 'hidden' }}
                header={
                  <Row
                    gutter={40}
                    style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}
                  >
                    <Col>
                      <div className="text-reviewed">
                        {item?.inspectionCode}
                      </div>
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                }
                key="1"
                extra={genExtra}
              >
                <div className="document-container">
                  <IoDocumentTextOutline color="#1468BA" size={18} />
                  <div className="document-text"
                    onClick={() => { showModalCompany(); localStorage.setItem('tabStatus', 'REVIEWED') }}>Download Certificate</div>
                </div>
                <div className="document-container"
                  onClick={() => { history.push(`/selectCompany/${true}`, inspectionData); localStorage.setItem('tabStatus', 'REVIEWED') }}>
                  <AiOutlineCar color="#1468BA" size={18} />
                  <div className="document-text">
                    Re-Inspect Car
                  </div>
                </div>
                <div className="document-container">
                  <AiOutlineCar color="#1468BA" size={18} />
                  <div className="document-text"
                    onClick={() => { handleVehicleDetails(item); localStorage.setItem('tabStatus', 'REVIEWED') }}>Car Details</div>
                </div>
              </Panel>
            </Collapse>
          </div>

          <Modal
            style={{ width: '40px', height: '40px' }}
            title="Basic Modal"
            visible={companyModalVisible}
            onOk={handleOkCompany}
            onCancel={() => {
              handleCancelCompany();
              // handleCancelLittle();
              cancleReInspection();
            }}
          >
            <div className="companies-modal">
              <div className="modal-content-container">
                <div className="option-text">Companies</div>
                <GrFormClose color="black" size={25} />
              </div>
              {item?.CompanyInspections?.map((items) => {
                return (
                  <div className="modal-company-text " key={items?.id}>
                    <div className="modal-links-text">{items?.Company?.name}</div>
                    <input
                      className="checkbox-container"
                      type="radio"
                      name="name"
                      onClick={() => {
                        handleDownload(items, item?.id);
                        // handleCancelLittle();
                      }}
                    />
                    {/* <form>
                      <input type="radio" id="html" name="fav_language" value="HTML" />
                      <label for="html">HTML</label>
                    </form> */}
                  </div>
                );
              })}
              {company === true ? (
                certificateData?.templateId === 3 ? (
                  <>
                    {certificateData?.companyName === 'Uber' || certificateData?.companyName === 'uber' ? (
                      <>
                        <UberCertificate
                          SetModal={setCompanyModalVisible}
                          handleModal={handleCancelCompany}
                          setLoading={setCertificateLoading}
                          isLoading={certificateLoading}
                          companyId={certificateData?.companyId}
                          id={certificateData?.id}
                        />
                      </>
                    ) : item?.Vehicle?.registrationState?.toLowerCase() === 'arizona' && certificateData?.companyName?.toLowerCase() === 'lyft' ? (
                      <>
                        <LyftCertificateArizona
                          SetModal={setCompanyModalVisible}
                          handleModal={handleCancelCompany}
                          setLoading={setCertificateLoading}
                          isLoading={certificateLoading}
                          companyId={certificateData?.companyId}
                          id={certificateData?.id}
                        />
                      </>
                    ) : <Lyftcertificate
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setCertificateLoading}
                      isLoading={certificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />}
                  </>
                ) : certificateData?.templateId === 2 ? (
                  <>
                    {certificateData?.companyName === 'Uber' || certificateData?.companyName === 'uber' ? (
                      <>
                        <UberCertificate
                          SetModal={setCompanyModalVisible}
                          handleModal={handleCancelCompany}
                          setLoading={setCertificateLoading}
                          isLoading={certificateLoading}
                          companyId={certificateData?.companyId}
                          id={certificateData?.id}
                        />
                      </>
                    ) : (
                      <>
                        <DownloadCertifcate
                          SetModal={setCompanyModalVisible}
                          handleModal={handleCancelCompany}
                          setLoading={setCertificateLoading}
                          isLoading={certificateLoading}
                          companyId={certificateData?.companyId}
                          id={certificateData?.id}
                        />
                      </>
                    )}
                  </>
                ) : certificateData?.templateId.length ? (
                  <>
                    <DownloadCertifcate
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setCertificateLoading}
                      isLoading={certificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  </>
                ) : null
              ) : null}
            </div>
          </Modal>
        </>
      ) : (
        <>
          {inReview === true ? (
            <div className="open-tab-container">
              <div className="open-tab-bg_color">
                <div className="tab-content-container">
                  <div className="content-text">{title}</div>
                  <div className="arrow-icon-container-inreview">
                    <RiArrowRightSLine color="#FF7A00" size={30} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )
      }
    </div >
  );
};

export default TabContentComponent;
