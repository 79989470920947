import React, { useState } from "react";

import UberVehicleInspectionScreen from "../../Screens/UberVehicleInspectionScreen";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {RideshareScreen} from "../../Screens";

const RideSharePageContainer = (props) => {
     const history = useHistory();
    const { addToast } = useToasts();

     const [checkboxValue, setCheckBox] = useState(false);
    const handleCheckBox = () => {
        setCheckBox(!checkboxValue);
    };


    const handleSubmit = (params) => {
        const { register } = props;
        if (params?.phone.includes("_")) {
            addToast("Please enter a valid number", { appearance: "error" });
        } else {
            if (checkboxValue) {
                register(params, history, addToast);
            } else {
                addToast("Please accept term of use", { appearance: "warning" });
            }
        }
    };
    //
    const handleLoginRedirect = () => {
        window.location.href = "/login";
    }
    //
    const handleReDirect = () => {
        window.location.href = "/getStarted";
    };
    //
    const handleTermsOfUse = () => {
        window.location.href = "/termsAndPolicy?uber=true";
    };

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 650, itemsToShow: 1, itemsToScroll: 1 },
        { width: 768, itemsToShow: 2 },
        { width: 1200, itemsToShow: 2 },
    ];
    return (
        <RideshareScreen
             handleTermsOfUse={handleTermsOfUse}
             handleLoginRedirect={handleLoginRedirect}
            breakPoints={breakPoints}
             handleReDirect={handleReDirect}
             handleSubmit={handleSubmit}
             handleCheckBox={handleCheckBox}
             isLoading={props.isLoading}
        />
    );
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        isLoading: state.auth.isAuthLoading,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RideSharePageContainer);
