import React from 'react';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
// import TestingImage from '../../Assets/testing.png';
import { AiTwotoneHome } from 'react-icons/ai';
// import { FaHistory } from 'react-icons/fa';
// import { FaCar } from 'react-icons/fa';
import { MdCall } from 'react-icons/md';
import { GrCircleInformation } from 'react-icons/gr';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { IoCloseSharp } from 'react-icons/io5';
// import { useHistory } from 'react-router-dom';
import './style.css';

const SideBar = ({ drawerVisible, handleSideBar, showLogoutModal }) => {
  // const history = useHistory();

    // const referralCode =localStorage.getItem('referralCode')

    // const {drivingLicense}= JSON.parse(data)

    return (
    <Drawer placement={'left'} width={300} closable={false} onClose={() => handleSideBar(false)} visible={drawerVisible}>
      <div className="img-container">
        <div className="side-bar-close_container" >
          <IoCloseSharp size={28} color="white" onClick={() => handleSideBar(false)} />
        </div>
        <div className="menu-image">

          <p className="image-text">
            CHEX.<span style={{ color: '#FF7A00' }}>AI</span>
          </p>
        </div>
      </div>
      <Link to="/selectoption" className="icon-bar">
        <AiTwotoneHome />
        <div className="icon-text">Home</div>
      </Link>
      {/* <div className="icon-bar">
        <FaHistory />
        <div className="icon-text">History</div>
      </div> */}
      {/* <Link to="/VehicleDetail" className="icon-bar">
        <FaCar />
        <div className="icon-text">Vehicle Details</div>
      </Link> */}
      {/* <Link to="/vehicleinspection" className="icon-bar">
        <AiTwotoneHome />
        <div className="icon-text">Home</div>
      </Link> */}
      <Link to="/contactUs" className="icon-bar">
        <MdCall />
        <div className="icon-text">Contact us</div>
      </Link>
      <Link to="/inspectionInstruction" className="icon-bar">
        <GrCircleInformation />
        <div className="icon-text">How To Prepare</div>
      </Link>
      <div onClick={() => { handleSideBar(false); showLogoutModal(); }} className="icon-bar">
        <RiLogoutCircleRLine />
        <div className="icon-text">Logout</div>
      </div>
        {/* {referralCode? (
            <div className='bottom_text'>{referralCode}</div>
        ):(
            <div className='referral_text'>No Referral Code</div>
        )} */}

    </Drawer>
  );
};

export default SideBar;
