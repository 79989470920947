/*eslint-disable */

import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { IoClose } from 'react-icons/io5';
import ClipLoader from 'react-spinners/ClipLoader';
import './style.css';

const ProgressModal = ({ item, handleDiscardInspection, loadingDiscard }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <div onClick={showModal} className="arrow-icon-container">
                <IoClose color="#FF0000" size={35} />
            </div>
            <Modal className="progress-modal" title="Basic Modal" visible={isModalVisible} onOk={() =>setIsModalVisible(false)} onCancel={() => handleCancel()}>
                <div className="progress-modal-text">Are You Sure Want To Discard Your <div className="progress-inspection-text">Inspection?</div>
                </div>
                <div className="progress-modal-btn">
                    <div className="progress-modal-btn-yes" onClick={() => { handleDiscardInspection(item?.id)}}>
                        <a href="#" style={{ color: "white" }} >
                            {loadingDiscard ? <ClipLoader color={'white'} size={20} /> : 'Yes'}
                        </a>
                    </div>
                    <div className="progress-modal-btn-no" onClick={() => { handleCancel(); }}>
                        <a style={{ color: '#1468BA' }} href="#">
                            <b>No</b>
                        </a>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ProgressModal;
