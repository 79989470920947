/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../actions';
import Header from '../../Components/Header';


import './style.css';
import '../../App.css';

const SelectOptionScreen = () => {
  const history = useHistory();
  return (
    <div className="select-option-main-container">
      <div className="select-option-image-background-signin">
        <div className="select-option-main-bg-signin">
          <div className="select-option-header_container">
            <div className="select-option-header">
              <Header title={false} />
            </div>
          </div>
          <div className="select-option-content-container">
            <h1 className="select-option-heading-logo">
              CHEX
              <span className="logo-span-color">.AI</span>
            </h1>
            <div className="select-option-desp-heading">Virtual Inspections</div>
            
          </div>
          <div className="select-option-form-main-container">
            <div className="select-option-form-heading">Please select one option below:</div>
            <div className="select-option-tabs" tabIndex="1"
              onClick={() => { history.push(`/selectCompany/${false}`) }}>
              + New Inspection
            </div>

            <div className="select-option-tabs" tabIndex="1"
              onClick={() => { history.push(`/inspectioninprogress`) }}>
              Inspections In Progress
            </div>
            <div className="select-option-tabs" tabIndex="1"
              onClick={() => { history.push(`/inspectionsubmitted`) }}>
              Inspections Submitted
            </div>
            <div className="select-option-tabs" tabIndex="1"
              onClick={() => { history.push(`/vehiclecertificate`) }}>
              Certificates
            </div>
            {/* <div className="select-option-tabs" tabIndex="1"
                 onClick={() => { history.push(`/referral`) }}>
             Rewards
            </div> */}
          </div>

        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehiclesByStatus: state.vehicleInstruction.inspectionByStatus,
    companies: state?.auth?.companies?.data,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectOptionScreen);

