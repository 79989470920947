import createReducer from '../store/createReducer';
import * as types from '../utils/constants';

const initialState = {
  };
const blog = createReducer(initialState, {
  [types.GET_BlogData](state, action) {
    return {
      ...state,
      blogdata: action.payload,
    };
  },
  [types.GET_IndividualRecord](state,action){
    return{
      ...state,
      blog:action.payload,
    }
  }
 
});
export default blog;

