/* eslint-disable */

import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  browserName,
} from "react-device-detect";
// import imageCompression from 'browser-image-compression';
// import Compressor from 'compressorjs';
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  checkCustomerStatus,
  sendReuploadEmail,
  SaveVehicleInspectionDetail,
} from "../../actions/vehicleInspection";
import { moment } from "moment";
import ActionCreators from "../../actions";
import Loading from "../../HOC/index";
import { VehicleInspectionScreen } from "../../Screens";
import axios from "axios";
const queryString = require("query-string");

// const queryString = require('query-string');

const VehicleInspectionContainer = (props) => {
  const query = queryString.parse(location.search);
  const { match, stateCompanies } = props;

  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const webRef = useRef(null);
  const captureAreaRef = useRef(null);
  const [isModalVisible, setModalValue] = useState(false);
  const [isSurveyModalVisible, setSurveyModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [vehicleStatusLoading, setVehicleStatusLoading] = useState(false);
  const [uploadingPercentage, setuploadingPercentage] = useState(0);
  const [fileToBeDeleted, setDeletingFile] = useState(null);
  const [imageCategory, setImageCategory] = useState(null);
  const [rating, setNewRating] = useState(0);
  const [comment, setComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [surveyModalLoading, setSurveyModalLoading] = useState(false);
  const [payement_status, setpayementStatus] = useState(false);
  const [surveyChecks, setSurveyChecks] = useState({
    online_services: false,
    face_time: false,
    visit_shop: false,
  });
  const [vehicleInstructionValues, setVehicleInstruction] = useState(null);
  const [groupType, setGroupType] = useState(null);
  const [surveyCheck, setSurveyCheck] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [aiStatus, setAiStatus] = useState({});
  const [isImagemodal, setIsImageModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [compressedFile, setCompressedFile] = useState("");
  const [sendEmailData, setSendEmailData] = useState("");
  const [showOneTire, setShowOneTire] = useState(false);
  const [locationMap, setLocationMap] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });
  const [vinwebcam, setVinWebCam] = useState(false);
  const [WebCameraOpen, setWebCameraOpen] = useState(false);
  const [frontFacing, setFrontFacing] = useState(false);
  const [clickPic, setClickPic] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [saveImage, setSaveImage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isEditable, setIsEditable] = useState(false);
  const [vehicleDetailModal, setVehicleDetailModal] = useState(false);
  const [initialFieldValues, setInitialFieldValues] = useState({});
  const [loadingDetail, setloadingDetail] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [detailEnter, setDetailEnter] = useState(false);
  const [imageTire, setImageTire] = useState("");
  const [stream, setStream] = useState(null);
  const [recordTime, setRecordTime] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [vidStream, setVidStream] = useState(null);
  const [videomodal, setvideomodal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const videoRef = useRef(null);
  const chunksRef = useRef([]);
  const mediaRecorderRef = useRef(null);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [showSaveOptions, setShowSaveOptions] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState("");
  const [playingRecordedVideo, setPlayingRecordedVideo] = useState(false);
  const [isFrontFacing, setIsFrontFacing] = useState(false);
  const [hasBackCamera, setHasBackCamera] = useState(false);
  const [tipsModalVisible, setTipsModalVisible] = useState(false);
  const [recordingTips, setRecordingTips] = useState(true);
  const recordRTCRef = useRef(null);
  const playerRef = useRef(null);
  const recordedVideoUrlRef = useRef(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [showdata, setshowdata] = useState(true);
  const [showBlurBackground, setShowBlurBackground] = useState(false);
  const [showbackcolor, setshowbackcolor] = useState();
  const [islocation, setisLocation] = useState(false);
  // const [isMysteriousCompany, setIsMysteriousCompany] = useState(false);
  let today = new Date();
  const date = `${
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1
  }-${
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
  }-${today.getFullYear()}`;
  const dateImage = date.toString();

  useEffect(() => {
    // if (user?.updates || user?.updates === null || user?.updates === undefined) {
    //   window.location.replace('/logoutForChanges');
    // }
    handleRequests();
  }, []);
  useEffect(() => {
    let mobileAgent = window?.navigator?.userAgent;
    const { match } = props;
    axios
      .get(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/review/status/${match?.params?.id}`
      )
      .then((resp) => {
        setpayementStatus(resp?.data?.payment_status);
        setisLocation(resp?.data?.isLocation);

        if (
          resp?.data?.payment_status === true &&
          resp?.data?.isLocation === false
        ) {
          setShowOneTire(true);
          setModalValue(false);
        } else if (
          resp?.data?.payment_status === true &&
          resp?.data?.isLocation === true
        ) {
        } else {
          // if(props?.vehicleStatus?.hasTyreLocation===true)
          // {
          if ("geolocation" in navigator) {
            if (mobileAgent) {
              if (isMobile) {
                navigator.geolocation.getCurrentPosition(
                  function (position) {
                    setLocationMap({
                      loaded: true,
                      coordinates: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                      },
                    });
                  },
                  function (error) {
                    if (error.message === "The permission was revoked") {
                    } else {
                      setShowOneTire(true);
                      setModalValue(false);
                      // addToast(` ${error.message}`, { appearance: 'error' });
                    }
                  },
                  {
                    enableHighAccuracy: true,
                    timeout: 20000,
                  }
                );
              }
            }
          }
          // }
        }
      })
      .catch((err) => {});

    //   if(payement_status===true)
    //   {

    //   }
    //   else{
    //
    //   if(props?.vehicleStatus?.hasTyreLocation===true)
    //   {
    //     if ("geolocation" in navigator) {
    //       // Request geolocation permission
    //       // const requestGeolocationPermission = async () => {
    //       //   try {
    //       //     const position = await new Promise((resolve, reject) => {
    //       //       navigator.geolocation.getCurrentPosition(resolve, reject, {
    //       //         enableHighAccuracy: true,
    //       //         timeout: 20000,
    //       //       });
    //       //     });

    //       //     // Geolocation permission granted
    //       //     setLocationMap({
    //       //       loaded: true,
    //       //       coordinates: {
    //       //         lat: position.coords.latitude,
    //       //         lng: position.coords.longitude,
    //       //       },
    //       //     });
    //       //   } catch (error) {
    //       //     // Geolocation permission denied or error occurred
    //       //     // setLocationMap({
    //       //     //   loaded: false,
    //       //     //   coordinates: {
    //       //     //     lat: "permission denied",
    //       //     //     lng: "permission denied",
    //       //     //   },
    //       //     // });
    //       //     setShowOneTire(true);
    //       //     setModalValue(false);
    //       //     addToast('Geolocation permission denied or error occurred',error,{appearance:'error'})
    //       //   }
    //       // };
    //       // requestGeolocationPermission();
    //     //   navigator.geolocation.getCurrentPosition(
    //     //     function(position) {
    //     //
    //     //       // This function is called when the user allows geolocation and the position is successfully retrieved.
    //     //       var latitude = position.coords.latitude;
    //     //       var longitude = position.coords.longitude;

    //     //       // Your additional code based on the retrieved position goes here.
    //     //     },
    //     //     function(error) {
    //     //
    //     //       setShowOneTire(true);
    //     //       setModalValue(false);
    //     //       addToast('Geolocation not supported', error.message,{appearance:'error'})
    //     //     }
    //     //   );
    //     // } else {
    //     //   setShowOneTire(true);
    //     //   setModalValue(false);
    //     //   addToast('Geolocation not supported',{appearance:'error'})
    //     // }
    //   if(mobileAgent){
    //     if(isMobile){

    //       navigator.geolocation.getCurrentPosition(
    //         function(position) {

    //               setLocationMap({
    //             loaded: true,
    //             coordinates: {
    //               lat: position.coords.latitude,
    //               lng: position.coords.longitude,
    //             }
    //           });
    //           //  addToast(`${position.coords.latitude} || ${position.coords.longitude}`, { appearance: 'success' });
    //         },
    //         function(error) {

    //           // setLocationMap({
    //           //   loaded: false,
    //           //   coordinates: {
    //           //     lat: error?.code,
    //           //     lng: error?.message,
    //           //   }
    //           // });
    //           if(error.message==='The permission was revoked')
    //           {

    //           }
    //           else{
    //           setShowOneTire(true);
    //           setModalValue(false);
    //           addToast(` ${error.message}`, { appearance: 'error' });
    //           }
    //         }, {
    //           enableHighAccuracy: true
    //           ,timeout : 20000
    //         }

    //     );

    //        // Check if geolocation is supported
    // //   if ("geolocation" in navigator) {
    // //     // Request geolocation permission
    // //     // const requestGeolocationPermission = async () => {
    // //     //   try {
    // //     //     const position = await new Promise((resolve, reject) => {
    // //     //       navigator.geolocation.getCurrentPosition(resolve, reject, {
    // //     //         enableHighAccuracy: true,
    // //     //         timeout: 20000,
    // //     //       });
    // //     //     });

    // //     //     // Geolocation permission granted
    // //     //     setLocationMap({
    // //     //       loaded: true,
    // //     //       coordinates: {
    // //     //         lat: position.coords.latitude,
    // //     //         lng: position.coords.longitude,
    // //     //       },
    // //     //     });
    // //     //   } catch (error) {
    // //     //     // Geolocation permission denied or error occurred
    // //     //     // setLocationMap({
    // //     //     //   loaded: false,
    // //     //     //   coordinates: {
    // //     //     //     lat: "permission denied",
    // //     //     //     lng: "permission denied",
    // //     //     //   },
    // //     //     // });
    // //     //     setShowOneTire(true);
    // //     //     setModalValue(false);
    // //     //     addToast('Geolocation permission denied or error occurred',error,{appearance:'error'})
    // //     //   }
    // //     // };
    // //
    // //     // requestGeolocationPermission();
    // //     navigator.geolocation.getCurrentPosition(
    // //       function(position) {
    // //         // This function is called when the user allows geolocation and the position is successfully retrieved.
    // //         var latitude = position.coords.latitude;
    // //         var longitude = position.coords.longitude;

    // //         // Your additional code based on the retrieved position goes here.
    // //       },
    // //       function(error) {
    // //         // This function is called when there is an error in retrieving the position.
    // //         setShowOneTire(true);
    // //         setModalValue(false);
    // //         addToast('Geolocation not supported', error.message,{appearance:'error'})
    // //       }
    // //     );
    // //   } else {
    // //     // Geolocation not supported
    // //     // setLocationMap({
    // //     //   loaded: false,
    // //     //   coordinates: {
    // //     //     lat: "geolocation not supported",
    // //     //     lng: "geolocation not supported",
    // //     //   },
    // //     // });
    // //     setShowOneTire(true);
    // //     setModalValue(false);
    // //     addToast('Geolocation not supported',{appearance:'error'})
    // //   }

    //       }}
    //   }
    //   }
    // }
  }, []);
  // }, [props?.vehicleStatus?.hasTyreLocation]);
  const handleRequests = () => {
    const {
      getVehicleFile,
      currentUser,
      getSurveyStatus,
      getVehiclesStatus,
      match,
    } = props;
    // getSurveyStatus(currentUser?.id, setSurveyCheck);
    getVehicleFile(
      match?.params?.id,
      "",
      "",
      setSendEmailData,
      history,
      match?.params?.vehicleId
      // setIsMysteriousCompany
    );
    dispatch(
      checkCustomerStatus(match?.params?.id, history, setpayementStatus)
    );

    getVehiclesStatus(match?.params?.id, setLoading);
  };

  const handleModal = (value, groupType) => {
    setImageCategory(value.id);
    setGroupType(groupType);
    setVehicleInstruction(value);
    setModalValue(!isModalVisible);
  };

  const handleImageControl = (e) => {
    handleImageUpload(e, vehicleInstructionValues);
  };

  const handleModalClose = () => {
    setModalValue(false);
    var video = document.getElementById("myVideo");
    video?.pause();
    video?.load();
  };

  const changeRating = (newRating, name) => {
    setNewRating(newRating);
  };

  const handleImageDeny = () => {
    setIsImageModal(false);
    setAiStatus({});
  };

  const handleImageConfirmation = () => {
    setIsImageModal(false);
    handleImageFinalize(imageFile);
    setAiStatus({});
  };

  const handleVideoDeny = () => {
    setIsVideoModal(false);
    setVideoFile(null);
  };

  const handleVideoConfirmation = () => {
    setIsVideoModal(false);
    const { uploadFile, match } = props;
    uploadFile(
      videoFile,
      { type: videoFile.type },
      match?.params?.id,
      imageCategory,
      groupType,
      setModalValue,
      imageUploadingProgress,
      dateImage,
      setDisabled
    );
    setVideoFile(null);
  };

  //New code for Image Upload with Location

  const handleImageUpload = async (event, data) => {
    setLocationMap({
      loaded: false,
      coordinates: {
        lat: "",
        lng: "",
      },
    });

    let mobileAgent = window?.navigator?.userAgent;

    if (payement_status === false) {
      // if(props?.vehicleStatus?.hasTyreLocation===true)
      // {
      if (data?.groupType === "tires") {
        const imageFile = event.target.files[0];
        setImageTire(imageFile);

        if (!("geolocation" in navigator)) {
          setLocationMap({
            loaded: false,
            coordinates: {
              lat: "geo location not supported",
              lng: "geo location not supported",
            },
          });
        }

        if (mobileAgent) {
          if (isMobile) {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                setLocationMap({
                  loaded: true,
                  coordinates: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                });

                locationImageTireHandler(
                  position.coords.latitude,
                  position.coords.longitude,
                  imageFile
                );
                //  addToast(`${position.coords.latitude} || ${position.coords.longitude}`, { appearance: 'success' });
              },
              function (error) {
                setLocationMap({
                  loaded: false,
                  coordinates: {
                    lat: error?.code,
                    lng: error?.message,
                  },
                });
                if (error.message === "The permission was revoked") {
                  const imageFile = event.target.files[0];
                  event.target.value = "";
                  const { uploadFile, match } = props;

                  uploadFile(
                    imageFile,
                    { type: imageFile.type },
                    match?.params?.id,
                    imageCategory,
                    groupType,
                    setModalValue,
                    imageUploadingProgress,
                    dateImage,
                    setDisabled,
                    "",
                    "",
                    "",
                    setSendEmailData,
                    setVehicleDetailModal,
                    setInitialFieldValues,
                    setFetchError,
                    match?.params?.vehicleId
                  );

                  setuploadingPercentage(0);
                } else {
                  // locationImageTireHandler(error?.code, error?.message, imageFile );
                  setShowOneTire(true);
                  setModalValue(false);
                  // addToast(` ${error.message}`, { appearance: 'error' });
                }
              },
              {
                enableHighAccuracy: true,
                timeout: 20000,
              }
            );
          }

          // }
          else {
            //Code for laptop

            locationImageTireHandler("Laptop User", "Laptop User", imageFile);
            // setModalValue(false);
            // addToast(`Please turn on location services for the browser on your mobile device before taking tire photos. Thanks` , {appearance: 'warning'});
          }
        }
      } else {
        const imageFile = event.target.files[0];
        event.target.value = "";
        const { uploadFile, match } = props;

        uploadFile(
          imageFile,
          { type: imageFile.type },
          match?.params?.id,
          imageCategory,
          groupType,
          setModalValue,
          imageUploadingProgress,
          dateImage,
          setDisabled,
          "",
          "",
          "",
          setSendEmailData,
          setVehicleDetailModal,
          setInitialFieldValues,
          setFetchError,
          match?.params?.vehicleId
        );

        setuploadingPercentage(0);
      }

      // }
      // else{
      //   const imageFile = event.target.files[0];

      //   event.target.value = '';
      //   const {uploadFile, match} = props;

      //   uploadFile(imageFile, {type: imageFile.type}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled,'','','', setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError,match?.params?.vehicleId);
      //   setuploadingPercentage(0);
      //   }
    } else if (islocation === true && payement_status === true) {
      const imageFile = event.target.files[0];

      event.target.value = "";

      const { uploadFile, match } = props;

      uploadFile(
        imageFile,
        { type: imageFile.type },
        match?.params?.id,
        imageCategory,
        groupType,
        setModalValue,
        imageUploadingProgress,
        dateImage,
        setDisabled,
        "",
        "",
        "",
        setSendEmailData,
        setVehicleDetailModal,
        setInitialFieldValues,
        setFetchError,
        match?.params?.vehicleId
      );
      setuploadingPercentage(0);
    } else if (islocation === false && payement_status === true) {
      // setShowOneTire(true);
      const imageFile = event.target.files[0];

      event.target.value = "";

      const { uploadFile, match } = props;

      uploadFile(
        imageFile,
        { type: imageFile.type },
        match?.params?.id,
        imageCategory,
        groupType,
        setModalValue,
        imageUploadingProgress,
        dateImage,
        setDisabled,
        "",
        "",
        "",
        setSendEmailData,
        setVehicleDetailModal,
        setInitialFieldValues,
        setFetchError,
        match?.params?.vehicleId
      );
      setuploadingPercentage(0);

      // setModalValue(false);
    } else {
      const imageFile = event.target.files[0];

      event.target.value = "";

      const { uploadFile, match } = props;

      uploadFile(
        imageFile,
        { type: imageFile.type },
        match?.params?.id,
        imageCategory,
        groupType,
        setModalValue,
        imageUploadingProgress,
        dateImage,
        setDisabled,
        "",
        "",
        "",
        setSendEmailData,
        setVehicleDetailModal,
        setInitialFieldValues,
        setFetchError,
        match?.params?.vehicleId
      );
      setuploadingPercentage(0);
    }
  };

  // const uploadFile = (imageFile) => {

  //   const { uploadFile, match } = props; // Assuming these come from props

  //   uploadFile(
  //     imageFile,
  //     { type: imageFile.type },
  //     match?.params?.id,
  //     imageCategory,
  //     groupType,
  //     setModalValue,
  //     imageUploadingProgress,
  //     dateImage,
  //     setDisabled,
  //     "",
  //     "",
  //     setSendEmailData,
  //     setVehicleDetailModal,
  //     setInitialFieldValues,
  //     setFetchError,
  //     match?.params?.vehicleId
  //   );

  //   // Reset the uploading percentage after the upload
  //   setuploadingPercentage(0);
  // };
  // const handleImageUpload = async (event, data) => {

  //   setLocationMap({
  //     loaded: false,
  //     coordinates: {
  //       lat: "",
  //       lng: "",
  //     },
  //   });

  //   let mobileAgent = window?.navigator?.userAgent;

  //   const imageFile = event.target.files[0]; // Get the image file
  //   event.target.value = "";  // Clear input value

  //   if (payement_status === false) {
  //     if (data?.groupType === "tires") {
  //       setImageTire(imageFile);

  //       if (!("geolocation" in navigator)) {
  //         setLocationMap({
  //           loaded: false,
  //           coordinates: {
  //             lat: "geo location not supported",
  //             lng: "geo location not supported",
  //           },
  //         });
  //       }

  //       if (mobileAgent && isMobile) {
  //         navigator.geolocation.getCurrentPosition(
  //           function (position) {
  //             setLocationMap({
  //               loaded: true,
  //               coordinates: {
  //                 lat: position.coords.latitude,
  //                 lng: position.coords.longitude,
  //               },
  //             });

  //             locationImageTireHandler(
  //               position.coords.latitude,
  //               position.coords.longitude,
  //               imageFile
  //             );
  //           },
  //           function (error) {
  //             setLocationMap({
  //               loaded: false,
  //               coordinates: {
  //                 lat: error?.code,
  //                 lng: error?.message,
  //               },
  //             });

  //             if (error.message === "The permission was revoked") {
  //               // Upload file without location
  //               uploadFile(imageFile);
  //             } else {
  //               setShowOneTire(true);
  //               setModalValue(false);
  //             }
  //           },
  //           {
  //             enableHighAccuracy: true,
  //             timeout: 20000,
  //           }
  //         );
  //       } else {
  //         // Upload for non-mobile (desktop/laptop)
  //         locationImageTireHandler("Laptop User", "Laptop User", imageFile);
  //       }
  //     } else {
  //       // For non-tire group type, upload without location
  //       uploadFile(imageFile);
  //     }
  //   } else if (islocation === true && payement_status === true) {
  //     // Location is enabled, proceed with regular upload
  //     uploadFile(imageFile);
  //   } else if (islocation === false && payement_status === true) {
  //     // Location is off, but payment status is true
  //     setShowOneTire(true);
  //     setModalValue(false);
  //     uploadFile(imageFile); // Upload without location
  //   } else {
  //     uploadFile(imageFile); // Default case to upload without location
  //   }
  // };
  const locationImageTireHandler = (lat, lng, imageFile) => {
    if (lat === "" && lng === "") {
      setModalValue(false);

      addToast(`Something Went Wrong. Try Again!!!`, { appearance: "error" });
    } else {
      const { uploadFile, match } = props;

      uploadFile(
        imageFile,
        { type: imageFile?.type },
        match?.params?.id,
        imageCategory,
        groupType,
        setModalValue,
        imageUploadingProgress,
        dateImage,
        setDisabled,
        lat,
        lng,
        addToast,
        setSendEmailData,
        setVehicleDetailModal,
        setInitialFieldValues,
        setFetchError,
        match?.params?.vehicleId
      );
      setuploadingPercentage(0);
    }
  };

  //Old Compression image code which cut image below
  // const handleImageUpload = async (event) => {
  //   var imageFile = event.target.files[0];
  //   event.target.value = '';
  //   var options = {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //     useWebWorker: true,
  //   };
  //   await imageCompression(imageFile, options)
  //     .then(function (compressedFile) {
  //       const { uploadFile, match } = props;
  //
  //       uploadFile(compressedFile, { type: compressedFile.type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled);
  //     })
  //     .catch(function (error) { });
  //   setuploadingPercentage(0);
  // };

  const handleVideoUpload = async (event) => {
    var videoFile = event.target.files[0];
    event.target.value = "";

    const { uploadFile, match } = props;
    uploadFile(
      videoFile,
      { type: videoFile.type },
      match?.params?.id,
      imageCategory,
      groupType,
      setModalValue,
      imageUploadingProgress,
      dateImage,
      setDisabled,
      "",
      "",
      "",
      setSendEmailData
    );
    setuploadingPercentage(0);
  };

  // const handleVideoUpload = async (event) => {
  //   var videoFile = event.target.files[0];
  //   event.target.value = '';

  //   const { uploadFile, match } = props;
  //   uploadFile(videoFile, { type: videoFile.type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled, '', '', '', setSendEmailData);
  //   setuploadingPercentage(0);
  // };

  // const handleImageUpload = async (event) => {
  //   setImageFile(event.target.files[0]);
  //   let formData = new FormData;
  //   formData.append('file',event.target.files[0]);
  //   const { uploadToAi } = props;
  //   await uploadToAi(formData, imageCategory, setAiLoading, setAiStatus, setIsImageModal, handleImageFinalize, event.target.files[0]);
  //   event.target.value = '';
  //   };

  //   const handleImageFinalize = async (imageFile) => {
  //     var options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 1920,
  //       useWebWorker: true,
  //     };
  //     await imageCompression(imageFile, options)
  //     .then(function (compressedFile) {
  //       const { uploadFile, match } = props;

  //       uploadFile(compressedFile, { type: compressedFile.type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled);
  //     })
  //     .catch(function (error) { });
  //     setuploadingPercentage(0);
  //     setImageFile(null)
  //   }

  // const handleVideoUpload = async (event) => {
  //   setVideoFile(event.target.files[0]);
  //   let formData = new FormData;
  //   formData.append('file',event.target.files[0]);
  //   const { uploadVideoToAi, match } = props;
  //   uploadVideoToAi(formData, setIsVideoModal, setAiLoading, event.target.files[0], { type: event.target.files[0].type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled)
  //   setuploadingPercentage(0);
  //   event.target.value = '';
  // };

  const handleSurveyModal = (value) => {
    if (surveyCheck) {
      setSurveyModal(value);
    } else {
      history.push("/transactionScreen");
    }
  };

  const handleCheckBox = (name, e) => {
    let obj = {
      ...surveyChecks,
      [name]: e.target.checked,
    };
    setSurveyChecks(obj);
  };

  const handleComment = (e) => {
    setCommentError(false);
    setComment(e.target.value);
  };

  const handleSubmitSurvey = async () => {
    const { submitSurvey } = props;
    if (comment) {
      const params = {
        ...surveyChecks,
        comment: comment,
        rating: rating,
      };
      await submitSurvey(
        params,
        addToast,
        setSurveyModal,
        setSurveyModalLoading,
        history
      );
    } else {
      setCommentError(true);
    }
  };

  const deleteFile = () => {
    const { deleteVehicleFile } = props;
    deleteVehicleFile(
      fileToBeDeleted?.id,
      fileToBeDeleted?.groupType,
      setDeleteLoading,
      setDeleteModal,
      addToast
    );
  };

  const handleDeleteModal = (groupType, id) => {
    setDeletingFile({ groupType, id });
    setDeleteModal(!isDeleteModal);
  };

  const imageUploadingProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor(loaded * 100) / total;
    setuploadingPercentage(percent);
  };
  const handleChangeVehicleStatus = () => {
    const { changeVehicleStatus, match } = props;
    if (sendEmailData === "Yes") {
      dispatch(
        sendReuploadEmail(
          match?.params?.id,
          match?.params?.vehicleId,
          setVehicleStatusLoading,
          history,
          addToast
        )
      );
    } else {
      changeVehicleStatus(
        match?.params?.id,
        match?.params?.vehicleId,
        setVehicleStatusLoading,
        history
      );
    }
  };
  const handleSkipPayment = (paymentStatus) => {
    const { match, skipPaymentMethod } = props;
    skipPaymentMethod(
      match?.params?.id,
      match?.params?.vehicleId,
      setVehicleStatusLoading,
      history,
      paymentStatus
    );
  };
  const imageConstraints = frontFacing
    ? { facingMode: "user" }
    : { facingMode: { exact: "environment" } };
  const handleImageControlvin = () => {
    setVinWebCam(true);
    setClickPic(true);
    setCapturedImage(null);
    setSaveImage(false);
  };
  const handlecameraOpen = () => {
    setWebCameraOpen(true);
  };
  const handleCamerarotation = () => {
    setFrontFacing((prevIsBackCameraOpen) => !prevIsBackCameraOpen);
  };

  const handleSubmitInspection = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/review/status/${match?.params?.id}`
      );

      if (resp?.data?.vehicleDetail?.vin === null) {
        setVehicleDetailModal(true);
        setDetailEnter(true);
      } else {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DEVELOPMENT_URL}/insp/comp/${match?.params?.id}`
          );
          const bodyString = JSON.stringify(response.data);
          localStorage.setItem("inspectionData", bodyString);

          if (response.data.companies.includes(7)) {
            localStorage.setItem("isUberComapany", true);
          } else {
            localStorage.setItem("isUberComapany", false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        const storedBodyString = localStorage.getItem("inspectionData");
        const storedBody = JSON.parse(storedBodyString);

        if (storedBody.companies && storedBody.companies.includes(7)) {
          history.push(
            `/confirmDetail/${match?.params?.id}/${match?.params?.vehicleId}`
          );
        } else {
          history.push(
            `/checkoutScreen/${match?.params?.id}/${match?.params?.vehicleId}`
          );
        }
      }
    } catch (err) {
      console.error("Error fetching vehicle review status:", err);
    }
  };

  const handleClickPicture = () => {
    const video = webRef.current.video;
    const captureCanvas = document.createElement("canvas");
    captureCanvas.width = video.videoWidth;
    captureCanvas.height = video.videoHeight;
    const ctx = captureCanvas.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(video, 0, 0, captureCanvas.width, captureCanvas.height);

    const desiredWidth = 374;
    const desiredHeight = 100;
    const aspectRatio = desiredWidth / desiredHeight;
    let targetWidth = captureCanvas.width;
    let targetHeight = captureCanvas.height;

    if (targetWidth / targetHeight > aspectRatio) {
      targetWidth = targetHeight * aspectRatio;
    } else {
      targetHeight = targetWidth / aspectRatio;
    }
    const cropX = (captureCanvas.width - targetWidth) / 2;
    const cropY = (captureCanvas.height - targetHeight) / 2;

    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = desiredWidth;
    croppedCanvas.height = desiredHeight;
    const croppedCtx = croppedCanvas.getContext("2d");
    croppedCtx.drawImage(
      captureCanvas,
      cropX,
      cropY,
      targetWidth,
      targetHeight,
      0,
      0,
      desiredWidth,
      desiredHeight
    );
    const croppedImage = croppedCanvas.toDataURL("image/png", 0.9);
    setCapturedImage(croppedImage);
    setVinWebCam(false);
    setClickPic(false);
    setSaveImage(true);
  };
  const handleSaveImage = () => {
    const { uploadFile, match } = props;
    const imageData = Buffer.from(
      capturedImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    setSaveImage(false);
    setCapturedImage(null);
    uploadFile(
      imageData,
      { type: "image/png" },
      match?.params?.id,
      imageCategory,
      groupType,
      setModalValue,
      imageUploadingProgress,
      dateImage,
      setDisabled,
      "",
      "",
      addToast,
      setSendEmailData,
      setVehicleDetailModal,
      setInitialFieldValues,
      setFetchError,
      match?.params?.vehicleId
    );
  };
  const handleEdit = () => {
    setIsEditable(true);
  };
  const handleFormSubmit = (values, form) => {
    if (!values.vin || !values.make || !values.model || !values.year) {
      addToast("Please ensure that every field is properly filled out.", {
        appearance: "error",
      });
    } else {
      const { match } = props;
      dispatch(
        SaveVehicleInspectionDetail(
          values,
          match?.params?.id,
          setVehicleDetailModal,
          setloadingDetail,
          addToast,
          setFetchError
        )
      );
    }
  };

  const handleTips = () => {
    setTipsModalVisible(true);
    setRecordingTips(false);
    // setTipsModalVisible(false)
  };
  const handleWebcamOpen = () => {
    setCameraOpen(true);
  };
  const handleshowdata = () => {
    setshowdata((prevIsshow) => !prevIsshow);
  };

  const handleVideoUploaded = (event) => {
    var videoFile = event.target.files[0];
    event.target.value = "";

    const { uploadFile, match } = props;
    setvideomodal(false);
    uploadFile(
      videoFile,
      { type: videoFile.type },
      match?.params?.id,
      imageCategory,
      groupType,
      setModalValue,
      imageUploadingProgress,
      dateImage,
      setDisabled,
      "",
      "",
      "",
      setSendEmailData
    );
    setuploadingPercentage(0);
  };

  //   const handleSaveRecording = () => {
  //
  //     if(stream){
  //
  //       setPlayingRecordedVideo(false)
  //       setShowSave(false)
  //       stream.getTracks().forEach(function (track) {

  //         track.stop();
  //     })
  //   }

  //       setVidStream(null);
  //       setIsRecording(false);

  //     // saveRecordingToDB(recordedVideoUrl);
  // saveRecordingToDB(recordedBlob)
  //     // setRecordedBlob(null);
  //     setShowSaveOptions(false);
  //   };
  //   const saveRecordingToDB = (recordedBlob) => {

  //     const videoFile = recordedBlob;
  //     const videoType = recordedBlob.type;

  //
  //     const { uploadFile, match } = props;
  //     setvideomodal(false)
  //     uploadFile(videoFile, { type: videoType }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled, '', '', '', setSendEmailData);
  //     setuploadingPercentage(0);
  //   };

  // const handlecameraRotation=()=>
  // {
  //
  //   setShowBlurBackground(true);
  //   setTimeout(() => {
  //    setShowBlurBackground(false);
  //  }, 2500);
  //   setIsFrontFacing((prevIsBackCameraOpen) => !prevIsBackCameraOpen);
  //   // setCameraOpen(false);
  // }
  return (
    <VehicleInspectionScreen
      rating={rating}
      isDisabled={isDisabled}
      vehicleInstructionValues={vehicleInstructionValues}
      vehicleInstructions={props.vehicleInstructions}
      isModalVisible={isModalVisible}
      isSurveyModalVisible={isSurveyModalVisible}
      isLoading={props.isLoading}
      isDeleteModal={isDeleteModal}
      deleteLoading={deleteLoading}
      surveyModalLoading={surveyModalLoading}
      uploadingPercentage={uploadingPercentage}
      currentUser={props.currentUser}
      handleImageUpload={handleImageUpload}
      handleModal={handleModal}
      deleteFile={deleteFile}
      handleDeleteModal={handleDeleteModal}
      handleModalClose={handleModalClose}
      handleSurveyModal={handleSurveyModal}
      changeRating={changeRating}
      handleCheckBox={handleCheckBox}
      handleComment={handleComment}
      setDeleteModal={setDeleteModal}
      handleSubmitSurvey={handleSubmitSurvey}
      commentError={commentError}
      paymentStatus={props.paymentStatus}
      match={props.match.params}
      changeVehicleStatus={handleChangeVehicleStatus}
      vehicleStatusLoading={vehicleStatusLoading}
      handleSkipPayment={handleSkipPayment}
      vehicleStatus={props?.vehicleStatus}
      lyftUser={query?.lyftUser}
      aiStatus={aiStatus}
      modal={isImagemodal}
      aiLoading={aiLoading}
      handleImageDeny={handleImageDeny}
      handleImageConfirmation={handleImageConfirmation}
      isVideoModal={isVideoModal}
      handleVideoDeny={handleVideoDeny}
      handleVideoConfirmation={handleVideoConfirmation}
      handleImageControl={handleImageControl}
      locationMap={locationMap}
      showOneTire={showOneTire}
      isMobile={isMobile}
      handleImageControlvin={handleImageControlvin}
      vinwebcam={vinwebcam}
      webRef={webRef}
      handlecameraOpen={handlecameraOpen}
      handleCamerarotation={handleCamerarotation}
      imageConstraints={imageConstraints}
      clickPic={clickPic}
      WebCameraOpen={WebCameraOpen}
      handleClickPicture={handleClickPicture}
      capturedImage={capturedImage}
      captureAreaRef={captureAreaRef}
      saveImage={saveImage}
      handleSaveImage={handleSaveImage}
      isEditable={isEditable}
      handleEdit={handleEdit}
      vehicleDetailModal={vehicleDetailModal}
      initialFieldValues={initialFieldValues}
      setVehicleDetailModal={setVehicleDetailModal}
      handleFormSubmit={handleFormSubmit}
      loadingDetail={loadingDetail}
      fetchError={fetchError}
      handleSubmitInspection={handleSubmitInspection}
      detailEnter={detailEnter}
      isRecording={isRecording}
      handleVideoUploaded={handleVideoUploaded}
      showPreview={showPreview}
      handleVideoUpload={handleVideoUpload}
      setShowPreview={setShowPreview}
      recordedChunks={recordedChunks}
      setRecordedChunks={setRecordedChunks}
      videoRef={videoRef}
      chunksRef={chunksRef}
      showSaveOptions={showSaveOptions}
      setIsFrontFacing={setIsFrontFacing}
      hasBackCamera={hasBackCamera}
      setTipsModalVisible={setTipsModalVisible}
      tipsModalVisible={tipsModalVisible}
      recordingTips={recordingTips}
      setRecordingTips={setRecordingTips}
      handleTips={handleTips}
      recordedVideoUrlRef={recordedVideoUrlRef}
      handleWebcamOpen={handleWebcamOpen}
      cameraOpen={cameraOpen}
      handleshowdata={handleshowdata}
      showdata={showdata}
      showBlurBackground={showBlurBackground}
      videomodal={videomodal}
      isMysteriousCompany={props?.isMysteriousCompany}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehicleData: state.auth.vehicleData,
    isLoading: state.vehicleInstruction.isVehicleLoading,
    isMysteriousCompany: state.vehicleInstruction.isMysteriousCompany,
    vehicleInstructions: state.vehicleInstruction,
    currentUser: state.auth.currentUser,
    vehicleStatus: state.vehicleInstruction.vehicleStatus,
    stateCompanies: state?.auth?.stateCompanies?.data,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading(VehicleInspectionContainer));
