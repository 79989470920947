import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

export const HowCanWeHelpSection = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="how-can-we-help-section">
      <section className="how-can-we-help-container">
        <div className="how-can-we-help-text">
          <div className="how-can-we-help-heading">
            How can we help your business?{" "}
          </div>
          <span className="how-can-we-help-paragraph">
            We can help your business optimize asset inspection process. Our
            interactive dashboard allows you to review data in real time and
            provide feedback to your customers instantaneously!
          </span>
          <div className="how-can-we-help-button">
            <Link
              to="/contactPage"
              className="contact-us-button"
              onClick={handleScrollToTop}
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="how-can-we-help-image">
          <img
            src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240534/analytics-image_dxh5nd.png"
            alt="Analytics"
            className="analytics-image"
          />
        </div>
      </section>
    </div>
  );
};
