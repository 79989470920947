/* eslint-disable */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../actions';


const PublicRoute = ({ allowed, ...params }) => {
  return <Route {...params} />;
  // <Redirect push to={`/selectoption`} />
  // return !allowed ? <Route {...params} /> : <Redirect push to={`/vehicleinspection`} />;
};

// export default PublicRoute;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
