/* eslint-disable */

import React from 'react';
import './style.css';
import '../../App.css';
// import { UpCircleOutlined } from '@ant-design/icons';
import Moment from 'react-moment';

const ReadyForReviewTileComponent = ({item}) => {
    return (
      
      <div style={{display:'flex',justifyContent:'center'}}>
      <div className="in-review-tile">
       
         
        
        <div className="in-review-tile-content">
          <div>
            <div className="in-review-tracking-id-text">
              License Plate No.
              {/* Status */}
            </div>
            <div className="in-review-tracking-id">
              {item?.Vehicle?.licensePlateNumber}
              {/* {"In-Review"} */}
            </div>

          </div>
          <div>
            <div className="in-review-date-created">
              Date Created
            </div>
            <div className="in-review-date">
            <Moment format="MM/DD/YYYY">
                {item?.createdAt}
                </Moment>
            </div>
            <div className="submitted-tile-tag-ready-review">Ready Review</div>
            
          </div>
        </div>
        {/* <UpCircleOutlined
                        style={{ fontSize: 28, color: '#FF7A00',position:'absolute',top:'35px',right:'20px'} } /> */}
      </div>
       </div>
       
    );

}

export default ReadyForReviewTileComponent;