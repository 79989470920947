import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Box, Container, Typography} from "@material-ui/core";
import iconValidate from "../../Assets/validateimg.png";
import {ClipLoader} from "react-spinners";

const ValidatePromoTokenScreen = ({promoLoading}) => {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <HelmetProvider>
            <Helmet>
                <title>Validate Token</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.paper",
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center",
                    minHeight: "100%",
                    px: 1,
                    py: mobileDevice ? "160px" : "60px" ,
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant={mobileDevice ? "h5" : "h3"}
                    >
                        Please wait to access the application
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {promoLoading ? (
                            <ClipLoader
                                size={60} color= '#1468BA' />
                        ): null}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 0,
                        }}
                    >
                        <Box
                            alt="Under development"
                            component="img"
                            src={iconValidate}
                            sx={{
                                height: "auto",
                                maxWidth: "100%",
                                width: 400,
                            }}
                        />
                    </Box>
                </Container>
            </Box>
        </HelmetProvider>
    );
}

export default ValidatePromoTokenScreen;
