import React from 'react';
import { CardExpiryElement, CardNumberElement, CardCvcElement } from '@stripe/react-stripe-js';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';


import {Button} from '../../Components';

import './style.css';
import '../../App.css';
import {IoIosArrowBack } from "react-icons/io";
import {Link} from "react-router-dom";

const TransactionScreen = ({ priceLoading,history, handleSubmit, loading, price, buttonDisable, inspectionId, vehicleId }) => {
  return (
    <div className="trans-container">

      <div className="transcation-container">
        <div className="arrows_top_header">
          <div className="arrows_style">
            {/*<Link to="/selectOption">*/}
            <div
                onClick={()=>history.push(`/checkoutScreen/${inspectionId}/${vehicleId}`)}
            >
              <IoIosArrowBack size={42} color="white" />
            </div>
              {/*<IoIosArrowBack size={42} color="white" />*/}
            {/*</Link>*/}
          </div>
          <p className="chex_wrapper_main_text">
            CHEX.<span style={{ color: '#FF7A00' }}>AI</span>
          </p>
        </div>
        {/*<div className="transaction-screen-header" style={{ justifyContent: 'center' }}>*/}
        {/*  /!* <Link style={{ color: 'white' }} to="/vehicleinspection">*/}
        {/*    <IoIosArrowBack size={32} color="white" />*/}
        {/*  </Link> *!/*/}
        {/*  /!* <div className="header-text">Payment</div> *!/*/}
        {/*  <div></div>*/}
        {/*</div>*/}


        <div className="transaction-form-container">
          <div className="transaction-input-field">
            <div>
              <p className="transaction-payment-heading">
                Payment
              </p>
            </div>
            <div className="transaction-payment">
              {priceLoading ? (
                  <div>Loading..</div>
              ) : price ? (
                  <>
                    {/*${price}*/}
                    <span style={{marginRight:-9}}>
                    ${`${price}`.split('.')[0]}
                      </span>
                    <span className="transaction-amount"> .{`${price}`.split('.')[1]}</span>
                  </>
              ) : (
                  <>
                    19.<span className="transaction-amount">99</span>
                  </>
              )}

            </div>
            <p className="amount-detail-style">
              Amount to be paid
            </p>
            <div>
              <hr />
            </div>
          </div>
            <div className="input-field-name">Card number</div>
            <CardNumberElement
                className="inputtransaction"
              // onChange={(e) => setFields(e.empty)}
              options={{
                style: {
                  base: {
                    backgroundColor: '#fff',
                    fontSize: '20px',
                    ':disabled': {
                      color: '#2e4860',
                    },
                    color: '#1F3B55',
                    '::placeholder': {
                      color: '#486288',
                    },
                  },

                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />

          <div className="transaction-input-field">
            <div className="input-field-name">Cardholder Name</div>
            <input className="cardholder-input_main" placeholder="Full name" />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 0 }}>
            <div style={{ width: '42%' }}>
              <div className="transaction-input-field">
                <div className="input-field-name">Expire Date</div>
                <CardExpiryElement
                    className="inputtransaction"
                  // onChange={(e) => setFields(e.empty)}
                  options={{
                    style: {
                      base: {
                        fontSize: '20px',
                        backgroundColor: '#fff',
                        color: '#1F3B55',
                        '::placeholder': {
                          color: '#486288',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div style={{ width: '54%' }}>
              <div className="transaction-input-field">
                <div className="input-field-name">CVC</div>
                <CardCvcElement
                    className="inputtransaction"
                  // onChange={(e) => setFields(e.empty)}
                  options={{
                    style: {
                      base: {
                        fontSize: '20px',
                        backgroundColor: '#fff',
                        color: '#1F3B55',
                        '::placeholder': {
                          color: '#486288',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          {/* <Link style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }} to="/vehicleinspection">
            go back to inspections page
          </Link> */}
          <div style={{marginTop: 50}}>
          <div style={{display:'flex', justifyContent:'center'}}>
            <AiOutlineSafetyCertificate size={22} color="#1262B1" />
            <p style={{marginLeft:4}}>
              Your payment information is safe with us
            </p>
          </div>
          <Button loading={loading} style={{ marginTop: 20 }} title={'Confirm'} disabled={buttonDisable} onClickButton={handleSubmit} />
          <div></div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default TransactionScreen;
