/*eslint-disable */

import React from 'react';
import { Modal } from 'antd';
import ClipLoader from 'react-spinners/ClipLoader';
import './style.css';

const LogoutModal = ({ logout, modal, setModal, loading, handleSubmit, setLoading, errorMessage, handleDeny }) => {

    return (
        <div>
            <Modal className="logout-modal" title="Basic Modal" mask={true} visible={modal} onOk={() => setModal(false)} onCancel={() => setModal(false)}>
                {logout ? (
                    <>
                        <div className="logout-modal-text">Are You Sure You Want To Log Out?
                        </div>
                        <div className="logout-inspection-text">Your Inspection Will Be Saved And Can Be Accessed In “Inspections In Progress’ Section After Logging Back In.</div>
                    </>
                ) : (
                    <>
                        <div className="logout-modal-text">{errorMessage}
                        </div>
                    </>
                )}
                <div className="logout-modal-btn">
                    <div className="logout-modal-btn-yes" onClick={() => { handleSubmit() }}>
                        <a href="#" style={{ color: "white" }} onClick={() => { setLoading(true) }}>
                            {loading ? <ClipLoader color={'white'} size={20} /> : 'Yes'}
                        </a>
                    </div>
                    {logout ? (
                        <div className="logout-modal-btn-no" onClick={() => { setModal(false); }}>
                            <a style={{ color: '#1468BA' }} href="#">
                                <b>No</b>
                            </a>
                        </div>
                    ) : (
                        <div className="logout-modal-btn-no" onClick={() => { handleDeny(); }}>
                            <a style={{ color: '#1468BA' }} href="#">
                            No
                            </a>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default LogoutModal;
