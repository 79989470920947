import React, { useState,useEffect } from "react";

import UberVehicleInspectionScreen from "../../Screens/UberVehicleInspectionScreen";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {BlogDetailScreen} from "../../Screens";
import { useDispatch, useSelector } from "react-redux";
import { getindividualBlogDetails } from "../../actions/blog";

const BlogDetailContainer = (props) => {
    const id=props?.match?.params.id

    const dispatch = useDispatch();
const [loading, setLoading] = useState(false);
const [page, setPage] = useState(1);
const [pageSize, setPageSize] = useState(10);
const [content, setContent] = useState('');
    const history = useHistory();
    const { addToast } = useToasts();
    
  const [showFullContent, setShowFullContent] = useState(false);

    useEffect(() => {
        dispatch(getindividualBlogDetails(setLoading,id));
      }, []);
   
   
    return (
        <BlogDetailScreen
            blogdata={props?.blogdata?.data?.data}
            showFullContent={showFullContent}
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            loading={loading}
            history={history}
            // toggleContent={toggleContent}
            // content={content}
            // pagination={props?.blogdata?.data?.data?.meta && props?.blogdata?.data?.data?.meta}
        />
    );
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        blogdata: state.blog.blog,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlogDetailContainer);
