/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import ActionCreators from '../../actions';
import { UnSubscribeScreen } from '../../Screens';

const queryString = require('query-string');

const UnSubscribeContainer = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { UnSubscribeUser } = props;

  const query = queryString.parse(location.search);
  useEffect(() => {
    handleUnSubscribe(query?.id);
  },[]);
  const handleUnSubscribe = (id) => {
    UnSubscribeUser(id, setLoading, addToast, setError);
  };

  return <UnSubscribeScreen loading={loading} error={error} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(UnSubscribeContainer);