import React, {useState} from "react";
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/all";
import './style.css'
const FaqsComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return(
        <div className={`faqsWrapper ${isOpen ? 'open' : ''}`} >
            <div className='questionStyleWrapper' onClick={()=> setIsOpen(!isOpen)}>
                <div className='faqsQuestionStyle' >
                    {props.question}
                </div>
                <div className='faqsQuestionStyle'>
                    {isOpen ?
                        (
                            <TiArrowSortedUp size={24} color="#000" />
                        ) :
                        (
                            <TiArrowSortedDown size={24} color="#000" />

                        )}

                </div>
            </div>

            { isOpen &&
            <div className={`answer`}>
                {props.answer}
            </div>
            }

        </div>
    )
}

export default FaqsComponent
