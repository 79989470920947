import React from "react";
import "./style.css";
import LandingBannerComponent from "../../Components/LandingBannerComponent";
import { CustomFooter, CustomReviewsComponent } from "../../Components";
import { Col, Row } from "antd";
import Carousel from "react-elastic-carousel";
import ManageSection from "./sections/ManageSection";
import { OurAiBasedSection } from "./sections/OurAiBasedSection";
import HowItWorkSection from "./sections/HowItWorkSection";
import { HowCanWeHelpSection } from "./sections/HowWeCanHelpSection";
import { OurCommpunitySection } from "./sections/OurCommunitySection";
import { OurSolutionSection } from "./sections/OurSolutionSection.js";
import AboutChexComponent from "./sections/AboutChexSection/index.js";
import { BenifitsSection } from "./sections/BenefitsSection/index.js";
import { KeyDiffSection } from "./sections/KeyDiffSection.js/index.js";

const ChexLandingScreen = ({
  changeHanlder,
  aboutBreakPoints,
  carouselRef,
  breakPoints,
  handleNextEnd,
}) => {
  return (
    <>
      <div className="landing_screen_main_wrapper">
        <LandingBannerComponent
          HomeLandingText={
            <div>
              {" "}
              <h3>
                {" "}
                <span className="darkGray">Safety </span> Inspection, Compliance{" "}
                <span className="darkGray"> And </span> maintenance{" "}
                <span className="darkGray"> Platform For The </span>{" "}
                <span className="darkBlue"> Mobility Industry </span>
              </h3>
            </div>
          }
          BannerImage={
            "https://res.cloudinary.com/dgjordf6e/image/upload/v1665470850/Group_495_whfm6k_vdrqjg.png"
          }
          redirectionPage={"/request-a-demo"}
        />
        <section className="backed_company_section_main">
          <div className="backed_company_section_container">
            <Row>
              <Col xl={24} md={24} sm={24}>
                <center>
                  <div className="techStar_LogoCompany">
                    <p className="text_techStart_para_landing">
                      <span className="a_text_landing company_backed"> A </span>
                      <span>
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470856/texttechstart_xurufl_e4w9rg.png"
                          alt="techstar"
                          className="img_techStar"
                        />
                      </span>{" "}
                      <span className="company_backed">backed company</span>
                    </p>
                  </div>

                  <div className="companylogo_container">
                    <Carousel
                      breakPoints={aboutBreakPoints}
                      pagination={false}
                      ref={carouselRef}
                      enableAutoPlay={true}
                      // autoPlaySpeed={1500}
                      onChange={changeHanlder}
                      // autoTabIndexVisibleItems={true}
                      infiniteLoop
                    >
                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726299533/image_34_oijwph.png"
                          alt="company logo"
                        />
                      </div>

                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726745077/Adroit_logo_1_whqe1c.png"
                          alt="company logo"
                        />
                      </div>

                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726299533/image_32_vtqyqs.png"
                          alt="company logo"
                        />
                      </div>
                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726299533/image_33_bhphep.png"
                          alt="company logo"
                        />
                      </div>
                      <div className="item_company_backed_company">
                        <img
                          src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726745076/Carepool_Logo_1_fil4mg.png"
                          alt="company logo"
                        />
                      </div>
                    </Carousel>
                  </div>
                </center>
              </Col>
            </Row>
          </div>
        </section>

        <OurAiBasedSection />
        <ManageSection />
        <HowItWorkSection />
        <BenifitsSection />
        <KeyDiffSection />
        <OurCommpunitySection />
        <OurSolutionSection />
        <HowCanWeHelpSection />

        <AboutChexComponent />

        <div>
          <CustomReviewsComponent breakPoints={breakPoints} />
        </div>

        <CustomFooter />
      </div>
    </>
  );
};
export default ChexLandingScreen;
