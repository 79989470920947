/*eslint-disable */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useToasts } from "react-toast-notifications";

import ActionCreators from "../../actions";
import InspectionSubmittedScreen from "../../Screens/InspectionSubmittedScreen";
import {
  getInspectionDetail,
  getNewCompanyData,
} from "../../actions/vehicleInspection";

const InspectionSubmittedContainer = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const {
    getInspectionByStatus,
    // getVehicleCertificate,
    // createInspection,
    // createReInspection,
    // discardInspection,
    vehiclesByStatus,
    // getCompanies,
    // companies,
    getInspectionDetail,
  } = props;

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [iscertificateLoading, setisCertificateLoading] = useState(false);
  const [certificateData, setCertificateData] = useState({
    id: "",
    companyId: "",
    companyName: "",
    templateId: "",
  });
  const [inspectionData, setInspectionData] = useState({
    vehicleId: "",
    plateNumber: "",
  });

  const handleInspectionData = (plateNumber, vehicleId) => {
    setInspectionData({
      vehicleId: vehicleId,
      plateNumber: plateNumber,
    });
  };

  useEffect(() => {
    getInspectionByStatus(
      {
        status: ["READY_FOR_REVIEW", "REVIEWED", "IN_REVIEW"],
      },
      setLoading
    );
    setCollapse(false);
  }, []);

  const handleVehicleDetails = (item) => {
    history.push(`/VehicleAfterReviewing/${item?.id}/${item?.vehicleId}`);
  };

  const handleNewCompany = (item) => {
    // getInspectionDetail(item);

    const dataEdit = {
      InspectionId: item?.id,
      vehicleId: item?.Vehicle?.id,
      state: item?.inspectionState,
      selectedCompanies: item?.CompanyInspections?.map(
        (data) => data.Company.id
      ),
    };
    history.push(`/selectCompany/${"addCompany"}`, dataEdit);
    //  dispatch(setDeleteLocalFile({ fileId, groupType }));
    const { deleteVehicleFileAddCompnay } = props;
    deleteVehicleFileAddCompnay();
  };

  const handleCancelCompany = () => {
    setCompanyModalVisible(false);
    setCompany(false);
    setCertificateData({
      id: "",
      companyId: "",
      companyName: "",
      templateId: "",
    });
    window.location.reload();
  };

  const showModalCompany = () => {
    setCompanyModalVisible(true);
  };

  const handleOkCompany = () => {
    setCompanyModalVisible(false);
  };

  const handleDownload = (items, id) => {
    setCompany(true);
    setCertificateData({
      id: id,
      companyId: items?.Company?.id,
      companyName: items?.Company?.name,
      templateId: items?.Company?.templateId,
    });
  };

  return (
    <InspectionSubmittedScreen
      loading={loading}
      company={company}
      collapse={collapse}
      setCollapse={setCollapse}
      handleDownload={handleDownload}
      inspectionData={inspectionData}
      certificateData={certificateData}
      handleOkCompany={handleOkCompany}
      vehiclesByStatus={vehiclesByStatus}
      showModalCompany={showModalCompany}
      handleCancelCompany={handleCancelCompany}
      companyModalVisible={companyModalVisible}
      handleInspectionData={handleInspectionData}
      iscertificateLoading={iscertificateLoading}
      handleVehicleDetails={handleVehicleDetails}
      setCompanyModalVisible={setCompanyModalVisible}
      setisCertificateLoading={setisCertificateLoading}
      handleNewCompany={handleNewCompany}
    />
  );
};

// export default InspectionSubmittedContainer;
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehiclesByStatus: state?.vehicleInstruction?.inspectionByStatus,
    companies: state?.auth?.companies?.data,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionSubmittedContainer);
