import React from 'react'
import './style.css'


const ChexSolutionComponent = ({imageSolution}) => {
    return(
        <div className='chex_solution_wrapper_main'>
          <p>
              Chex.<span className='ai_span_style'>AI </span>is an all-in-one solution tailored towards safety inspections and compliance for the mobility industry
          </p>

            <div className='chex_solution_image'>
                <img src={imageSolution} alt="chex_software"/>
            </div>

        </div>
    )
}

export default ChexSolutionComponent