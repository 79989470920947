import React, { useState } from "react";

import LyftVehicleInspectionScreen from "../../Screens/LyftVehicleInspectionScreen";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const LyftInspectionContainer = (props) => {
  const history = useHistory();

  const { addToast } = useToasts();
  const [checkboxValue, setCheckBox] = useState(false);

  const handleCheckBox = () => {
    setCheckBox(!checkboxValue);
  };

  const handleSubmit = (params) => {
    const { register } = props;
    if (params?.phone.includes("_")) {
      addToast("Please enter a valid number", { appearance: "error" });
    } else {
      if (checkboxValue) {
        register(params, history, addToast);
      } else {
        addToast("Please accept term of use", { appearance: "warning" });
      }
    }
  };

  const handleLoginRedirect = () => {
    window.location.href = "/login";
  }

  const handleReDirect = () => {
    window.location.href = `/getStarted`;
  };

  const handleTermsOfUse = () => {
    window.location.href = "/termsAndPolicy?lyft=true";
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
  ];

  return (
    <LyftVehicleInspectionScreen
      handleTermsOfUse={handleTermsOfUse}
      handleLoginRedirect={handleLoginRedirect}
      handleReDirect={handleReDirect}
      handleSubmit={handleSubmit}
      handleCheckBox={handleCheckBox}
      isLoading={props.isLoading}
      breakPoints={breakPoints}
    />
  );
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isAuthLoading,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LyftInspectionContainer);
