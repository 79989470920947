import React from "react";
import "./style.css";

export const OurCommpunitySection = () => {
  return (
    <div className="commpunity-section">
      <section className="commpunity-container">
        <span className="commpunity-title">
          Our Community of Chex.AI is Trending Fast
        </span>
        <div className="community-numbers">
          <div className="community-number-values">
            <div className="numbers-value">1000+</div>
            <div className="text-value">Customers</div>
          </div>
          <div className="community-number-values">
            <div className="numbers-value">5+</div>
            <div className="text-value">Years&nbsp;of&nbsp;Experience</div>
          </div>
          <div className="community-number-values">
            <div className="numbers-value">20+</div>
            <div className="text-value">Companies</div>
          </div>
        </div>
      </section>
    </div>
  );
};
