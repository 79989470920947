/*eslint-disable */

import React from 'react';
import { Modal } from 'antd';
import './style.css';

const ImageConfirmationModal = ({ modal, handleImageConfirmation, aiStatus, handleImageDeny }) => {
    return (
        <div>
            <Modal className="image-confirmation-modal" title="Basic Modal" mask={true} visible={modal} 
            >
                    <>
                        {aiStatus?.meta?.status === "fail" ? (
                        <div className="image-confirmation-modal-text">{aiStatus?.meta?.message}
                        </div>
                        ) : (
                            <div className="image-confirmation-modal-text">Are You Sure You Want To Upload?
                            </div>
                        )}
                        {aiStatus?.meta?.status === "fail" ? (
                        <div className="confirmation-inspection-text">Kindly ReTake the image</div>
                        ) : (
                        <div className="confirmation-inspection-text">{aiStatus?.meta?.message}</div>
                        )}
                        {/* {aiStatus?.meta?.status === "fail" ? null : <div className="image-confirmation-modal-text"> Score: <span>{aiStatus?.score}</span>
                        </div>} */}
                        {aiStatus?.meta?.status === "fail" ? <div className="image-confirmation-modal-text">Status: {aiStatus?.meta?.status}
                        </div> : null}
                    </>
                <div className="image-confirmation-modal-btn">
                    {aiStatus?.meta?.status === "fail" ? (
                        // <div className="image-confirmation-modal-btn-yes">
                        // {'Submit'}
                        // </div>
                        null
                    ) : (
                    <div className="image-confirmation-modal-btn-yes" onClick={() => { handleImageConfirmation() }}>
                            {'Submit'}
                    </div>
                    )}
                        <div className="image-confirmation-modal-btn-no" onClick={() => { handleImageDeny(); }}>
                            <a style={{ color: '#1468BA' }} href="#">
                            {'Retake'}
                            </a>
                        </div>
                </div>
            </Modal>
        </div>
    );
};

export default ImageConfirmationModal;
