import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const MysteriousContainer = () => {
  const history = useHistory();

  useEffect(() => {
    // localStorage.setItem("Temp", "true");
    localStorage.setItem("isMysterious", "true");
    history.push("/login");
  }, [history]);

  return null;
};

export default MysteriousContainer;
