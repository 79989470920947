/*eslint-disable */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useToasts } from 'react-toast-notifications';

import ActionCreators from '../../actions';
import InspectionInProgressScreen from '../../Screens/InspectionInProgressScreen';

const InspectionInProgressContainer = (props) => {
  // const addToast = useToasts();
  //   const history = useHistory();
  const { addToast } = useToasts();
  const {
    getInspectionByStatus,
    discardInspection,
    vehiclesByStatus,
  } = props;


  const [loadingDiscard, setLoadingDiscard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    getInspectionByStatus({status: "IN_PROGRESS"}, setLoading);
  },[load]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleDiscardInspection = (inspectionId) => {
    discardInspection(inspectionId, setLoadingDiscard, setLoad, addToast);
  }

  const handleRedirect = (item) => {
    window.location.href = `/vehicleinspection/${item?.id}/${item?.vehicleId}?lyftUser=${item.lyftInspection}`;
  };

    return (
        <InspectionInProgressScreen 
        loading={loading}
        showModal={showModal}
        loadingDiscard={loadingDiscard}
        isModalVisible={isModalVisible}
        handleRedirect={handleRedirect}
        vehiclesByStatus={vehiclesByStatus}
        handleDiscardInspection={handleDiscardInspection}
        />
    );
} 

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
  }
  
  function mapStateToProps(state) {
    return {
      vehiclesByStatus: state?.vehicleInstruction?.inspectionByStatus,
      companies: state?.auth?.companies?.data,
    };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(InspectionInProgressContainer);
  