import axios from "axios";
import * as types from '../utils/constants';
// import { NotificationManager } from "react-notifications";
// import { GET_BlogData } from "../constants/Constants";
// import { Upload } from "antd";
const headers = {
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
};
function setBlogData(params) {
  return {
    type: types.GET_BlogData,
    payload: params,
  };
}
function setindividualBlogData(params)
{
  return{
  type: types.GET_IndividualRecord,
  payload: params,
  }
}

export const getAllBlogDetails = (setloading,page,pageSize) => async (dispatch) => {
  setloading(true)
  try {
   axios.get(`${process.env.REACT_APP_DEVELOPMENT_URL}/readAll/blog/app?pageSize=${pageSize}&page=${page}`).then((resp) => {
  
    dispatch(setBlogData(resp));
    setloading(false);
  }).catch((error)=>{
   
    setloading(false) 
  })
  }
  catch {
  
    setloading(false)
  }
}
export const getindividualBlogDetails = (setloading,id) => async (dispatch) => {
  setloading(true)
  try {
   axios.get(`${process.env.REACT_APP_DEVELOPMENT_URL}/read/blog/${id}`).then((resp) => {
    dispatch(setindividualBlogData(resp));
    setloading(false);
  }).catch((error)=>{
    setloading(false) 
  })
  }
  catch {
    setloading(false);
  }
}