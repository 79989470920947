import React ,{useRef}from 'react'
import './style.css'
import { Avatar, Card, Col, Row } from 'antd';
import CustomNavbar from '../../Components/CustomNavbar';
import moment from 'moment';
import LandingBannerComponent from "../../Components/LandingBannerComponent";
import {
  CustomFooter,

} from "../../Components";
import { ClipLoader } from 'react-spinners';
import { BsChevronRight } from 'react-icons/bs';
import { Pagination } from 'antd';


const { Meta } = Card;
const BlogScreen = ({ blogdata, showFullContent, toggleContent,pagination,page,setPage,handlechangesize ,firstCardRef,handleScroll,loading}) => {
  return (
    <div style={{overflow:'hidden'}}>
      <div> <CustomNavbar scrollValue={30} />   </div>
      
   
     
      {/* <LandingBannerComponent
        HomeLandingText={<div> <h3 className='darkBlue'>Blog </h3><p style={{color:'rgb(26, 25, 25)'}}>Thoughts around automotive, AI, computer vision, car inspection, damage detection and more by the Chex team.</p></div>}
        BannerImage={'https://res.cloudinary.com/dgjordf6e/image/upload/v1684142736/idea_powgow.png'}
        // redirectionPage={"/contactPage"}
        firstCardRef={firstCardRef}
        handleScroll={handleScroll}
      /> */}
      <div className='main--containerS'>
      <h2 style={{fontWeight:'700',marginTop:'140px'}}>
        Blog
      </h2>
      <Row gutter={[16, 16]} >
        
{loading?(
   <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginLeft:'47%',marginTop:'2%'}}>
   <ClipLoader color={'#246DB5'} size={50} />
   </div>
):(
       
blogdata?.data?.data && blogdata.data.data.length > 0 && (
  blogdata.data.data.map((data,index) => {
    if (data.status === "Published") {
      return (
        <Col xs={24} sm={12} lg={8}  key={data.id} ref={index === 0 ? firstCardRef : null}>
          <div  >
        <Card hoverable
        className='blog-card-manage'
          cover={

            <img style={{height:'250px'}}
              alt="image"
              src={`${process.env.REACT_APP_AWS_S3_LINK}/${data && data?.thumbnail}?x-request=xhr`}
            />           
              }
        >
          <div style={{display:'flex',flexDirection:'column',height:'170px',overflow:'hidden'}}>
         
          <Row style={{color:'black',fontSize:'15px',marginTop:'0.4%',display:'flex'}}>
            <Col xs={24} sm={12} >
            {moment(new Date(data?.createdAt)).format('MM-DD-YYYY')}
            </Col>
         {/* <Col xs={24} sm={12}  className='publishbyat' > {data?.publishedBy}</Col> */}
          </Row>
          <div  style={{ flexGrow: 1 }} >
          <div style={{fontWeight:'bold',color: 'rgb(26, 25, 25)',fontSize:'18px',marginTop:'3%'}} onClick={()=>toggleContent(data?.id , data?.title)} >{data?.title}</div>
          </div>
          {/* <div  style={{ flexGrow: 1 }} > */}
  <div> 
               {/* <div style={{ marginTop: '3%',textAlign:'justify',height:'0px',overflow:'hidden'}} dangerouslySetInnerHTML={{ __html: data?.message }}></div>  */}
           
               {/* </div> */}
              </div>
             
             <div style={{display:'flex',justifyContent:'flex-end'}}>
              <a className='gotoarticle' onClick={()=>toggleContent(data?.id , data?.title)}>Go to Article <BsChevronRight /></a>
</div>
              </div>
        </Card>
        </div>

      </Col>
      );
    }
    return null; 
  })
)
)}


      </Row>
      {loading===true?null:
      < Pagination style={{marginTop:'2%',}}
       current={pagination?.current}
       total={pagination?.total ? pagination?.total : 1} 
       pageSize={20}
       onChange={handlechangesize}   
        />
        } 
      </div>
      {loading===true?null:
      <div>
        <CustomFooter />
      </div>
      }

    </div>
  )
}
export default BlogScreen