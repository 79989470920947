import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const HowItWorkSection = () => {
  const handleLogo = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="how-it-work-container">
      <div className="how-it-work-inner">
        <div className="how-it-work-divs">
          <div className="how-it-work-column1">
            <div className="how-it-work-column1-head">HOW IT WORKS</div>
            <div className="how-it-work-column1-para">
              With a user-friendly app and
              <br /> comprehensive dashboard, Chex.AI
              <br />
              streamlines inspection reviews and
              <br /> provides detailed insights, enhancing
              <br /> operational efficiency
            </div>
            <div className="how-it-work-column1-btn">
              <Link
                to="/request-a-demo"
                onClick={handleLogo}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Book A Demo
              </Link>
            </div>
          </div>
          <div className="how-it-work-column2">
            <div className="how-it-work-column2-divs">
              <div className="how-it-work-column2-card">
                <div className="how-it-work-column2-card-index">1</div>
                <div className="how-it-work-column2-card-head">
                  Mobile Application
                </div>
                <div className="how-it-work-column2-card-para">
                  Scalable photo and video solution through a customizable
                  mobile and web application
                </div>
              </div>
              <div className="how-it-work-column2-card">
                <div className="how-it-work-column2-card-index">2</div>
                <div className="how-it-work-column2-card-head">
                  Partner Dashboard
                </div>
                <div className="how-it-work-column2-card-para">
                  Partner dashboard for real time inspection access, compliance
                  record keeping and advanced analytics
                </div>
              </div>
            </div>
            <div className="how-it-work-column2-divs">
              <div className="how-it-work-column2-card">
                <div className="how-it-work-column2-card-index">3</div>
                <div className="how-it-work-column2-card-head">
                  Damage and Wear Review
                </div>
                <div className="how-it-work-column2-card-para">
                  AI based reviews for pinpoint damage safety items reporting
                  and New damage reporting
                </div>
              </div>
              <div className="how-it-work-column2-card">
                <div className="how-it-work-column2-card-index">4</div>
                <div className="how-it-work-column2-card-head">
                  Integratable
                </div>
                <div className="how-it-work-column2-card-para">
                  Integrate Chex’s solution within your existing system through
                  aPI and SDK with ease
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorkSection;
