/* eslint-disable */

import React, { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import TicketImg from "../../Assets/tickimg.png";
import LyftImg from "../../Assets/liftimg.png";
import UberScNcGe from "../../Assets/UberScNcGe.png";
import "./LyftCertificate.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { Row, Col, Checkbox } from 'antd';
import ActionCreators from "../../actions";
import kalamFont from "../../font/Kalam-Light.ttf";
import { setLyftReportData } from "../../utils/functions";
import font from "../../font/Cairo-VariableFont_wght.ttf";

Font.register({ family: "kalamFont", format: "truetype", src: kalamFont });

const styles = StyleSheet.create({
  body: {
    paddingBottom: 40,
  },
  headerWidth: {
    // paddingHorizontal: 10,
    marginBottom: "25px",
  },
  header: {
    backgroundColor: "#272E38",
    flexDirection: "row",

    alignItems: "center",
    padding: "18@s",
  },
  californiaText: {
    color: "white",
    fontSize: "12px",
    textTransform: "uppercase",
    fontWeight: "400",
    marginLeft: "20@s",
  },
  californiaText1: {
    color: "white",
    fontSize: "20px",
    textTransform: "uppercase",
    fontWeight: "600",
  },
  boxContainer: {
    borderWidth: 1,
    borderColor: "#141414",
    backgroundColor: "#E4E5E7",
    padding: "10@s",
  },
  lyftdrivertextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15@s",
  },
  boxWidth: {
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  lyftdriverText: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "42@s",
    color: "#272E38",
  },
  allfieldsText: {
    color: "#47484F",
    fontSize: "10px",
    fontWeight: "400",
    marginLeft: "8@s",
  },
  nameinputContainer: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "100%",
  },
  lyftdriverName: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "23@s",
    color: "#47484F",
    marginBottom: 0,
    marginLeft: "10@s",
    marginTop: "3@s",
  },
  nameText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "23@s",
    color: "black",
    marginLeft: "30%",
  },
  threeinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  nameinputContainerOne: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "30%",
    marginRight: "10@s",
  },
  nameinputContainerTwo: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "30%",
    marginRight: "10@s",
  },
  nameinputContainerThree: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "40%",
  },
  threeinputFields: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "23@s",
    color: "black",
    marginLeft: "50%",
  },
  licenseplateContainer: {
    borderWidth: 1,
    borderColor: "#47484F",
    width: "45%",
    marginRight: "13@s",
  },
  codeText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "23@s",
    color: "black",
    marginLeft: "50%",
  },
  vinContainer: {
    fontSize: "12px",
    fontWeight: "30",
    fontStyle: "normal",
    color: "black",
  },
  twoinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10@s",
  },
  vintopBorder: {
    borderTopWidth: 1,
    borderTopColor: "#47484F",
    width: "100%",
  },
  topText: {
    marginTop: "30px",
    fontSize: "12px",
    // borderLeftWidth: 1,
    paddingLeft: "8 px",
    paddingRight: "10px",
  },
  vehicleinspectionPadding: {
    paddingHorizontal: "20@s",
    paddingTop: "10@s",
  },
  checkboxesmainContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30@s",
  },
  checkboxesmainContainerTwo: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30@s",
    marginTop: "10@s",
  },
  inspectionfirstContainer: {
    width: "98%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionsecondContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionText: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
    textTransform: "uppercase",
  },
  inspectiontextTwo: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
    marginLeft: "15@s",
    textTransform: "uppercase",
  },
  passText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
    marginRight: "15@s",
    textTransform: "uppercase",
  },
  failText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
    textTransform: "uppercase",
  },
  passfailContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  numbersText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
  },
  detailsText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "black",
    marginLeft: "5px",
  },
  failpassbox: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "black",
    marginLeft: "0@s",
    width: "100%",
    padding: "20px 10px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    marginBottom: "20@s",
    top: 10,
  },

  boxinspectionText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21@s",
    color: "black",
    marginLeft: "-120px",
    textTransform: "uppercase",
  },
  circleoneText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    marginLeft: "-195px",
    lineHeight: "21@s",
    color: "#716E6E",
    marginTop: "5px",
  },
  circleoneText1: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21@s",
    color: "black",
    marginTop: "8px",
    marginLeft: "3px",
  },
  boxpassText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "black",
  },
  boxfailText: {
    marginTop: "-40px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "black",
  },
  orangeCircle: {
    marginTop: "-40px",
    borderWidth: 5,
    borderColor: "#FC6522",
    borderRadius: "50%",
    height: "50px",
    width: "50px",
    lineHeight: "50@s",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "-55px",
  },
  boxfailText: {
    marginTop: "-40px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "black",
    marginLeft: "-15px",
  },
  boxinspectionContainer: {
    marginLeft: "10px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tobecompletedContainer: {
    backgroundColor: "#E4E5E7",
    padding: "15@s 10@s",
  },
  tobecompletedContainer1: {
    marginTop: "10@s",
    // padding: '10@s',
  },
  tobecompletedWidth: {
    paddingHorizontal: "20@s",
    marginTop: "15@s",
  },
  tobecompletedWidth1: {
    paddingHorizontal: "20@s",
  },
  tobecompletedtextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  tobecompletedText: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "23@s",
    color: "black",
    textTransform: "uppercase",
  },
  fieldsText: {
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "#716E6E",
    textTransform: "uppercase",
  },

  inpectionText: {
    fontSize: "16px",
    color: "#47484F",
    marginRight: "10px",
    fontWeight: "600",
    fontStyle: "noraml",
    lineHeight: "22@s",
    textTransform: "uppercase",
  },
  documentexpiryText: {
    fontSize: "12px",
    color: "#47484F",
  },
  dateText: {
    fontSize: "18px",
    color: "black",
    fontWeight: "extralight",
  },
  datemainContainer: {
    marginTop: "10@s",
    marginLeft: "52%",
  },
  namesfieldscontainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "25@s",
    alignItems: "flex-end",
  },
  namesfieldscontainer1: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "25@s",
    alignItems: "flex-end",
  },
  borderWidth: {
    borderBottomWidth: 1,
    borderBottomColor: "gray",
  },
  topName: {
    fontSize: "14px",
    color: "black",
    // fontFamily: 'kalamFont',
    marginBottom: "0px",
    // marginLeft: '12@s',
  },
  bottomName: {
    fontSize: "12px",
    color: "#47484F",
    paddingTop: "5px",
    textTransform: "uppercase",
  },
  bottomPara: {
    paddingHorizontal: "30@s",
    fontSize: 10,
    marginTop: "15@s",
    marginLeft: "10px",
    padding: "0px 10px",
    color: "#716E6E",
  },
  bottomPara1: {
    paddingHorizontal: "30@s",
    fontSize: 10,
    marginTop: "15@s",
    marginLeft: "10px",

    color: "black",
  },
  bottomPara2: {
    paddingHorizontal: "30@s",
    fontSize: 10,
    marginTop: "15@s",
    marginLeft: "14px",
    color: "black",
  },
  tickpassContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "26px",
  },
  tickSelectContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
  },
  tickfailContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  tickfailContainer1: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
  },
  inspectiondateContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "95%",
  },
  statetext: {
    fontSize: "10px",
    marginLeft: "10px",
    color: "gray",
    marginRight: "10px",
  },
  statewrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  stateinspection1: {
    fontSize: "11px",
    color: "gray",
    marginTop: "5px",
    marginLeft: "9.5px",
  },
});

const UberCertificateScNcGe = (props) => {
  const { isLoading, handleModal, SetModal, reportData } = props;
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const getToken = () => localStorage.getItem("token") || null;
    if (getToken) {
      props.getVehicleCertificate(
        { id: props?.id, companyId: props.companyId },
        props.setLoading,
        "template3"
      );
    } else {
      props.history.push("/login");
    }
  }, [props?.companyId]);

  const handleReload = () => {
    SetModal(false);
    localStorage.setItem("tabStatus", "REVIEWED");
    setTimeout(function () {
      handleModal();
    }, 200);
  };

  return (
    <div className="App">
      <PDFDownloadLink
        wrap={false}
        document={
          <Document>
            <Page size="A3" style={styles.body}>
              <View style={styles.headerWidth}>
                <View style={styles.header}>
                  <Text style={styles.californiaText1}>RASIER</Text>
                  <Text style={styles.californiaText}>
                    FOR USE BY DRIVER-PARTNERS ON THE UBER PLATFORM ONLY
                  </Text>
                </View>
              </View>

              <View style={{ marginLeft: "20@s" }}>
                <View
                  style={{
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignSelf: "center",
                  }}
                >
                  <Image
                    style={{
                      width: "100%",
                      marginLeft: -10,
                      // marginTop: "20px",
                    }}
                    source={UberScNcGe}
                  />
                </View>
              </View>

              {/* <View style={styles.vehicleinspectionPadding}>
                  <View style={styles.lyftdrivertextContainer}>
                    <Text style={styles.lyftdriverText}>ANNUAL VEHICLE INSPECTION</Text>
                    <Text style={styles.allfieldsText}>[TO BE COMPLETED BY INSPECTOR]</Text>
                  </View>
                </View> */}

              {/* <View style={{ flexDirection: 'row', paddingHorizontal: '30px' }}>
                  <View style={{ flexDirection: 'column', width: '50%' }}>
                    <View style={styles.inspectionfirstContainer}>
                      <Text style={styles.inspectionText}>INSPECTION POINT</Text>
                      <View style={styles.passfailContainer}>
                        <Text style={styles.passText}>PASS</Text>
                        <Text style={styles.failText}>FAIL</Text>
                      </View>
                    </View>
                    {props?.checksData?.map((check, index) =>
                      index <= 11 ? (
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={styles.numbersText}>{index + 1}.</Text>
                            <Text style={styles.detailsText}>{check?.Check?.name}</Text>
                          </View>
                          <View style={styles.passfailContainer}>
                            {check?.status == true || check?.status == 1 ? (
                              <View style={styles.tickpassContainer}>
                                <Image style={{ width: '16px', marginLeft: '2px' }} source={TicketImg} />
                              </View>
                            ) : (
                              <View style={styles.tickpassContainer}></View>
                            )}
                            {check?.status == false || check?.status == 0 ? (
                              <View style={styles.tickfailContainer}>
                                <Image style={{ width: '16px', marginLeft: '2px' }} source={TicketImg} />
                              </View>
                            ) : (
                              <View style={styles.tickfailContainer}></View>
                            )}
                          </View>
                        </View>
                      ) : null
                    )}
                  </View>
                  <View style={{ flexDirection: 'column', width: '50%' }}>
                    <View style={styles.inspectionfirstContainer}>
                      <Text style={styles.inspectionText}>Inspection Point</Text>
                      <View style={styles.passfailContainer}>
                        <Text style={styles.passText}>Pass</Text>
                        <Text style={styles.failText}>Fail</Text>
                      </View>
                    </View>
                    {props?.checksData?.map((check, index) =>
                      index > 11 ? (
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={styles.numbersText}>{index + 1}.</Text>
                            <Text style={styles.detailsText}>{check?.Check?.name}</Text>
                          </View>
                          <View style={styles.passfailContainer}>
                            {check?.status == true || check?.status == 1 ? (
                              <View style={styles.tickpassContainer}>
                                <Image style={{ width: '16px', marginLeft: '2px' }} source={TicketImg} />
                              </View>
                            ) : (
                              <View style={styles.tickpassContainer}></View>
                            )}
                            {check?.status == false || check?.status == 0 ? (
                              <View style={styles.tickfailContainer}>
                                <Image style={{ width: '16px', marginLeft: '2px' }} source={TicketImg} />
                              </View>
                            ) : (
                              <View style={styles.tickfailContainer}></View>
                            )}
                          </View>
                        </View>
                      ) : null
                    )}
                      <View style={[styles.inspectionsecondContainer, { marginTop: '8px',marginBottom:"20px", width: '96%', marginLeft: 3 }]}>
                          <View style={styles.failpassbox}>
                          <View style={styles.boxinspectionContainer}>
                              <Text style={styles.boxinspectionText}>VEHICLE INSPECTION</Text>
                              <Text style={styles.circleoneText}>(Please circle)</Text>
                              <Text style={styles.circleoneText1}>Must pass all the inspection points to pass vehicle inspection</Text>
                          </View>
                          {reportData && reportData?.finalStatus === 'pass' ? (
                              <View style={styles.orangeCircle}>
                              <Text style={styles.boxpassText}>PASS</Text>
                              </View>
                          ) : (
                              <Text style={styles.boxpassText}>PASS</Text>
                          )}
                          {reportData && reportData?.finalStatus === 'fail' ? (
                              <View style={styles.orangeCircle}>
                              <Text style={styles.boxpassText}>FAIL</Text>
                              </View>
                          ) : (
                              <Text style={styles.boxfailText}>FAIL</Text>
                          )}
                          </View>
                      </View>

                  </View>

                </View> */}
              <View style={styles.tobecompletedWidth1}>
                <View style={styles.tobecompletedContainer1}>
                  <View style={styles.namesfieldscontainer1}>
                    <View style={{ width: "50%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData &&
                          `${reportData?.Username} ${
                            reportData?.UserlastName === null
                              ? ""
                              : reportData?.UserlastName
                          }`}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Partner Name</Text>
                    </View>
                    <View style={{ width: "50%" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.Useremail}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Email Address</Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer1}>
                    <View style={{ width: "30%", marginRight: "20@s" }}>
                      <Text style={styles.topName}> </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Partner Signature</Text>
                    </View>
                    <View style={{ width: "50%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.Userphone}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>
                        Partner Phone Number
                      </Text>
                    </View>
                    <View style={{ width: "20%" }}>
                      <Text style={styles.topName}>
                        {moment(
                          new Date(reportData && reportData?.reviewedDate)
                        ).format("MM/ DD /YYYY")}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Date</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.tobecompletedWidth}>
                <View style={styles.tobecompletedContainer}>
                  <View style={styles.tobecompletedtextContainer}>
                    <Text style={styles.tobecompletedText}>
                      TO BE COMPLETED BY INSPECTOR ONLY{" "}
                    </Text>
                  </View>

                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "50%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>CHEX</Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>COMPANY NAME</Text>
                    </View>

                    <View style={{ width: "50%" }}>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        {reportData &&
                        reportData?.inspectionState === "South Carolina" ? (
                          <>
                            <View style={styles.statewrap}>
                              <View style={styles.tickSelectContainer}>
                                <Image
                                  style={{ width: "16px", marginLeft: "2px" }}
                                  source={TicketImg}
                                />
                              </View>
                              <Text style={styles.statetext}>
                                SOUTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>
                                NORTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>GEORGIA *</Text>
                            </View>
                          </>
                        ) : reportData &&
                          reportData?.inspectionState === "North Carolina" ? (
                          <>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>
                                SOUTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickSelectContainer}>
                                <Image
                                  style={{ width: "16px", marginLeft: "2px" }}
                                  source={TicketImg}
                                />
                              </View>
                              <Text style={styles.statetext}>
                                NORTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>GEORGIA *</Text>
                            </View>
                          </>
                        ) : reportData &&
                          reportData?.inspectionState === "Georgia" ? (
                          <>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>
                                SOUTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>
                                NORTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickSelectContainer}>
                                <Image
                                  style={{ width: "16px", marginLeft: "2px" }}
                                  source={TicketImg}
                                />
                              </View>
                              <Text style={styles.statetext}>GEORGIA *</Text>
                            </View>
                          </>
                        ) : (
                          <>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>
                                SOUTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>
                                NORTH CAROLINA
                              </Text>
                            </View>
                            <View style={styles.statewrap}>
                              <View style={styles.tickfailContainer1}></View>
                              <Text style={styles.statetext}>GEORGIA *</Text>
                            </View>
                          </>
                        )}
                      </View>
                      <View>
                        <Text style={styles.stateinspection1}>
                          STATE OF INSPECTION LOCATION (CHECK ONE)
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "100%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.stateAddress}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>
                        Address Inspection Location
                      </Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "50%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.licensePlateNumber}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>License Plate #</Text>
                    </View>
                    <View style={{ width: "25%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.vin}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Vin #</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.milage}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Vehicle Mileage</Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "50%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.make}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Vehicle Make</Text>
                    </View>
                    <View style={{ width: "35%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.model}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Vehicle Model</Text>
                    </View>
                    <View style={{ width: "15%" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.year}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Vehicle Year</Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "50%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.Inspectorsignature ? (
                          <Image
                            style={{
                              width: "100px",
                              height: "31px",
                              marginLeft: "50px",
                              marginBottom: -30,
                            }}
                            source={`${process.env.REACT_APP_AWS_S3_LINK}/${
                              reportData && reportData?.Inspectorsignature
                            }?x-request=xhr`}
                          />
                        ) : (
                          <Text style={styles.topName}>
                            {reportData &&
                              `${reportData?.Inspectorname} ${
                                reportData?.InspectorlastName === null
                                  ? ""
                                  : reportData?.InspectorlastName
                              }`}
                          </Text>
                        )}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>
                        Signature of Inspector
                      </Text>
                    </View>
                    <View style={{ width: "50%" }}>
                      <Text style={styles.topName}>
                        {moment(
                          new Date(reportData && reportData?.reviewedDate)
                        ).format("MM/ DD /YYYY")}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Inspection Date</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ textAlign: "center", padding: "0px 115px" }}>
                <Text style={styles.bottomPara}>
                  List of approved facilities for Georgia: Any car dealership,
                  Bridgestone, Butler Automotive Service, Firestone Complete
                  Auto Care (FCAC), Goodyear, Gordon Car Care, Jiffy Lube,
                  Meineke, Midas, National Tire & Battery Auto Center (NTB), Pep
                  Boys, Prescion Tune Auto Care, Savannah Tire, Tires Plus, ot
                  Tuffy Auto Service.
                </Text>
                <Text style={styles.bottomPara1}>
                  Please upload this form to your partners.uber.com account once
                  complete. This form expires up to one(1) year from the
                  inspection date.
                </Text>
                <Text style={styles.bottomPara2}>
                  THIS FORM MAY ONLY BE USED BY DRIVER-PARTNERS ON THE UBER
                  PLATFORM
                </Text>
              </View>
            </Page>
          </Document>
        }
        fileName="Vehicle-certificate.pdf"
      >
        {({ blob, url, loading, error }) => (
          <>
            {isLoading ? (
              <ClipLoader color={"#246DB5"} size={40} />
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    handleReload();
                  }}
                  style={{
                    fontSize: "15px",
                    marginBottom: "20px",
                    marginTop: "20px",
                    color: "white",
                    fontWeight: "bold",
                    margin: "auto",
                    background: "#3276ba",
                    padding: "13px",
                    borderRadius: "50px",
                  }}
                >
                  Download Vehicle Report
                </div>
                <i className="fa fa-download" />
              </div>
            )}
          </>
        )}
      </PDFDownloadLink>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    reportData: state.vehicleInstruction.lyftVehicleCertificate,
    checksData: setLyftReportData(
      state.vehicleInstruction.lyftVehicleCertificate?.Files
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UberCertificateScNcGe);
