/* eslint-disable */
import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

import {
  MainContainer,
  PrivacyPolicyHeading,
  PrivacyPolicyUnderLine,
  PrivacyPolicyText,
  MainBgSignin,
  PrivacyPolicySpan,
  ContentMainScreen,
  ContentMainScreenh5,
  ArrowBack,
} from "./style";

import "./style.css";

const PrivacyPage = ({ showModal, closeModal }) => (
  <div>
    <Modal className="modal-styling" isOpen={showModal}>
      <MainBgSignin>
        <ContentMainScreen>
          <ContentMainScreenh5>
            <ArrowBack>
              <div onClick={closeModal}>
                <IoIosArrowBack className="left-icon-termsAndPolicy" />
              </div>
            </ArrowBack>
            <PrivacyPolicySpan>Privacy Policy</PrivacyPolicySpan>
          </ContentMainScreenh5>
        </ContentMainScreen>
      </MainBgSignin>
      <MainContainer style={{ backgroundColor: "white" }}>
        <PrivacyPolicyHeading>TERMS OF USE</PrivacyPolicyHeading>
        <PrivacyPolicyText>
          Chex.ai , Inc. ("Chex.ai"," "we, "us" or "our" as the context
          indicates) owns and operates chex.ai.com and other sites linking to
          these Terms of Use, including the mobile version of this website and
          our mobile application (individually, the "Site," or, collectively,
          the "Sites"). Through the Sites, Chex.ai provides various service
          offerings, information and resources related to vehicle inspection
          services (each a "Service" and collectively, the "Services").
          References to the Sites include the Services.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          These terms of use ("the "Terms of Use") are an agreement between you
          and Chex.ai governing your access to and use of the Sites, their
          functionality, their content, and our Services. By using or accessing
          the Sites, you represent and warrant that you are of legal age to
          accept these Terms of Use and form a binding contract with Chex.ai.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          Chex.ai may change, suspend, modify, or discontinue all or any part of
          the Sites in its sole discretion with or without notice. Chex.ai is
          not liable if all or any part of a Site is, for any reason,
          unavailable at any time or for any period. Chex.ai reserves the right
          to block or deny access to any of the Sites to anyone at any time and
          for any reason. Chex.ai is not obligated to correct or update any
          information or content on the Sites. The information published on the
          Sites was valid at the time of publication. Chex.ai reserves the right
          to make changes and improvements at any time and without notice and
          assumes no liability for damages incurred directly or indirectly as a
          result of errors, omissions or discrepancies.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          THE SITE AND RELATED SERVICES ARE PROVIDED SUBJECT TO YOUR COMPLIANCE
          WITH THE TERMS OF USE SET FORTH BELOW. PLEASE READ THE FOLLOWING
          INFORMATION CAREFULLY. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS,
          PROMPTLY EXIT THE SITE WITHOUT ACESSING OR USING ANY OF THE SERVICES.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          BY ACCESSING ANY PAGES OF THE SITES, CREATING AN ACCOUNT, USING ANY
          SERVICES MADE AVAILABLE THROUGH THE SITES, POSTING, SUBMITTING,
          TRANSMITTING OR UPLOADING ANY INFORMATION OR CONTENT THROUGH THE
          SITES, YOU EXRESSLY AGREE THAT YOU HAVE READ AND THAT YOU UNDERSTAND
          AND AGREE TO THE TERMS, CONDITIONS, LIMITATIONS, NOTICES AND COVENANTS
          SET FORTH IN THESE TERMS OF USE AND ANY APPLICABLE POLICIES AND
          DISCLAIMERS REFERENCED HEREIN OR ON THE SITES. DO NOT USE THE SITES IF
          YOU DO NOT AGREE TO ALL OF THE TERMS OF USE AND OTHER POLICIES.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Restrictions on Use</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          The Sites are the property of Chex.ai and are protected by state,
          federal, and/or international copyright and trademark laws. No portion
          of the materials on these pages may be reprinted, republished,
          modified, or distributed in any form without the express written
          permission of Chex.ai. The Sites are provided for your own personal
          use or the internal use of your business. You shall keep intact any
          proprietary notices, including copyright notices, contained on any
          downloaded materials and shall comply with any applicable end user
          license agreements. Any rights not expressly granted by these Terms
          and Conditions or any applicable end user license agreements are
          reserved by Chex.ai.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Account Access and Security</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          Should you choose to register an account with us, you are responsible
          for maintaining the confidentiality of your account, including your
          username and password. You agree to notify Chex.ai immediately if you
          become aware of any unauthorized access to or use of your account. To
          protect the security of your account, you should choose a username and
          password that are not associated with your personal information.
          Chex.ai reserves the right to disable an account at any time.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>User Content</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          The Sites may now or in the future contain Services that use
          information you have provided to Chex.ai and that allow you and others
          to post, submit, publish, display, or otherwise transmit ("post")
          various information and materials ("User Content"), which may include
          answers in response to questions, comments, documents, and other
          similar content. Except as expressly provided in these Terms of Use or
          the policies applicable to the Sites, the User Content is and will be
          considered non-confidential and non-proprietary. You retain all your
          ownership rights in and to your User Content, and by posting User
          Content to the Sites, you hereby grant to Chex.ai an unrestricted,
          non-exclusive, perpetual, royalty-free, worldwide, transferable,
          sub-licensable, and irrevocable license and right, but not the
          obligation, to use, edit, alter, copy, reproduce, disclose, display,
          publish, prepare derivative works from, perform, market, distribute,
          exhibit, broadcast, or otherwise exploit such User Content and
          derivatives thereof, in whole or in part, and in any form, media, or
          technology now known or hereafter developed.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          Chex.ai is under no obligation to use, return, review, or respond to
          User Content. You understand and acknowledge that you are responsible
          for any User Content you provide. In providing any User Content, you
          represent and warrant that (i) you own or control all rights in and to
          such User Content and have the right to grant the license granted
          above and (ii) all of your User Content does and will comply with
          these Terms of Use, including the Content Standards below. You have
          full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness, as further explained in
          the Content Standards.
        </PrivacyPolicyText>
        {/* <PrivacyPolicyUnderLine>
          <u>Usage of Service, Assumption of Risk, and Limitation of Liability (Vehicle Inspection Platform)</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          The vehicle inspection services offered by Chex.ai are intended to provide an evaluation of your vehicle's suitability for usage as a rideshare vehicle, as such standards
          of suitability are articulated by companies such as Uber, Lyft, or any other ride sharing platform. THE INSPECTION PROVIDED BY CHEX.AI IS NOT A SAFETY INSPECTION. The
          Company makes no warranty as to the safety or drivability of your vehicle, and expressly disclaims any and all liability arising from or related to safety issues with
          your vehicle. You expressly agree and acknowledge that ensuring the safety of your vehicle is your own responsibility, and that if you have any doubts as to your
          vehicle's safety, you shall have a safety inspection conducted on your vehicle from a third-party qualified to provide such inspections. Chex.ai shall only inspect your
          vehicle for compliance with the applicable standards of the ridesharing platform to which you are being referred. YOU HEREBY EXPRESSLY WAIVE AND RELEASE THE COMPANY FROM
          ANY AND ALL CLAIMS RELATED TO THE SAFETY AND DRIVEABILITY OF YOUR VEHICLE, AND EXPRESSLY ASSUME THE RISKS INHERENT IN DRIVING FOR A RIDESHARING PLATFORM.
        </PrivacyPolicyText> */}
        <PrivacyPolicyUnderLine>
          <u>Content Standards</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          You agree that you will not post any User Content that (i) infringes
          or violates any other party's intellectual property rights; (ii) fails
          to comply with applicable laws and regulations, or (iii) contains any
          expressions of hate, abuse, offensive images or conduct, or any
          similar content.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          Questions, comments and complaints regarding Chex.Ai App's data
          practices can be submitted to the Chex.Ai App data protection office
          through this link.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          Without limiting the foregoing, User Content must not:
        </PrivacyPolicyText>
        <ul>
          <li>
            contain any defamatory, libelous, slanderous, obscene, indecent,
            abusive, offensive, harassing, violent, hateful, inflammatory,
            sexually explicit, pornographic, or otherwise objectionable (as
            determined by Chex.ai in its sole discretion) material;
          </li>
          <li>
            violate any person's or party's legal rights (including intellectual
            property rights, moral rights, and rights of publicity and privacy);
          </li>
          <li>
            impersonate any person or entity or misrepresent the identity or
            affiliation of the user or any other party;
          </li>
          <li>
            promote sexually explicit or pornographic material, violence, or
            discrimination based on race, ethnicity, sex, religion, nationality,
            disability, sexual orientation or age;
          </li>
          <li>
            appear as if they are sent by or endorsed by Chex.ai or any other
            person, if this is not the case;
          </li>
          <li>be likely to or designed to deceive any person;</li>
          <li>
            contain any material that is unlawful or could result in civil or
            criminal liability;
          </li>
          <li>
            incite, advocate, promote, contribute to, enable, or assist any
            illegal or unlawful activity;
          </li>
          <li>
            involve commercial activities or sales, such as contests,
            sweepstakes, and other sales promotions, barter or advertising,
            other than as conducted by Chex.ai in its operation of the Sites; or
          </li>
          <li>
            conflict with these Terms of Use or any other applicable law or
            policy.
          </li>
        </ul>
        <PrivacyPolicyUnderLine>
          Monitoring and Removal of User Content
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          Chex.ai reserves the right, in its sole discretion, to (i) remove or
          refuse to post any User Content for any or no reason; (ii) take any
          action with respect to any User Content that it deems necessary or
          appropriate, if Chex.ai believes that it violates the Terms of Use or
          Content Standards, infringes any intellectual property rights,
          threatens a person's safety, or could create liability for Chex.ai; or
          (iii) take any action Chex.ai deems appropriate or advisable,
          including referral to law enforcement, for illegal or unauthorized
          User Content or use of the Sites, to prevent harm, or to protect
          against liability. Without limiting the foregoing, Chex.ai has the
          right to fully cooperate with any law enforcement authorities or court
          orders requesting or directing it to disclose the identity or other
          information of anyone posting any User Content.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          Chex.ai is not responsible or liable to any third party and assumes no
          liability for any action or inaction regarding the posting of User
          Content by any user or third party. At its discretion Chex.ai may, but
          is not obligated to, take steps to monitor User Content. However,
          Chex.ai does not review material before it is posted and makes no
          guarantees regarding promptness of removal of objectionable material
          after it has been posted. Chex.ai is not deemed to endorse, verify, or
          agree with any User Content.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          CHEX.AI HAS NO LIABILITY OR RESPONSIBILITY TO ANYONE FOR PERFORMANCE
          OR NONPERFORMANCE OF THE ACTIVITIES DESCRIBED IN THIS SECTION. YOU
          WAIVE AND HOLD HARMLESS CHEX.AI AND ITS AFFILIATES, LICENSORS, AND
          SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
          ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
          INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
          INVESTIGATIONS BY CHEX.AI OR LAW ENFORCEMENT AUTHORITIES.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>Prohibited Uses</PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          As a condition of your use of this Sites, you warrant to Chex.ai that
          you will not use the Sites for any purpose that is unlawful or
          prohibited by these Terms of Use. You agree that you will not use the
          Sites:
        </PrivacyPolicyText>
        <p>
          <ul>
            <li>
              In any way that violates any applicable law or intellectual
              property right;
            </li>
            <li>
              In any way that threatens the security or functionality of the
              Sites;
            </li>
            <li>
              To transmit any material that does not comply with the Content
              Standards;
            </li>
            <li>
              For the purpose of building a competitive product or service; or
            </li>
            <li>
              For any other purpose or in any manner not expressly permitted by
              these Terms of Use.
            </li>
          </ul>
        </p>
        <PrivacyPolicyUnderLine>
          Intellectual Property Rights
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          Chex.ai and its licensors or other providers own the Sites, including
          all of their content, features, and functionality--including any
          information, software code, text, displays, images, video and audio,
          and the design, selection and arrangement of such material, and any
          documents, resources, recommendations, guidance, forms, policies, or
          other materials provided or generated through the Sites ("Site
          Content"). The Sites and all such Site Content are protected by
          copyright, trademark, and other laws and treaties, and may not be used
          except as permitted in the Terms of Use. No right, title, or interest
          in or to the Sites or any Site Content is transferred to you, and all
          rights not expressly granted are reserved by Chex.ai. Any use of the
          Sites or Site Content not expressly permitted by the Terms of Use is a
          breach of them and may violate copyright, trademark, and other laws.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          You may use the Sites solely for their intended purpose and you may
          download one copy of the Site Content on any single computer and print
          a copy of the materials for your use in learning about, evaluating, or
          acquiring Chex.ai's Services. No other permission is granted to you to
          print, copy, reproduce, distribute, transmit, upload, download, store,
          display in public, alter, or modify these materials. No permission is
          granted here to you to use Chex.ai icons, site address, or other means
          to hyperlink other websites with our Sites.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>Trademark Notice</PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          Chex.ai owns and retains all rights in its trademarks, trademark
          names, trade dress, logos, and designs, which are registered and/or
          common law trademarks of Chex.ai and are protected by law (the
          "Trademarks"). No license to use Chex.ai's Trademarks is granted to
          you under these Terms of Use or by your use of the Sites. All other
          logos, designs, and slogans on the Sites are the intellectual property
          of their respective owners and are used in accordance with the law
          and/or license and/or other agreement.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>Third Party Information</PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          The Sites may include content provided by third parties. Other than
          content provided by Chex.ai, all statements and/or opinions expressed,
          all articles and responses to questions, and other content are solely
          the opinions and the responsibility of the party providing those
          materials. Chex.ai is not responsible for the content or accuracy of
          any materials provided by any third parties. Chex.ai does not warrant
          the accuracy, completeness, or reliability of any third party
          information on the Sites.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>Links to Our Sites</PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          You may link to our Sites provided you do so in a way that is legal
          and does not take advantage of Chex.ai's reputation. In so doing, you
          must not suggest any form of association, approval or endorsement on
          the part of Chex.ai without the express written consent of Chex.ai.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          Our Sites may offer integration with social media websites or apps.
          The Sites may provide features that enable you to (i) link from your
          own or certain third-party social media websites to particular content
          or types of content on the Sites; (ii) send e- mails or other
          communications with certain content, or links to certain content, on
          the Sites; or (iii) cause limited portions of content displayed on the
          Sites to be displayed or appear to be displayed on your own or certain
          third-party sites. You may use these features solely as Chex.ai
          provides and in accordance with any additional terms and conditions we
          provide with respect to such features. Subject to the foregoing, you
          must not (i) establish a link from any website or social media account
          that you do not own or control; (ii) cause the Sites or portions of it
          to be displayed, or appear to be displayed (by, for example, framing
          or in-line linking) on any other site; or; or (iii) take any other
          action with respect to the material or content on the Sites that is
          inconsistent with any other provision of these Terms of Use.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          The website from which you link, or on which you make certain content
          accessible, must comply in all respects with the Content Standards set
          out in these Terms of Use. You agree to cooperate with Chex.ai in
          causing any unauthorized framing or linking to cease immediately.
          Chex.ai may withdraw linking permission without prior notice. Chex.ai
          may disable any or all social media features and any links at any time
          without notice.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Links to Other Websites</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          Chex.ai makes no representations whatsoever about any other website
          that you may access though this Site. When you access a non-Chex.ai
          website, please understand that it is independent from Chex.ai and
          that Chex.ai has no control over the content on that website. In
          addition, a hyperlink to a non- Chex.ai website does not mean that
          Chex.ai endorses or accepts any responsibility for the content, or the
          use of the linked site. It is up to you to take precautions to ensure
          that whatever you select for your use or download is free of such
          items as viruses, worms, trojan horses, and other items of a
          destructive nature.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Geographic Restrictions</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          Chex.ai is intended for use only by persons located in the United
          States. Chex.ai makes no claim that the Sites or any of their content
          is appropriate or accessible outside of the United States. If you are
          accessing the Sites outside of the United States, your access to the
          Sites is done at your own risk as it may not be legal.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Disclaimer of Warranties</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          THIS SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR
          ANY SITE-RELATED SERVICE, IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
          BASIS, WITH ALL FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY
          KIND, EITHER EXPRESSED OR IMPLIED, OTHER THAN THOSE EXPRESS WARRANTIES
          PROVIDED IN THESE TERMS OF USE. NEITHER CHEX.AI OR ANY PERSON OR
          ENTITY AFFILIATED OR ASSOCIATED WITH IT MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, SAFETY,
          RELIABILITY, QUALITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE
          SITES, SITE CONTENT, AND SERVICES. WITHOUT LIMITING THE FOREGOING,
          NEITHER CHEX.AI OR ANYONE ASSOCIATED WITH IT REPRESENTS OR WARRANTS
          THAT THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FEE OR UNINTERRUPTED,
          THAT DEFECTS WILL BE CORRRECTED, THAT THE SITE OR SERVER THAT MAKES IT
          AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
          WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
          OTHERWISE MEET YOUR EXPECTATIONS OR NEEDS.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          EXCEPT AS EXPRESSLY PROVIDED HEREIN, CHEX.AI DISCLAIMS ALL WARRANTIES
          OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
          INCLDUING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ASSUME
          TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITES AND CHEX.AI'S
          SERVICES.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          THE FOREGOING APPLY TO THE GREATEST EXTENT PERMISSIBLE PURSUANT TO
          APPLICABLE LAW, AND DOES NOT EXCLUDE OR LIMIT ANY WARRANTIES TO THE
          EXTENT THAT THEY CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW,
          IN WHICH CASE THE FOREGOING LIMITATIONS WILL APPLY SOLELY TO THE
          EXTENT LEGALLY PERMISSIBLE.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>No Warranty of Safety</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          CHEX.AI EXPRESSLY DISCLAIMS ANY WARRANTY RELATED TO THE SAFETY OF YOUR
          VEHICLE, REGARDLESS OF WHETHER OR NOT YOUR VEHICLE PASSES CHEX.AI'S
          VISUAL INSPECTION.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          YOU ACKNOWLEDGE AND AGREE THAT THE SAFETY OF YOUR VEHICLE IS YOUR
          RESPONSIBILITY AND NOT THE RESPONSIBILITY OF CHEX.AI.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          YOU ACKNOWLEDGE AND AGREE THAT ANY VEHICLE INSPECTION SERVICE PROVIDED
          TO YOU BY CHEX.AI IS BASED SOLELY ON INFORMATION AND PHOTOGRAPHS
          PROVIDED BY YOU FOR THE PURPOSE OF PERFORMING AN EVALUATION OF YOUR
          VEHICLE FOR USE AS A RIDESHARE VEHICLE. YOU ACKNOWLEDGE AND AGREE THAT
          CHEX.AI IS NOT EVALUATING NOR ENSURING THE SAFETY OF YOUR VEHICLE. IF
          YOU HAVE ANY DOUBTS ABOUT THE SAFETY OF YOUR VEHICLE, YOU SHOULD HAVE
          YOUR VEHICLE INSPECTED BY A THIRD PARTY QUALIFIED TO PROVIDE SUCH AN
          INSPECTION.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          Limitation on Liability and Time to File Claims
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          IN NO EVENT WILL CHEX.AI, ITS SERVICE PROVIDERS, EMPLOYEES, AGENTS,
          OFFICERS, MANAGERS, OR DIRECTORS, OR AFFILIATES OR THEIR LICENSORS, BE
          LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF
          OR IN CONNECTION WITH USE OF, OR INABILITY TO USE, THE SITES, ANY
          WEBSITES LINKED TO THE SITE, ANY SITE CONTENT OR OTHER CONTENT ON THE
          SITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE SITE OR SUCH OTHER WEBSITES. THIS LIMITATION INCLUDES ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES ARISING FROM PERSONAL
          INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF INCOME, LOSS
          OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
          LOSS OF USE, LOSS OF GOODWILL, AND LOSS OF DATA, WHETHER CAUSED BY
          TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
          FORESEEABLE.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, CHEX.AI WILL NOT BE
          LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
          DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
          MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
          DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITES.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE SITES OR SERVICES MUST BE COMMENCED
          WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH
          CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          Chex.Ai App uses the information we collect (including recordings of
          customer support calls after notifying you and with your consent) to
          assist you when you contact our customer support services, including
          to:
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          YOU UNDERSTAND AND ACKNOWLEDGE THAT CHEX.AI WOULD NOT BE ABLE TO
          PROVIDE THE SITE AND SERVICES ON AN ECONOMICALLY FEASIBLE BASIS
          WITHOUT THE FOREGOING EXCLUSIONS, LIMITATIONS, AND DISCLAIMERS OF
          WARRANTIES AND LIMITATIIONS OF LIABILITY, AND THAT ACCORDINGLY, SUCH
          EXCLUSIONS, LIMITATIONS, AND DISCLAIMERS REFLECT A REASONABLE
          ALLOCATION OF RISK UNDER THE CIRCUMSTANCES, AND WILL APPLY TO THE
          GREATEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          We may use the information we collect for testing, research, analysis
          and product development. This allows us to further and further improve
          the security of our services, to develop new features and products and
          to facilitate insurance and financing solutions related to our
          services.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Refunds</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          Chex.ai handles refund requests on a case by case basis. From time to
          time, Chex.ai may offer a refund for an inspection if, in its sole and
          absolute discretion, it determines that a refund is appropriate. Under
          no circumstances shall Chex.ai be obligated to provide a refund. You
          acknowledge that your vehicle may be rejected for non-compliance with
          certain standards, and that in such event, Chex.ai will have
          nevertheless expended effort and resources in providing the Services.
          Chex.ai cannot guarantee the suitability or compliance of every
          vehicle inspected, and therefore cannot guarantee a satisfactory
          result, despite your payment for the Services.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Indemnification</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          To the maximum extent permitted by applicable law, you agree to
          defend, indemnify, and hold harmless Chex.ai, its affiliates and their
          respective directors, officers, employees, agents, service providers,
          contractors, licensors, suppliers, successors, and assigns from and
          against any claims, liabilities, damages, judgments, awards, losses,
          costs, expenses, or fees (including reasonable attorneys' fees)
          arising out of or relating to your breach of these Terms of Use or
          your use of the Services, including, but not limited to, your User
          Content and any use of the Sites other than as expressly authorized in
          these Terms of Use.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Governing Law and Jurisdiction</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          These Terms of Use are entered into in the State of California and
          shall be governed by and construed in accordance with the laws of the
          State of California exclusive of its choice of law rules. Each party
          to these Terms of Use submits to the exclusive jurisdiction of the
          state and federal courts located in the State of California and waives
          any jurisdictional, venue, or inconvenient forum objections to such
          courts. In the event that any of the Terms of Use are held by a court
          or other tribunal of competent jurisdiction to be unenforceable, those
          provisions shall be limited or eliminated to the minimum extent
          necessary so that these Terms of Use shall otherwise remain in full
          force and effect.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Waiver and Severability</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          No waiver by Chex.ai of any term or condition set forth in these Terms
          of Use shall be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          of Chex.ai to assert a right or provision under these Terms of Use
          shall not constitute a waiver of such right or provision.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>
          <u>Denial of Access</u>
        </PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          These Terms of Use constitute an agreement that is effective unless
          and until terminated by Chex.ai. If in Chex.ai's sole discretion you
          fail to comply with any term or provision of these Terms of Use,
          Chex.ai may deny you access to the Sites. In the event of denial of
          access by Chex.ai, you are no longer authorized to access the Sites,
          and the restrictions imposed upon you with respect to material copied
          or downloaded, and the disclaimers and limitations of liabilities set
          forth in these Terms of Use, shall continue in force.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>Entire Agreement</PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          These Terms of Use constitute the sole and entire agreement between
          you and Chex.ai regarding the Sites and supersede all prior and
          contemporaneous understandings, agreements, representations and
          warranties, both written and oral, with respect to the Sites. In its
          sole discretion, Chex.ai may modify these Terms of Use by posting the
          revised version on this Site and you agree that each visit by you to
          this Site is a new transaction governed by the Terms of Use linked on
          this Site at that time.
        </PrivacyPolicyText>
        <PrivacyPolicyUnderLine>Contact Chex.ai</PrivacyPolicyUnderLine>
        <PrivacyPolicyText>
          This website is operated by Chex.ai located at www.chex.ai.com. All
          feedback, comments, requests for technical support, or other
          communications relating to the Sites should be directed to: :{" "}
          <a style={{ color: "black" }} href="mailto:support@chex.ai">
            support@chex.ai
          </a>
        </PrivacyPolicyText>
        <PrivacyPolicyText>Last Updated: 02/08/2021</PrivacyPolicyText>
      </MainContainer>
    </Modal>
  </div>
);

export default PrivacyPage;
