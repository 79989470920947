/* eslint-disable */

import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useHistory } from 'react-router-dom';

import './style.css';
import '../../App.css';


import { Header } from '../../Components';

import { InProgressTileComponent } from '../../Components';

import progress from '../../Assets/progress.png';




const InspectionInProgressScreen = ({ handleRedirect, vehiclesByStatus, loading, handleDiscardInspection, loadingDiscard }) => {
  const history = useHistory();

  return (

    <div className="inspection-main-container">
      <div>
        <div style={{ marginBottom: '-13px' }}>
          <Header title={true} />
        </div>

        <div className="inspection-conatiner">
          <div className="inspections-in-progress-text">Inspections in Progress</div>
          {vehiclesByStatus != "" ?
            <i>
              <div className="inspection-tag">
                Please select inspection below to continue. Once you submit, we will review and issue certificate
              </div>
            </i>
            : null
          }
          <div className="inspection-progress-all-tiles">
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : vehiclesByStatus != "" ? (
              vehiclesByStatus?.map((item, index) => {
                return (
                  <InProgressTileComponent
                    item={item}
                    key={index}
                    loading={loading}
                    handleRedirect={handleRedirect}
                    loadingDiscard={loadingDiscard}
                    handleDiscardInspection={handleDiscardInspection}
                  />
                );

              })) : <div className="progress-container">
              <img className="draft-image-show" src={progress} />
              <div className="draft-message-show">{'0 Inspections Are In Progress'}</div>
            </div>}
          </div>
          <div className="inspection-bottom-content">
            <div className="start-inspection-button">
              <div className="select-text" onClick={() => { history.push(`/selectCompany/${false}`) }}>+ Start Inspection</div>
            </div>
            <div className="back-text" onClick={() => { history.push(`/selectOption`) }}>Or Go back to <b className="home-text"> Home </b>  page</div>
          </div>
        </div>


      </div>

    </div >




  );
};
export default InspectionInProgressScreen;
