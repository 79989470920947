import React from 'react'
import './style.css'
import {Link} from "react-router-dom";

const HelpBusinessSectionComponent = ({headingSolution, paraSolution, buttonText, imageSolution, wrapper_background_style, wrapper_reverse_order, imageGovtStyle}) => {
    return(
        <div className={`main_wrapper_help_business_outer ${wrapper_background_style ? 'wrapper_background_style' : null} ${wrapper_reverse_order ? 'wrapper_reverse_order' : null} `}>
            <div className='content_wrapper_help_business'>

                {headingSolution}




                <p>
                    {paraSolution}
                      </p>


                {buttonText &&
                    <div className='btn_contact_wrapper_help_business'>

                        {/*<Link to='/contactPage' >*/}
                            <a href="/contactPage">
                                {buttonText}
                            </a>



                        {/*</Link>*/}


                    </div>
                }


            </div>
            <div className={`image_wrapper_help_business ${imageGovtStyle ? 'imageGovtStyle' : null}`}>
                <img src={imageSolution} alt="image solution"/>
                {/*<img src={'https://res.cloudinary.com/techlingcompany/image/upload/v1662380812/Group_510_mntmoh.png'} alt=""/>*/}

            </div>


        </div>
    )
}

export default HelpBusinessSectionComponent