import { Rate } from "antd";
import React from "react";
import "./style.css";

import styles from "./index.module.css";

const ReviewCard = (props) => {
  const bgColorClass =
    props.bgColor === "#ffffff"
      ? "review-card-container"
      : "review-card-container-blue";

  return (
    <div className={styles.carouselWrapper}>
      <div className={`${bgColorClass}`}>
        <p className="review-text">{props.reviewText}</p>
        <div className="name-rate-container">
          <p className="reviewer-name">{props.reviewerName}</p>
          <Rate allowHalf disabled defaultValue={props.ratingStar} />
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
