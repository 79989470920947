import React from 'react'
import './style.css'

const WhyChexAiComponent = ({firstIllustration, firstPara, secondIllustration, secondPara, thirdIllustration, thirdPara}) => {
    return(
        <div className='why_chex_component_wrapper_outer'>
            <div className='why_chex_heading'>
                <h2>
                 <span className='span_style_why'> Why </span>  Chex.AI
                </h2>

            </div>

            <div className='why_chex_steps_inner_main_wrapper'>

                <div className='why_chex_step_straight_wrapper'>
                    <img src={firstIllustration} alt=""/>

                    <div className='why_chex_step_content_wrapper'>
                        <p>
                            {firstPara}
                        </p>
                    </div>

                </div>

                <div className='why_chex_step_reverse_wrapper'>


                    <div className='why_chex_step_content_wrapper'>
                        <p>
                            {secondPara}

                        </p>
                    </div>

                    <img src={secondIllustration} alt=""/>

                </div>


                <div className='why_chex_step_straight_wrapper'>

                    <img src={thirdIllustration} alt=""/>

                    <div className='why_chex_step_content_wrapper'>
                        <p>
                            {thirdPara}

                        </p>
                    </div>

                </div>


            </div>

        </div>
    )
}

export default WhyChexAiComponent