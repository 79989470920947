import React from "react";
import "./style.css";

export const OurSolutionSection = () => {
  return (
    <div className="solution-container">
      <section className="solution-section">
        <span className="solution-title">Our solution is tailored for</span>
        <div className="cards-container">
          <div className="solution-card">
            <img
              src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240327/rideshare-image_sflhqj.png"
              alt="Rideshare/P2P"
            />
            <span className="solution-card-title">Rideshare/P2P</span>
            <span className="solution-card-text">
              Compliance inspection <br /> Certification
            </span>
          </div>

          <div className="solution-card">
            <img
              src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240327/fleet-image_orz8me.png"
              alt="Fleet"
            />
            <span className="solution-card-title">Fleets/Rentals</span>
            <span className="solution-card-text">
              Damage/Wear and Asset <br /> Safety Analytics
            </span>
          </div>

          <div className="solution-card">
            <img
              src="https://res.cloudinary.com/dgjordf6e/image/upload/v1726240328/insurance-image_gputqq.png"
              alt="Insurance"
            />
            <span className="solution-card-title">Insurance</span>
            <span className="solution-card-text">
              Damage detection and <br /> Claims Processing
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};
