/*eslint-disable */

import React from 'react';
import { Tabs } from 'antd';
import './style.css';
// import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import TabContentComponent from '../TabContentComponent';
// import { IoDocumentTextOutline } from 'react-icons/io5';
// import { AiOutlineCar } from 'react-icons/ai';

import in_review from '../../Assets/in_review.png';
import progress from '../../Assets/progress.png';

// import { Collapse, Row, Col } from 'antd';
// import { UpCircleOutlined } from '@ant-design/icons';


const { TabPane } = Tabs;
const TabComponent = ({
  // handleCancel,
  // handleOk,
  // checkModal,
  showModal,
  isLoading,
  loadingDiscard,
  // setLoadingSelect,
  certificateLoading,
  setCertificateLoading,
  setReInspectionId,
  reInspectionModal,
  cancleReInspection,
  setReInspectionModal,
  handleDiscardInspection,
  setReInspectionLisencePlateNumber,
  vehiclesByStatus,
  handleStatus,
  setLoading
}) => {
  // const history = useHistory()
  // const { Panel } = Collapse;

  return (
    <div className="tabs-content-bg-color ">
      <Tabs
        onChange={(key) => handleStatus(key)}
        defaultActiveKey={localStorage.getItem('tabStatus') && localStorage.getItem('tabStatus') === 'REVIEWED' ? 'REVIEWED' : localStorage.getItem('tabStatus') === 'IN_PROGRESS' ? 'IN_PROGRESS' : localStorage.getItem('tabStatus') === 'IN_REVIEW' ? 'READY_FOR_REVIEW' : 'all'}
        centered
      >
        <TabPane key="all" tab={<div className="tab-content ">All</div>}>
          <div className="tabs-content-bg-color">
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : vehiclesByStatus?.length === 0 ? (
              <div className="content-flex">
                <img className="draft-image-show" src={progress} />
                <div className="draft-message-show">{'No Car Available'}</div>
              </div>
            ) : (
              <>
                {vehiclesByStatus
                  ? vehiclesByStatus?.map((item) => {
                    return <TabContentComponent title={item?.inspectionCode} all={true} item={item} />;
                  })
                  : null}
              </>
            )}
          </div>
        </TabPane>
        <TabPane key="IN_PROGRESS" tab={<div className="tab-content ">In Progress</div>}>
          <div className="tabs-content-bg-color">
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : vehiclesByStatus?.length === 0 ? (
              <div className="content-flex">
                <img className="draft-image-show" src={progress} />
                <div className="draft-message-show">{'No Car In Progress'}</div>
              </div>
            ) : (
              <>
                {vehiclesByStatus?.[0]?.status === 'IN_PROGRESS'
                  ? vehiclesByStatus?.map((item) => {
                    return <TabContentComponent loadingDiscard={loadingDiscard} handleDiscardInspection={handleDiscardInspection} title={item?.inspectionCode} draft={true} item={item} />;
                  })
                  : null}
              </>
            )}
          </div>
        </TabPane>

        <TabPane key="READY_FOR_REVIEW" tab={<div className="tab-content">In Review</div>}>
          <div className="tabs-content-bg-color">
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : vehiclesByStatus?.length === 0 ? (
              <div className="content-flex">
                <img className="draft-image-show" src={in_review} />
                <div className="draft-message-show">{'No car in Review'}</div>
              </div>
            ) : (
              <>
                {vehiclesByStatus?.[0]?.status === 'READY_FOR_REVIEW'
                  ? vehiclesByStatus?.map((item) => {
                    return <TabContentComponent title={item?.inspectionCode} inReview={true} />;
                  })
                  : null}
              </>
            )}
          </div>
        </TabPane>

        <TabPane key="REVIEWED" tab={<div className="tab-content">Reviewed</div>}>
          <div className="tabs-content-bg-color">
            {' '}
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : vehiclesByStatus?.length === 0 ? (
              <div className="content-flex">
                <img className="draft-image-show" src={progress} />
                <div className="draft-message-show">{'No car in Reviewed'}</div>
              </div>
            ) : (
              <>
                {vehiclesByStatus?.[0]?.status === 'REVIEWED'
                  ? vehiclesByStatus?.map((item) => {
                    return (
                      <TabContentComponent
                        title={item?.inspectionCode}
                        reviewed={true}
                        item={item}
                        setLoading={setLoading}
                        showModal={showModal}
                        cancleReInspection={cancleReInspection}
                        certificateLoading={certificateLoading}
                        setCertificateLoading={setCertificateLoading}
                        setReInspectionId={setReInspectionId}
                        reInspectionModal={reInspectionModal}
                        setReInspectionModal={setReInspectionModal}
                        setReInspectionLisencePlateNumber={setReInspectionLisencePlateNumber}
                        key={item?.id}
                      />
                      //   <div>
                      //   <Collapse
                      //     className="reviewed-content-container"
                      //     defaultActiveKey={['0']}
                      //     expandIconPosition="right"
                      //     expandIcon={({ isActive }) => (
                      //       <UpCircleOutlined
                      //         style={
                      //           isActive
                      //             ? { fontSize: 33, color: '#FF7A00', marginTop: 0 }
                      //             : { fontSize: 33, color: 'lightGray', marginTop: 0 }
                      //         }
                      //         rotate={isActive ? 180 : 0}
                      //       />
                      //     )}
                      //   >
                      //     <Panel
                      //       style={{ overflow: 'hidden' }}
                      //       header={
                      //         <Row
                      //           gutter={40}
                      //           style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}
                      //         >
                      //           <Col>
                      //             <div className="text-reviewed">
                      //               {item?.inspectionCode}
                      //             </div>
                      //           </Col>
                      //           <Col>
                      //           </Col>
                      //         </Row>
                      //       }
                      //       key="1"
                      //       extra={genExtra}
                      //     >
                      //       <div className="document-container">
                      //         <IoDocumentTextOutline color="#1468BA" size={18} />
                      //         <div className="document-text">Download Certificate</div>
                      //       </div>
                      //       <div className="document-container"
                      //         onClick={() => { history.push(`/selectCompany/${true}`, item?.CompanyInspections[0]?.id) }}>
                      //         <AiOutlineCar color="#1468BA" size={18} />
                      //         <div className="document-text">
                      //           Re-Inspect Car
                      //         </div>
                      //       </div>
                      //       <div className="document-container">
                      //         <AiOutlineCar color="#1468BA" size={18} />
                      //         <div className="document-text">Car Details</div>
                      //       </div>
                      //     </Panel>
                      //   </Collapse>
                      // </div>
                    );
                  })
                  : null}
              </>
            )}
          </div>
        </TabPane>
        {/* <TabPane key="IN_REVIEW" tab={<div style={{ color: 'white', fontSize: '15px', marginLeft: '35px', marginRight: '35px', fontWeight: '500' }}>In Review</div>}>
          <div className="tabs-content-bg-color">
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : (
              <>
                {vehiclesByStatus?.[0]?.status === 'IN_REVIEW'
                  ? vehiclesByStatus?.map((item) => {
                    return <TabContentComponent title={item?.Vehicle?.licensePlateNumber} inReview={true} />
                  })
                  : null}
              </>
            )}
          </div>
        </TabPane> */}
        {/* <TabPane key="IN_PROGRESS" tab={<div style={{ color: 'white', fontSize: '15px', fontWeight: '500' }}>Draft</div>}>
          <div className="tabs-content-bg-color">
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <ClipLoader color={'#246DB5'} size={20} />
              </div>
            ) : (
              <>
                {vehiclesByStatus?.[0]?.status === 'IN_PROGRESS'
                  ? vehiclesByStatus?.map((item) => {
                    return <TabContentComponent title={item?.Vehicle?.licensePlateNumber} draft={true} item={item} />;
                  })
                  : null}
              </>
            )}
          </div>
                </TabPane>*/}
      </Tabs>
    </div>
  );
};

export default TabComponent;
