import React from 'react'
import './style.css'
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import {BlogNavbar} from '../../Components';
import {CustomNavbar} from '../../Components';
import { ClipLoader } from 'react-spinners';
import {IoIosArrowBack} from "react-icons/all";
import {
  CustomFooter,

} from "../../Components";
const BlogDetailScreen = ({ blogdata,loading,history}) => {
  return (
    <div style={{overflow:'hidden'}} >
       <BlogNavbar scrollValue={30} history={history} />
       {/* <CustomNavbar scrollValue={30} />  */}
<div style={{paddingTop:'50px'}}>
        {loading===false?
        <>
 <Row gutter={[16, 16]}>
            <Col span={24} >
              
              <div className='Blog-images'>
                <div style={{}}>
                 <div className='blog-detail-images' 
                    alt="image"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_AWS_S3_LINK}/${blogdata && blogdata?.thumbnail}?x-request=xhr)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                    // src={`${process.env.REACT_APP_AWS_S3_LINK}/${blogdata && blogdata?.thumbnail}?x-request=xhr`}
                  >
                    </div>
                    <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }}
  />

                  </div>
                  <div style={{position:'absolute',top:'46%',left:'3%',width:'100%'}}>
                  <div className='Blog-title-styling' >{blogdata?.title}</div>
                <div className='DateContentS' >{moment(new Date(blogdata?.createdAt)).format('MM-DD-YYYY')}
               
                </div> 
               
                  </div>

                  </div>
                  {/* <div style={{marginTop:'2%'}}>
                   <div className='publishbytextstyle' ><strong>Publish By </strong>: {blogdata?.publishedBy}</div>
                   </div> */}
                 {/* <div style={{fontWeight:'bold',fontSize:'19px',color: 'rgb(26, 25, 25)',marginTop: '5%',marginLeft:'5%',marginRight:'5%' }} >{blogdata?.title}</div> */}
                {/* <div className='DateContentS' >{moment(new Date(blogdata?.createdAt)).format('MM-DD-YYYY')}<span style={{float:'right'}}><strong>Publish By </strong>: {blogdata?.publishedBy}</span></div> */}
               
                    <div className='ContentStylingS' dangerouslySetInnerHTML={{ __html: blogdata?.message }}>
                    </div>
                   
            </Col>
      </Row>
    
      <div>
        <CustomFooter />
      </div>
      </>
  :
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>
  <ClipLoader color={'#246DB5'} size={40} />
  </div>
  }
  </div>
    </div>
  )

}
export default BlogDetailScreen