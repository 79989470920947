import "./style.css";

export const OurAiBasedSection = () => {
  return (
    <div className="full-width-container">
      <section className="centered-section">
        <span className="ourAiBasedSection">
          Our AI based solution simplifies vehicle <br />
          inspections and automates damage <br />
          documentation for the mobility industry
        </span>
        {/* <button className="aboutChexButton">About Chex</button> */}
      </section>
    </div>
  );
};
