/*eslint-disable */

import React from 'react';
import { Modal } from 'antd';
import './style.css';

const VideoConfirmationModal = ({ modal, handleVideoConfirmation, aiStatus, handleVideoDeny }) => {
    return (
        <div>
            <Modal className="video-confirmation-modal" title="Basic Modal" mask={true} visible={modal} 
            >
                    <>
                    <div className="video-confirmation-modal-text">Rear light's not detected</div>
                    {/* <div className="Video-confirmation-inspection-text">Kindly retake</div> */}
                        {/* {aiStatus?.meta?.status === "fail" ? (
                        <div className="Video-confirmation-inspection-text">Kindly ReTake the image</div>
                        ) : (
                        <div className="Video-confirmation-inspection-text">{aiStatus?.meta?.message}</div>
                        )} */}
                        {/* {aiStatus?.meta?.status === "fail" ? null : <div className="video-confirmation-modal-text"> Score: <span>{aiStatus?.score}</span>
                        </div>} */}
                        {/* {aiStatus?.meta?.status === "fail" ? <div className="video-confirmation-modal-text">Status: {aiStatus?.meta?.status}
                        </div> : null} */}
                    </>
                <div className="video-confirmation-modal-btn">
                    {/* {aiStatus?.meta?.status === "fail" ? (
                        null
                    ) : ( */}
                    {/* <div className="video-confirmation-modal-btn-yes" onClick={() => { handleVideoConfirmation() }}>
                            {'Submit'}
                    </div> */}
                    {/* )} */}
                        <div className="video-confirmation-modal-btn-no" onClick={() => { handleVideoDeny(); }}>
                            <a style={{ color: '#1468BA' }} href="#">
                            {'Retake'}
                            </a>
                        </div>
                </div>
            </Modal>
        </div>
    );
};

export default VideoConfirmationModal;
